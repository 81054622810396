@import "variables.scss";

@font-face {
  font-family: 'rawline';
  src: url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-100.eot');
  src: url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-100.eot?#iefix') format('embedded-opentype'),
  url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-100.woff2') format('woff2'),
  url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-100.woff') format('woff'),
  url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-100.ttf') format('truetype'),
  url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-100.svg') format('svg');
  font-weight: 100;
  font-style: normal;
}


* {
  margin: 0;
  padding: 0;
}


body {
  min-width: 320px;

  font-family: $font-family;

  &.overfl {
    overflow: hidden;
    height: 100vh;
  }

  &.op {
    &:after {
      content: '';
      width: 100%;
      height: 100vh;
      min-height: 100%;

      position: fixed;
      top: 0;
      left: 0;
      z-index: 70;

      background: $black2;
      opacity: 0.5;
    }
  }
}

.start-page {
  padding: 30px;

  &-logo {
    display: block;
    margin-bottom: 30px;
  }

  ul {
    display: inline-block;
    vertical-align: top;
    padding-right: 60px;

    li {
      padding: 5px 0;

      b {
        font-size: 20px;
      }

      a {
        font-size: 16px;
        line-height: 140%;
        color: $black;

        &:hover {
          //color: $active;
        }
      }
    }
  }
}

div,
span,
a,
input,
textarea,
select,
button,
.button {
  &:focus {
    outline: none;
  }
}

input,
textarea {
  box-shadow: none!important;
}


ul {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
  }
}

a {
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

svg {
  use {
    transition: 0.3s;
  }
}



.container {
  max-width: 100%;

  @media screen and(min-width: 1670px) {
    max-width: 1640px;
  }
}


.button {
  background: transparent;
  border: none;
  box-shadow: none;
  outline: none;
  transition: 0.3s;

  &-primary {
    padding: 17px 45px;
    display: inline-block;

    background: $black2;
    border-radius: 4px;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: $white!important;
    letter-spacing: 0.06em;
    text-transform: uppercase;

    &.disabled {
      background: $gray2!important;
    }

    &:hover {
      background: $hover;
    }
  }

  &-link {
    display: inline-flex;
    align-items: center;

    color: $black2;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    svg {
      width: 41px;
      height: 6px;
      margin-left: 7px;

      use {
        color: $black2;
      }
    }

    &.disabled {
      color: $gray2!important;

      svg {
        use {
          color: $gray2!important;;
        }
      }
    }

    &:hover {
      color: $hover;

      svg {
        use {
          color: $hover;
        }
      }
    }
  }

  &-gray {
    padding: 17px 45px;
    display: inline-block;

    background: $gray8;
    border-radius: 4px;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: $black2!important;
    letter-spacing: 0.06em;
    text-transform: uppercase;

    &.disabled {
      background: $gray2!important;
    }

    &:hover {
      background: $black2;
      color: $white!important;
    }
  }

  &-center {
    text-align: center;
  }
}

h1 {
  margin-bottom: 15px;

  font-family: $font-family2;
  font-size: 52px;
  line-height: 120%;
  font-weight: 400;
  color: $black2;

  span {
    position: relative;
    top: -20px;

    font-size: 26px;
    line-height: 120%;
    color: $gray6;

    &.or {
      top: -8px;

      padding: 1px 5px;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: $white;
      background: $ye;
      border-radius: 4px;
      font-family: $font-family;
    }
  }
}

h2 {
  margin-bottom: 15px;

  font-family: $font-family2;
  font-size: 34px;
  line-height: 135%;
  color: $black2;
}

h3 {
  margin-bottom: 15px;

  font-family: $font-family2;
  font-size: 24px;
  line-height: 130%;
  color: $black2;
}

.title-main {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 40px;

  h2 {
    margin-bottom: 0;

    .blog-slider & {
      color: $white;
    }
  }
}


.header {
  position: relative;
  z-index: 71;

  &-mob {
    display: none;
  }

  &-top {
    background: $white;

    border-bottom: 1px solid rgba(6, 71, 60, 0.05);

    &__inner {
      padding: 10px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__left {

    }

    &__right {

    }

    &__menu {

      ul {
        li {
           display: inline-block;
           vertical-align: middle;
           margin-left: 31px;

          a {
            letter-spacing: 0.06em;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            color: $gray;

            &:hover {
              color: $ye;
            }
          }
        }
      }
    }
  }

  &-mid {
    background: $white;

    &__inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__group {
      width: 35%;

      &-search {
        &-title {
          cursor: pointer;
        }

        &-block {
          width: 100%;
          display: none;

          position: absolute;
          left: 0;
          top: 116px;
          z-index: 9;

          &.open {
            display: block;
          }

          &-inp {
            height: 74px;

            background: linear-gradient(0deg, #FAF9FA, #FAF9FA), #191919;

            &-inner {
              padding: 10px 0;

              position: relative;

              input {
                padding-left: 30px;
                background: transparent;

                font-weight: 600;
                font-size: 18px;
                line-height: 140%;
              }

              .button-search {
                position: absolute;
                top: 24px;
                left: 0;
                z-index: 2;

                svg {
                  width: 22px;
                  height: 22px;

                  use {
                    color: $black2!important;
                  }
                }

                &:hover {
                  color: $hover!important;
                }
              }
            }
          }

          &-list {
            padding: 41px 0;

            background: $white;

            &-inner {
              display: flex;
              align-items: flex-start;
            }

            &-tt {
              margin-bottom: 20px;

              font-weight: 600;
              font-size: 16px;
              line-height: 22px;
              letter-spacing: 0.06em;
              text-transform: uppercase;
              color: $gray;
            }

            &-left {
              width: 300px;

              ul {
                li {
                  margin-bottom: 18px;

                  a {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 140%;
                    color: $black2;

                    &:hover {
                      color: $hover;
                    }
                  }
                }
              }
            }

            &-prod {
              padding-left: 30px;
              width: calc(100% - 300px);
            }

            &-in {
              display: flex;
              align-items: center;
              flex-wrap: wrap;
            }

            &-item {
              margin-bottom: 20px;
              max-width: 33.333%;
              flex: 0 0 33.333%;
              padding: 20px 10px 10px 120px;

              position: relative;
            }

            &-img {
              display: block;
              width: 100px;
              height: 100px;

              position: absolute;
              top: 0;
              left: 0;
              z-index: 2;

              img {
                max-width: 100%;
                max-height: 100px;
              }
            }

            &-title {
              display: block;

              font-weight: 600;
              font-size: 16px;
              line-height: 140%;
              color: $black2;

              &:hover {
                color: $hover;
              }
            }

            &-price {
              margin-top: 5px;

              &.mob {
                display: none;
              }

              &-now {
                margin-right: 13px;
                display: inline-block;
                vertical-align: middle;

                font-weight: 600;
                font-size: 20px;
                line-height: 140%;
                color: $black2;

                &:after {
                  display: inline-block;
                  margin-left: 7px;
                  font-family: $font-family3;
                  content: "\20cf";
                  font-weight: 400;
                }
              }

              &-old {
                display: inline-block;
                vertical-align: middle;

                font-weight: 600;
                font-size: 14px;
                line-height: 140%;
                color: $gray10;
                text-decoration: line-through;

                &:after {
                  display: inline-block;
                  margin-left: 7px;
                  font-family: $font-family3;
                  content: "\20cf";
                  font-weight: 400;
                }
              }
            }
          }
        }
      }

      &-right {
        text-align: right;
      }

      &-item {
        margin-right: 40px;
        display: inline-block;
        vertical-align: middle;

        &.sr {
          margin-left: 40px;
        }

        &:last-of-type {
          margin-right: 0;
        }

        svg {
          width: 20px;
          height: 20px;
          margin-right: 10px;

          use {
            color: $black2;
          }
        }

        span {
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          color: $black2;
          letter-spacing: 0.06em;
          text-transform: uppercase;
          transition: 0.3s;
        }

        &:hover {
          svg use, span {
            color: $ye;
          }
        }
      }
    }
  }

  &-menu {
    padding: 16px 0;



    background: linear-gradient(86.68deg, #00352C -15.56%, #0F6254 39.54%, #2A7E70 60.18%, #0F6254 79.56%, #00352C 105.88%), #F2F1F2;

    &__inner {
      ul {
        display: flex;
        align-items: center;
        justify-content: space-between;

        li {
          display: inline-block;
          vertical-align: middle;

          a {
            font-weight: 700;
            font-size: 14px;
            line-height: 19px;
            color: $white;
            letter-spacing: 0.06em;
            text-transform: uppercase;

            &:hover {

            }
          }

          &:hover {
            .dropdn {
              visibility: visible;
              opacity: 1;
            }
          }
        }
      }
    }
  }

  &__tel {
    margin-right: 15px;
    display: inline-block;
    vertical-align: middle;

    color: $black2;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.06em;
    text-transform: uppercase;

    &:hover {
      color: $ye;
    }
  }

  &__time {
    display: inline-block;
    vertical-align: middle;

    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: $gray;
    letter-spacing: 0.06em;
    text-transform: uppercase;
  }

  &__logo {
    width: 30%;

    position: relative;
    top: -11px;

    text-align: center;
  }
}

.dropdn {
  width: 100%;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;

  position: absolute;
  top: 173px;
  left: 0;
  z-index: 8;

  &-in {
    background: $white;
    position: relative;
    z-index: 71;
  }



  &:before {
    content: '';
    width: 15%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;

    background: $bg;
  }

  &:after {
    content: '';
    width: 100%;
    height: 18px;

    position: absolute;
    top: -17px;
    left: 0;
    z-index: 2;

    background: transparent;
  }


  &__inner {
    display: flex;
    position: relative;
    z-index: 71;


    ul {
      display: block;
      margin-bottom: 40px;

      li {
        margin-bottom: 13px;

        display: block;

        &:last-of-type {
          margin-bottom: 0;
        }

        a {
          font-weight: 600;
          font-size: 16px!important;
          line-height: 140%;
          color: $black2!important;
          text-transform: inherit;
          letter-spacing: 0.001em!important;

          &:hover {
            color: $gray!important;
          }
        }
      }
    }
  }

  &__left {
    padding: 40px 20px;
    background: $bg;
  }

  &__right {
    width: calc(100% - 230px);
    padding: 40px 20px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &-inner {
      -webkit-column-count: 3;
      column-count: 3;
    }

    &-img {
      padding-left: 40px;


      img {
        max-width: 100%;
      }
    }
  }

  &__title {
    margin-bottom: 11px;

    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: $gray;
    letter-spacing: 0.06em;
    text-transform: uppercase;
  }

  &__item {

  }
}

.banner {
  &__inner {
    position: relative;

    .pagingInfo {
      width: 55px;
      display: inline-block;

      position: absolute;
      bottom: 33px;
      right: 0;
      left: 1500px;
      margin: auto;
      z-index: 3;

      .number-slide {
        .numb {
          &-prev,
          &-next {
            padding: 0 3px;
            font-size: 20px;
            line-height: 160%;
            font-family: $font-family2;
          }

          &-prev {

          }

          &-next {
            &:before {
              content: '/';
              display: inline-block;
              vertical-align: middle;

              position: relative;
              left: -4px;
              top: -3px;

              font-size: 18px;
              line-height: 160%;
            }
          }
        }
      }
    }
  }

  &__slider {
    .slick {
      &-arrow {
        width: 40px;
        height: 40px;

        top: inherit;
        bottom: 30px;
        left: 0;
        right: 0;
        margin: auto;

        border-color: rgba(0, 0, 0, 0.2);

        svg {
          width: 13px;
          height: 13px;
        }
      }

      &-prev {
        left: 1385px;
      }

      &-next {
        left: 1580px;
      }
    }

    &-item {

    }

    &-holder {
      display: flex;
    }

    &-inner {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    &-img {
      width: 62%;
    }

    &-tx {
      width: 38%;
      padding: 60px 0 30px;

      &-title {
        margin-bottom: 20px;

        font-family: $font-family2;
        font-size: 52px;
        line-height: 120%;
        color: $black2;
      }

      p {
        margin-bottom: 50px;

        color: $black2;
        font-size: 18px;
        line-height: 160%;
      }
    }
  }

  &-min {
    margin: 60px 0;

    &__item {
      height: 250px;
      padding: 25px 37px;
      margin-bottom: 30px;

      position: relative;

      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      border-radius: 4px;

      a {
        width: 100%;
        height: 100%;
        display: block;

        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
      }

      &-title {
        font-size: 28px;
        line-height: 130%;
        color: $black2;
        font-family: $font-family2;
      }

      &-link {
        position: absolute;
        left: 37px;
        bottom: 34px;
        z-index: 1;

        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        color: $black2;
        transition: 0.3s;

        svg {
          width: 16px;
          height: 16px;

          use {
            color: $black2;
          }
        }
      }

      &:hover {
        .banner-min__item-link {
          color: $ye;

          svg {
            use {
              color: $ye;
            }
          }
        }
      }
    }
  }

  &-info {
    margin: 80px 0;

    &__inner {
      display: flex;
      align-items: center;
      justify-content: space-between;

      background: $bg;
      border-radius: 4px;
      overflow: hidden;

      background-image: url("../images/bg1.png");
      background-repeat: no-repeat;
      background-position: top right;
    }

    &__img {
      width: 33%;
      min-height: 536px;

      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

      &-m {
        width: 22%;

        img {
          max-width: 100%;
        }
      }
    }

    &__text {
      width: 550px;
      margin: 0 40px;

      &-min {
        margin-bottom: 10px;

        font-weight: 700;
        font-size: 12px;
        line-height: 16px;

        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: $black2;
      }

      p {
        margin-bottom: 45px;

        font-size: 16px;
        line-height: 190%;
        color: $black2;
      }
    }
  }

  &-rek {
    margin: 80px 0;

    &__inner {
      padding: 59px 69px 73px;

      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    &__tx {
      width: 690px;

      &-min {
        margin-bottom: 10px;

        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: $black;
      }

      h2 {
        margin-bottom: 45px;
      }
    }
  }

  &-nav {
    margin: 60px 0;

    &__inner {
      display: flex;
      justify-content: space-between;

      background-color: $ye2;
      background-image: url("../images/bg5.png");
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 4px;
    }

    &__left {
      width: 25%;
      display: flex;
      align-items: flex-end;

      img {
        max-width: 100%;
      }
    }

    &__right {
      width: 41%;
      min-height: 535px;
    }

    &__text {
      width: 30%;
      padding: 120px 0 0;

      p {
        margin-bottom: 50px;

        font-size: 16px;
        line-height: 190%;
        color: $black2;
      }

      &-min {
        margin-bottom: 10px;

        color: $black2;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;

        letter-spacing: 0.1em;
        text-transform: uppercase;
      }
    }
  }

  &-ct {
    margin: 100px 0;

    background: $bg;

   &__inner {
     display: flex;
     align-items: center;
     justify-content: space-between;
   }

    &__img {
      img {
        max-width: 100%;
      }
    }

    &__text {
      width: 40%;

      p {
        margin-bottom: 50px;

        font-size: 16px;
        line-height: 190%;
        color: $black2;
      }

      &-min {
        margin-bottom: 10px;

        color: $black2;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;

        letter-spacing: 0.1em;
        text-transform: uppercase;
      }
    }
  }

  &-ab {
    margin: 60px 0;

    &__inner {
      display: flex;
      justify-content: space-between;

      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      //background: linear-gradient(252.08deg, rgba(29, 22, 15, 0.2) 0%, rgba(0, 0, 0, 0) 13.3%), linear-gradient(252.08deg, #9C8166 14.64%, #B38A64 21.03%, #DEC4AB 32.7%, #F9EFE3 57.31%, #E1C6AD 79.11%, #CDAB8A 92.37%);
      border-radius: 4px;
    }

    &__text {
      width: 40%;
      padding-top: 215px;

      &-in {
        padding: 0 70px 50px;
      }

      p {
        margin-bottom: 30px;

        font-size: 16px;
        line-height: 190%;
        color: $black2;
      }

      &-min {
        margin-bottom: 10px;

        color: $black2;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;

        letter-spacing: 0.1em;
        text-transform: uppercase;
      }
    }

    &__img {
      width: 54%;

      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
  }

  &-form {
    margin: 80px 0;

    &.tw {
      background: transparent;

      textarea{
        margin-bottom: 20px;
        height: 148px;
      }

      input {
        margin-bottom: 20px;
      }

      .form__tx {
        width: 60%;

      }
    }

    &__holder {
      padding: 66px 0 66px 100px;
      position: relative;

      background: linear-gradient(293.33deg, #E6E6E6 25.15%, #F7F7F7 67.89%, #E6E6E6 98.66%);
      border-radius: 4px;
      overflow: hidden;

      &:before {
        content: '';
        width: 730px;
        height: 570px;
        display: block;

        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 1;

        background-image: url("../images/fr-img.png");
        background-repeat: no-repeat;
      }

      &-bottom {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

       p {
         margin-bottom: 44px!important;
         font-weight: 600;
         font-size: 18px!important;
         line-height: 140%;
         color: $gray!important;
       }
    }

    &__inner {
      display: flex;
      align-items: center;
      justify-content: flex-end;


      background-repeat: no-repeat;
      background-position: center left;
      background-size: cover;

      //background: linear-gradient(294.11deg, #E6E6E6 0%, #F7F7F7 56.04%, #E6E6E6 114.47%);
      border-radius: 4px;

      min-height: 350px;
    }

    &__img {
      width: calc(100% - 794px);

      img {
        //max-width: 100%;
      }
    }

    &__text {
      width: 628px;
      padding: 30px 0;
      margin-right: 10%;

      position: relative;
      z-index: 3;

      .tw & {
        width: 835px;
      }

      p {
        margin-bottom: 25px;

        font-size: 16px;
        line-height: 155%;
        color: $black2;
        opacity: 0.8;
      }

      &-in {
        display: flex;
        align-items: center;

        input {
          margin-right: 10px;
          max-width: 410px;
        }
      }
    }

    &__holder {
      display: flex;

    }
  }

  &-dop {


    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    &__inner {
      height: 300px;
      display: flex;
      align-items: center;
    }

    &__text {
      width: 360px;
      margin-left: 63%;

      p {
        font-size: 16px;
        line-height: 190%;
        color: $black2;
      }
    }
  }
}

.catalog {
  &-min {
    margin: 60px 0;

    &__slider {
      margin: 0 -10px;

      &-item {

      }

      .slick-slide {
        padding: 0 10px;
      }
    }
  }

  &__item {
    position: relative;

    &-labels {
      padding-right: 40px;
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 2;

      &-item {
        padding: 5px 9px;
        margin-right: 2px;
        margin-bottom: 2px;
        display: inline-block;
        vertical-align: middle;

        font-weight: 700;
        text-transform: uppercase;
        font-size: 12px;
        line-height: 16px;
        border-radius: 4px;
        border: 2px solid transparent;

        &.sale {
          border-color: $ye;
          color: $ye;
        }

        &.hit {
          border-color: $black2;
          color: $black2;
        }

        &.new {
          border-color: $gray4;
          color: $gray4;
        }
      }
    }

    &-fav {
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 2;

      cursor: pointer;

      svg {
        width: 24px;
        height: 24px;

        use {
          color: $black2;
        }
      }

      .catalog__item-fv & {
        &:before {
          content: '';
          width: 24px;
          height: 24px;
          display: block;

          position: absolute;
          top: 0;
          left: 0;
          z-index: 3;

          background-image: url("../images/svg/fv.svg");
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }

    &-img {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 308px;
      width: 100%;
      margin-bottom: 11px;


      img {
        width: 100%;
        max-height: 305px;
        max-width: 100%;
      }
    }

    &-title {
      margin-bottom: 3px;

      display: block;

      font-size: 16px;
      line-height: 140%;
      color: $black2;

      &:hover {
        color: $ye;
      }
    }

    &-price {
      &-now {
        margin-right: 10px;
        display: inline-block;
        vertical-align: middle;

        font-weight: 600;
        font-size: 22px;
        line-height: 140%;
        color: $black2;
        text-transform: uppercase;

        &:after {
          display: inline-block;
          margin-left: 7px;
          font-family: $font-family3;
          content: "\20cf";
          font-weight: 400;
        }
      }

      &-old {
        display: inline-block;
        vertical-align: middle;

        text-decoration: line-through;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        color: $gray3;


        &:after {
          font-weight: 400;
          display: inline-block;
          margin-left: 7px;
          font-family: $font-family3;
          content: "\20cf";
        }
      }
    }
  }

  &-page {
    margin-bottom: 50px;

    &__mob {
      display: none;
    }

    &__inner {
      display: flex;
      padding: 35px 0 0;
    }

    &__left {
      width: 253px;
    }

    &__content {
      width: calc(100% - 253px);
      padding-left: 75px;

      h1 {
        margin-top: 17px;
      }

      &-bottom {
        .button {
          display: block;
          width: 100%;

          text-align: center;
        }
      }

      &-tx {
        margin-top: 50px;

        &-inner {
          height: 112px;

          overflow: hidden;

          .open & {
            height: auto;
          }
        }

        h3 {
          margin-bottom: 25px;
        }

        &-btn {
          margin-top: 20px;

          &-item {
            font-weight: 600;
            font-size: 16px;
            line-height: 140%;
            color: $black2;
            cursor: pointer;
            transition: 0.3s;

            &:hover {
              color: $ye;

              svg {
                use {
                  color: $ye;
                }
              }
            }

            svg {
              width: 18px;
              height: 18px;
              transform: rotate(90deg);

              .open &{
                transform: rotate(-90deg);
              }

              use {
                color: $black2;
              }
            }

            &.opn {
              display: inline-block;

              .open &{
                display: none;
              }
            }

            &.cl {
              display: none;

              .open &{
                display: inline-block;
              }
            }
          }
        }
      }

    }

    &__tags {
      margin-top: 32px;
      margin-bottom: 18px;

      &-del {
        margin-left: 12px;
        margin-bottom: 10px;
        display: inline-flex;
        align-items: center;
        vertical-align: middle;

        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        color: $gray6;
        cursor: pointer;
        transition: 0.3s;

        svg {
          width: 13px;
          height: 13px;
          margin-right: 5px;

          use {
            color: $gray6;
          }
        }

        &:hover {
          color: $ye;

          svg {
            use {
              color: $ye;
            }
          }
        }
      }

      &-item {
        margin-bottom: 10px;
        margin-right: 5px;
        display: inline-flex;
        align-items: center;
        padding: 8px 15px;

        border-radius: 4px;
        background: $bg;
      }

      &-title {
        margin-right: 13px;

        font-weight: 600;
        font-size: 18px;
        line-height: 140%;
        color: $gray6;

        span {
          color: $black2;
        }
      }

      &-close {
        cursor: pointer;

        svg {
          width: 13px;
          height: 13px;

          use {
            color: $hover;
          }
        }

        &:hover {
          svg {
            use {
              color: $ye;
            }
          }
        }
      }

    }

    &__sorting {
      &-title {
        margin-right: 25px;
        display: inline-block;
        vertical-align: middle;

        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        color: $gray6;
      }

      ul {
        display: inline-block;
        vertical-align: middle;


        li {
          margin-right: 15px;
          display: inline-block;
          vertical-align: middle;

          a {
            font-weight: 600;
            font-size: 16px;
            line-height: 140%;
            color: $black2;

            &:hover {
              color: $ye;
            }
          }

          &.active {
            a {
              color: $ye;
            }
          }
        }
      }
    }

    &__list {
      margin-top: 25px;

      .catalog__item {
        margin-bottom: 50px;
      }
    }
  }
}

.slick {
  &-arrow {
    width: 78px;
    height: 78px;

    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 3;

    border-radius: 1000px;
    border: 1px solid $gray5;
    background: transparent;
    transform: none;
    transition: 0.3s;
    cursor: pointer;

    svg {
      width: 22px;
      height: 22px;

      use {
        color: $black2;
      }
    }

    &:hover {
      border-color: $black2;
      background: $black2;

      svg {
        use {
          color: $white;
        }
      }
    }
  }

  &-prev {
    left: 0;

    transform: rotate(180deg);
  }

  &-next {
    right: 0;
  }

  &-disabled {
    opacity: 0;
    z-index: -9;
  }

  &-dots {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 5;

    li {
      width: 6px;
      height: 6px;
      display: inline-block;
      margin-right: 7px;

      background: $gray6;
      opacity: 0.5;
      border-radius: 10px;

      button {
        display: none;
      }

      &.slick-active {
        opacity: 1;
      }
    }
  }
}

.brands {
  margin: 70px 0;
  padding: 62px 0;

  background: $bg;

  &__inner {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    padding: 0 10px;
    height: 200px;
    flex: 0 0 20%;
    max-width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    border-radius: 4px;
    overflow: hidden;

    &:after {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      transition: 0.3s;
      opacity: 0;

      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;

      background-image: url("../images/brands-hov.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    &-img {
      display: flex;
      align-items: center;
      justify-content: center;

      position: relative;
      z-index: 2;

      transition: 0.3s;

      img {
        max-width: 100%;
        margin: auto;
      }

      &-hov {
        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;
        z-index: 2;

        opacity: 0;

        transition: 0.3s;

        img {
          max-width: 100%;
          margin: auto;
        }
      }
    }

    &:hover {
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);


      &:after {
        opacity: 1;
      }

      .brands__item-img {
        opacity: 0;

        &-hov {
          opacity: 1;
        }
      }
    }
  }

  &-page {
    padding-bottom: 90px;
  }
}

.services {
  margin: 70px 0;

  &__text {
    &-min {
      margin-bottom: 5px;

      font-size: 12px;
      line-height: 16px;
      color: $black2;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      font-weight: 700;
    }

    h2 {
      margin-bottom: 18px;
    }

    p {
      margin-bottom: 50px;

      font-size: 16px;
      line-height: 160%;
      color: $black2;
    }
  }

  &__item {
    position: relative;

    a {
      display: block;
      width: 100%;
      height: 100%;

      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }

    &-img {
      height: 390px;
      width: 100%;

      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    &-title {
      margin-top: 20px;

      font-size: 24px;
      line-height: 130%;
      color: $black2;
      transition: 0.3s;
      font-family: $font-family2;
    }

    &:hover {
      .services__item-title {
        color: $ye;
      }
    }
  }
}

.blog {
  margin-bottom: 80px;

  &-slider {
    margin: 100px 0;
    padding: 60px 0 100px;

    background: linear-gradient(85.76deg, #00352C 9.48%, #0F6254 48.58%, #2A7E70 68.64%, #0F6254 84.71%, #00352C 100.97%), #023626;

    .button-link {
      color: $white;

      svg {
        use {
          color: $white;
        }
      }

      &:hover {
        color: $hover;

        svg {
          use {
            color: $hover;
          }
        }
      }
    }
  }

  &__item {
    margin-bottom: 30px;
    height: 270px;
    padding: 34px 40px 60px 40px;

    position: relative;

    background: $white;
    border-radius: 5px;
    overflow: hidden;

    a {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
    }

    &:before {
      content: '';
      width: 100%;
      height: 100%;
      display: block;

      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      opacity: 0;
      transition: 0.3s;

      background-image: url("../images/bg6.jpg");
      background-repeat: no-repeat;
      background-position: center;
    }

    &:hover {
      &:before {
        opacity: 1;
      }

      .blog__item-link {
        color: $ye;

        svg {
          use {
            color: $ye;
          }
        }
      }
    }

    &-data {
      display: inline-block;
      padding: 7px 12px;
      margin-bottom: 20px;

      position: relative;
      z-index: 2;

      font-size: 12px;
      line-height: 16px;
      color: $black2;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      font-weight: 700;

      background: $hover;
      border-radius: 4px;

    }

    &-title {
      height: 110px;
      position: relative;
      z-index: 2;

      font-style: italic;
      font-weight: 500;
      font-size: 28px;
      line-height: 130%;
      color: $black2;
      font-family: $font-family2;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    &-link {
      position: absolute;
      left: 37px;
      bottom: 34px;
      z-index: 2;

      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: $black2;
      transition: 0.3s;

      svg {
        width: 16px;
        height: 16px;

        use {
          color: $black2;
        }
      }
    }
  }

  &__text {
    p {
      margin-bottom: 45px;

      font-weight: 300;
      font-size: 16px;
      line-height: 160%;
      color: $black2;
    }

    .row-right & {
      padding-left: 80px;
    }
  }

  &__img {
    height: 450px;

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .row {
    margin-bottom: 100px;

    align-items: center;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &-item {
    margin-bottom: 80px;

    &-banner {
      height: 450px;

      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    &__inner {
      margin: 40px 0;
      display: flex;
      align-items: flex-start;
    }

    &__text {
      padding-right: 150px;
      width: calc(100% - 527px);
    }

    &__form {
      width: 527px;
      padding: 40px;

      border-radius: 4px;
      background: linear-gradient(294.11deg, #E6E6E6 0%, #F7F7F7 56.04%, #E6E6E6 114.47%);
    }
  }

  &-bottom {
    padding: 40px 20px 50px;
    margin-top: 30px;

    position: relative;

    background: linear-gradient(84.52deg, #00352C 12.61%, #0F6254 57.61%, #2A7E70 80.68%, #0F6254 99.19%, #00352C 117.89%), #EFE5D9;

    text-align: center;
    border-radius: 4px;

    &:before {
      content: '';
      width: 100%;
      height: 100%;
      display: block;

      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;

      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      background-image: url("../images/bg11.png");
    }

    &-title {
      margin-bottom: 20px;

      position: relative;
      z-index: 4;

      font-family: $font-family2;
      font-size: 34px;
      line-height: 135%;
      color: $white;
    }

    p {
      position: relative;
      z-index: 4;

      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: $white;
      opacity: 0.5;
    }

    .button-link {
      position: relative;
      z-index: 4;

      color: $white;

      svg {
        use {
          color: $white;
        }
      }

      &:hover {
        color: $ye;

        svg {
          use {
            color: $ye;
          }
        }
      }
    }
  }

  &-page {
    padding-bottom: 70px;
  }
}

.text {
  blockquote {
    padding: 30px;
    margin: 10px 0 30px;

    font-weight: 600;
    font-size: 18px;
    line-height: 160%;
    color: $black2;

    background: $ye2;
    border-radius: 4px;

    text-align: center;
  }

  p {
    margin-bottom: 10px;

    font-size: 18px;
    line-height: 190%;
    color: $gray6;
  }

  img {
    margin: 30px 0;
    width: 100%;

    max-width: 100%;
  }

  h2 {
    margin-top: 55px;
    margin-bottom: 30px;
  }

  h3 {
    margin-bottom: 10px;
  }

  ol {
    margin: 30px 0;
    padding-left: 0;
    counter-reset: myCounter;

    li {
      padding-left: 45px;
      margin-bottom: 15px;

      position: relative;

      font-size: 18px;
      line-height: 180%;
      color: $gray6;

      list-style: none;

      &:before{
        counter-increment: myCounter;
        content: counters(myCounter,".") ".";
        display: block;

        position: absolute;
        top: -1px;
        left: 0;
        z-index: 2;

        font-size: 24px;
        line-height: 130%;
        color: $black3;
        font-family: $font-family2;
      }

    }
  }


  &__acc {
    margin-top: 30px;

    .accordion {
      &-item {
        padding: 0;
        margin-bottom: 0;

        border: none;
        border-bottom: 1px solid $gray2!important;
      }

      &-header {
        border: none!important;
        background: transparent;
      }

      &-button {
        padding: 20px 0!important;
        font-size: 24px;
        line-height: 130%;
        color: $black2;
        font-family: $font-family2;
        border: none!important;
        background: transparent;
        box-shadow: none!important;
      }

      &-collapse {

      }

      &-body {
        padding: 0 0 20px!important;

        p {
          font-size: 18px;
          line-height: 190%;
          color: $gray6;
        }
      }
    }

  }

  &__table {
    margin: 30px 0;

    table {
      width: 100%;

      border: 1px solid $gray2;

      tr {
        th {
          font-weight: 600;
          font-size: 18px;
          line-height: 140%;
          color: $black2;
          border: 1px solid $gray2;
        }

        td {
          font-weight: 600;
          font-size: 16px;
          line-height: 140%;
          color: $black2;
          border: 1px solid $gray2;
        }

        th, td {
          padding: 15px;
        }
      }
    }
  }

  &__link {
    margin: 30px 0;

    &-item {
      margin-bottom: 4px;
      display: inline-flex;
      align-items: center;
      padding: 9px 17px;
      margin-right: 5px;

      font-size: 14px;
      line-height: 120%;
      color: $black2!important;
      background: $ye2;
      border-radius: 4px;


      svg {
        width: 17px;
        height: 17px;
        margin-left: 10px;

        use {
          color: $black2;
        }
      }

      &:hover {
        background: $hover;
      }
    }
  }

  &__video {
    position: relative;

    img {
      max-width: 100%;
    }

    &:before {
      content: '';
      width: 95px;
      height: 95px;
      display: inline-flex;

      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 2;

      background: $black2;
      border-radius: 1000px;
      transition: 0.3s;
    }

    &:after {
      content: '';
      width: 34px;
      height: 34px;
      display: block;

      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 3;

      background-image: url("../images/svg/pley.svg");
      background-repeat: no-repeat;
      background-position: center;
    }

    &:hover {
      &:before {
        background: $hover;
      }
    }
  }

  &__slider {
    margin: 30px 0;
    padding-bottom: 70px;

    position: relative;

    .pagingInfo {
      width: 50px;

      position: absolute;
      left: 0;
      right: 0;
      bottom: 20px;
      margin: auto;

      .number-slide {
        .numb {
          &-prev,
          &-next {
            padding: 0 3px;
            font-size: 20px;
            line-height: 160%;
            font-family: $font-family2;
          }

          &-prev {

          }

          &-next {
            &:before {
              content: '/';
              display: inline-block;
              vertical-align: middle;

              position: relative;
              left: -4px;
              top: -3px;

              font-size: 18px;
              line-height: 160%;
            }
          }
        }
      }

    }

    .slick {
      &-arrow {
        width: 40px;
        height: 40px;

        top: inherit;
        bottom: -53px;
        left: 0;
        right: 0;
        margin: auto;

        border: 2px solid rgba(0, 0, 0, 0.2);

        svg {
          width: 18px;
          height: 18px;

        }

        &:hover {
          background: $black2;

          svg {
            use {
              color: $white;
            }
          }
        }
      }

      &-prev {
        left: -120px;
      }

      &-next {
        right: -100px;
      }
    }

    &-item {

    }

    &-inner {
      height: 505px;

      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

.footer {
  margin-top: 100px;

  background-image: url("../images/bg10.png");
  background-repeat: no-repeat;
  background-position: center right;
  background-color: $black2;
  background-size: cover;

  &__main {
    padding: 30px 0 140px;

    &-inner {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
  }

  &__bottom {
    padding: 10px 0;

    border-top: 1px solid rgba(255,255,255,0.05);

    &-inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-tx {
      color: $gray7;
      font-size: 14px;
      line-height: 140%;
    }

    &-links {
      a {
        display: inline-block;
        vertical-align: middle;
        margin: 0 25px;

        color: $gray7;
        font-size: 14px;
        line-height: 140%;

        &:hover {
          color: $hover;
        }
      }
    }
  }

  &__dev {
    display: inline-flex;
    align-items: center;

    font-size: 14px;
    line-height: 140%;
    color: $gray7;

    svg {
      width: 22px;
      height: 22px;
      margin-left: 5px;

      use {
        color: $gray7;
      }
    }

    &:hover {
      color: $hover;

      svg {
        use {
          color: $hover;
        }
      }
    }
  }

  &__logo {
    margin-bottom: 25px;
    display: inline-block;
  }

  &__tel {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: $white;

    &:hover {
      color: $ye;
    }
  }

  &__call {
    font-size: 14px;
    line-height: 19px;
    color: $gray6;

    &:hover {
      color: $ye;
    }
  }

  &__time {
    margin-top: 30px;

    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: $white;
    letter-spacing: 0.06em;
    text-transform: uppercase;
  }

  &__menu {
    width: 50%;
    padding-top: 10px;

    display: flex;
  }

  &__text {
    width: 352px;

    p {
      font-size: 15px;
      line-height: 150%;
      color: $gray6;
    }
  }

  &__inst {
    padding-top: 10px;

    a {
      display: inline-flex;
      align-items: center;

      font-weight: 800;
      font-size: 16px;
      line-height: 22px;
      color: $hover;
      letter-spacing: 0.08em;
      text-transform: uppercase;

      svg {
        width: 24px;
        height: 24px;
        margin-right: 5px;

        use {
          color: $hover;
        }
      }

      &:hover {
        color: $ye;

        svg {
          use {
            color: $ye;
          }
        }
      }
    }
  }

  &__item {
    width: 22%;
    padding: 0 15px;

    box-sizing: border-box;

    &:last-of-type {
      width: 36%;
    }

    &-title {
      margin-bottom: 15px;

      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      color: $gray6;
      letter-spacing: 0.06em;
      text-transform: uppercase;

      svg {
        display: none;
      }
    }

    ul {
      li {
        margin-bottom: 8px;

        a {
          font-weight: 600;
          font-size: 16px;
          line-height: 140%;
          color: $white;

          &:hover {
            color: $hover;
          }
        }
      }
    }
  }
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;

  &-bl {
    margin-top: 50px;
  }

  .page {
    &-item {
      margin: 0 5px;

      a, span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 45px;
        height: 45px;

        font-weight: 700;
        font-size: 18px;
        line-height: 140%;
        text-align: center;
        text-transform: uppercase;
        border-radius: 100px!important;
        border: none;
        box-shadow: none!important;
        background: transparent;
        color: $black2;

        &:hover {
          color: $ye;
        }
      }

      span {
        cursor: inherit!important;
        color: $gray7;
        background: transparent;
      }

      &.active {
        a {
          background: $black2;
          color: $white;
        }
      }

      &.prev,
      &.next {
        width: 30px;
        height: 40px;
        margin: 0 18px;

        position: relative;

        &:hover {
          background: transparent;
        }

        span.page-link {
          .ar {
            opacity: 0.5;
          }
        }

        .page-link {
          padding: 0;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 20px;
          height: 40px;
        }

        .ar {
          width: 20px;
          height: 20px;

          position: absolute;
          top: 5px;


        }

        a {
          position: initial;

          width: auto;
          height: auto;

          background: transparent!important;

          &:hover {
            .ar {
              border-color: $bg;
            }

            .tt {
              color: $bg;
            }

            svg {
              use {
                color: $ye;
              }
            }
          }
        }

        svg {
          width: 30px;
          height: 30px;

          use {
            color: $black2;
          }
        }
      }


      &.prev {
        margin-left: 0;
        transform: rotate(180deg);

        svg {
          position: relative;
          top: 4px;
        }
      }

      &.next {

        svg {
          position: relative;
          top: 7px;
        }
      }
    }
  }
}

.mCSB_dragger_bar {
  width: 2px!important;
  background: $gray2!important;
  border-radius: 0!important;
}
.mCSB_draggerRail {
  background: $gray8!important;
  width: 2px!important;
  border-radius: 0!important;
}

.filter {
  width: 100%;

  &__total {
    width: 152px;
    height: 79px;
    padding: 19px 17px 20px 35px;

    position: absolute;
    right: -55px;
    top: 120px;
    z-index: 30;

    background-image: url("../images/svg/tooltip.svg");
    background-repeat: no-repeat;
    background-position: center;

    &-quantity {
      font-size: 14px;
      line-height: 120%;
      color: $gray;
    }

    &-all {
      display: inline-block;

      font-size: 14px;
      line-height: 120%;
      letter-spacing: 0.01em;
      color: $black2;

      &:hover {
        color: $ye!important;
      }
    }
  }

  &__top {
    display: none;

    &-close {
      display: none;
    }

    &-tt {
      display: none;
      padding: 15px 0;

      font-weight: 700;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 17px;
      color: $black2;

      &-main {
        margin-bottom: 18px;

        font-weight: 700;
        font-size: 18px;
        line-height: 121.03%;
        color: $black2;
        text-transform: uppercase;
      }
    }
  }

  &__btn {
    padding: 18px 0;
    margin: 15px auto 0;

    .button {
      display: block;
      width: 100%;
      margin-bottom: 10px;

      text-align: center;

      &-link {
        padding: 10px 0;
        display: block;

        color: $gray6;
        font-size: 14px;
        line-height: 19px;
        margin-top: 10px;

        &:hover {
          color: $hover;
        }
      }
    }
  }

  &__top {
    display: none;
  }

  &__item {
    padding: 18px 0;

    position: relative;

    border-bottom: 1px solid $gray8;

    .filter__list & {
      padding: 8px 0;
      border: none;
    }

    &.active {
      .filter__title {
        color: $bg;
      }
    }

    &:last-of-type {
      margin-bottom: 20px;
    }
  }

  &__container {
    display: none;
    margin-top: 10px;

    .filter__list & {
      margin-top: 10px;
    }

    .open & {
      display: block;
    }
  }

  &__title {
    position: relative;

    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    color: $black2;

    cursor: pointer;

    & + .filter__title-t-sr {
      margin-top: 32px;
    }

    &-m {
      display: none;
      margin-bottom: 12px;

      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $black2;

      &:hover {
        color: $bg;
      }

      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3),
      &:nth-of-type(4) {
        display: block;
      }

      .on & {
        display: block;
      }

    }

    &-t {
      display: block;

      position: relative;

      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: $black;

      &:after {
        content: '';
        display: inline-block;
        width: 12px;
        height: 12px;

        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;

        transition: 0.3s;
        background-image: url("../images/svg/arrow.svg");
        background-repeat: no-repeat;
        background-position: center;
        transform: rotate(90deg);
      }

      .open & {
        &:after {
          transform: rotate(0deg);
        }
      }

      .tw & {
        text-transform: uppercase;

        &:after {
          display: none;
        }
      }

      &-inner {
        padding-bottom: 35px;

        .open & {
          display: block;
        }

        .tw & {
          padding-bottom: 0;
          padding-top: 15px;
        }
      }

      &-btn {
        margin-top: 10px;

        font-weight: 500;
        font-size: 14px;
        line-height: 130%;
        color: $black2;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
          color: $ye;
        }

        .tw & {
          .open & {
            display: none;
          }
        }

        .on & {
          display: none;
        }
      }
    }


    &:after {
      content: '';
      display: inline-block;
      width: 16px;
      height: 16px;

      position: absolute;
      top: 5px;
      right: 0;
      z-index: 1;

      background-image: url("../images/svg/arrow.svg");
      background-repeat: no-repeat;
      background-position: center;
      transform: rotate(90deg);
      transition: 0.3s;

      .open & {
        transform: rotate(270deg)!important;
      }
    }

    &.open {
      &:after {
        transform: rotate(180deg);
      }
    }

  }

  &__all {
    span {
      font-size: 13px;
      line-height: 140%;
      letter-spacing: 0.01em;
      color: $bg;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }

      &.show {
        display: block;
      }

      &.hide {
        display: none;
      }
    }

    &.open {
      span {
        &.show {
          display: none;
        }

        &.hide {
          display: block;
          margin-top: 10px;
        }
      }
    }
  }

  &__group {
    &-check {
      position: relative;
      height: 155px;

      overflow: hidden;

      .filter__list.tw & {
        height: auto;

        overflow: inherit;
      }

      .filter__check {
        display: none;

        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(4),
        &:nth-of-type(5) {
          display: block;
        }

        .on & {
          display: block!important;
        }
      }
    }
  }

  &__check {
    margin-bottom: 7px;

    position: relative;

    &:last-of-type {
      margin-bottom: 0;
    }

    .filter__group-check.open & {
      display: block;
    }

    input {
      &[type="checkbox"] {
        display: none;

        & + label {
          padding-left: 30px;

          position: relative;

          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: $black2;
          cursor: pointer;
          transition: 0.3s;

          &:before {
            content: '';
            width: 20px;
            height: 20px;
            display: block;

            position: absolute;
            top: -1px;
            left: 0;
            z-index: 2;

            border-radius: 3px;
            border: 1px solid $gray6;
            transition: 0.3s;
          }

          &:hover {
            color: $ye;

            &:before {
              border-color: $ye;
            }
          }

        }

        &:checked {
          & + label {
            &:before {
              background-image: url("../images/svg/check.svg");
              background-repeat: no-repeat;
              background-size: 20px 20px;
              background-position: center;
              background-color: $black2;
              border-color: $black2;
            }
          }
        }
      }
    }
  }

  &__list {
    padding: 20px 0;
    border-top: 4px solid rgba(44,44,44,0.2);

    ul {
      padding-left: 25px;
      margin-bottom: 0;

      li {
        margin-bottom: 6px;

        position: relative;

        &:last-of-type {
          margin-bottom: 0;
        }

        &.dp {
          &:after {
            content: '';
            width: 6px;
            height: 6px;
            display: block;
            position: absolute;
            top: 10px;
            right: 0;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url(../images/svg/arrow2.svg);
            background-size: 6px 6px;
            -webkit-transition: 0.3s;
            transition: 0.3s;
          }
        }

        a {
          font-size: 12px;
          line-height: 166%;
          color: $black2;

          &:hover {
            color: $bg;
          }
        }

        span {
          font-size: 12px;
          line-height: 166%;
          color: $black2;
          cursor: pointer;
        }

        &.active {
          a {
            color: $bg;
          }
        }

        ul {
          display: none;
          margin-top: 10px;

          li {
            position: relative;
          }
        }
      }

      &.op {
        li {
          ul {
            display: block;
          }

          &.dp {
            &:after {
              top: 7px;
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }

  &__range {
    &-item {
      position: absolute;
      top: 55px;

      &.on {
        left: 0;

        &:before {
          content: 'От';
          display: block;

          position: absolute;
          left: 13px;
          top: 11px;

          font-size: 14px;
          line-height: 120%;
          color: $gray6;
        }
      }

      &.tw {
        right: 0;

        &:before {
          content: 'До';
          display: block;

          position: absolute;
          left: 13px;
          top: 11px;

          font-size: 14px;
          line-height: 120%;
          color: $gray6;
        }
      }
    }

    &.polzunok-container-5 {
      margin: 75px 0 5px;

      box-sizing: border-box;
      outline: none;

      &:after {
        content: "";
        clear: both;
        display: table;
      }

      .ui-slider {
        height: 4px;

        position: relative;

        background: $gray2;
        border-radius: 2px;

        .ui-slider-range {
          height: 4px;

          position: absolute;
          z-index: 1;

          border: none;
          background: $black2;
        }
      }

      .ui-slider-handle {
        width: 16px;
        height: 16px;
        margin-left: -6px;

        position: absolute;
        top: -5px;

        cursor: pointer;
        background: $black2;
        z-index: 2;

        border-radius: 100px;

        &:focus {
          outline: none;
        }
      }

      .polzunok-input-5-left,
      .polzunok-input-5-right {
        display: inline-block;
        width: 109px;
        height: 37px;
        padding: 10px 10px 10px 35px;

        font-size: 14px;
        line-height: 17px;
        background: $white;
        color: $black2;
        border-radius: 2px;
        border: 1px solid $gray2!important;

        &:focus {
          border-color: $bg;
        }
      }

      .polzunok-input-5-left {
        left: 0;

      }

      .polzunok-input-5-right {
        right: 0;

      }
    }
  }

}

.select {
  background-image: url("../images/svg/ar.svg");
  background-position: center right;
  background-repeat: no-repeat;

  select {
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: $black2;
    background: transparent;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}

.page-top {
  padding: 33px 0 40px;

  h1 {
    margin-bottom: 0;
  }
}

.breadcrumb {
  padding: 0;

  &-block {
    margin-bottom: 15px;

    .breadcrumb {
      padding: 0;
      margin: 0;

      background: transparent;

      &-item {
        font-size: 14px;
        line-height: 120%;
        color: $gray6;

        a {
          font-size: 14px;
          line-height: 120%;
          color: $black2;

          &:hover {
            color: $ye;
          }
        }

        & + .breadcrumb-item {
          &:before {
            content: '—';
            display: inline-block;
            vertical-align: middle;
            margin: 0 8px 0 1px;
            padding: 0;

            font-size: 14px;
            line-height: 120%;
            color: $gray6;
          }
        }
      }
    }
  }
}

.form {
  &__inner {
    input {
      margin-bottom: 20px;
    }

    .button {
      width: 100%;

      text-align: center;
    }
  }

  &__title {
    margin-bottom: 10px;

    font-size: 24px;
    line-height: 130%;
    color: $black2;
    font-family: $font-family2;
  }

  p {
    margin-bottom: 20px;

    font-size: 14px;
    line-height: 120%;
    color: $gray6;
  }

  &__text {
    text-align: center;
  }

  &__tx {
    margin-top: 5px;
    margin-bottom: 20px;

    font-size: 14px;
    line-height: 120%;
    color: $gray6;

    a {
      color: $black2;

      &:hover {
        color: $ye;
      }
    }
  }
}

.bg {
  background: $bg;
}

.size {

  .container {
    position: relative;
  }

  &__inner {
    max-width: 937px;
    margin: auto;
  }
}

.actions {
  &-item {
    margin-bottom: 40px;

    .container {
      position: relative;
    }

    &__inner {
      margin-top: 20px;
    }

    &__pages {
      margin-top: 60px;
      padding: 25px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      border-top: 1px solid $gray9;

      &-prev,
      &-next {
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: $black2;

        svg {
          width: 40px;
          height: 10px;

          use {
            color: $black2;
          }
        }

        &:hover {
          color: $hover;

          use {
            color: $hover;
          }
        }
      }

      &-prev {
        svg {
          margin-right: 5px;
          transform: rotate(180deg);
        }
      }

      &-next {
        svg {
          margin-left: 5px;
        }
      }
    }
  }
}

.data {
  margin-top: 17px;
  display: inline-block;
  padding: 9px 14px;

  background: $hover;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: $black2;
  border-radius: 3px;
}


input {
  width: 100%;
  height: 55px;
  padding: 3px 20px;

  font-size: 14px;
  line-height: 19px;
  color: $black2;

  background: $white;
  border-radius: 5px;
  border: none;
  box-shadow: none;

  &::-webkit-input-placeholder { color: $gray6; }
  &:-ms-input-placeholder { color: $gray6; }
  &::placeholder { color: $gray6; }
}

textarea {
  height: 87px;
  width: 100%;
  padding: 10px 20px;

  font-size: 14px;
  line-height: 19px;
  color: $black2;

  background: $white;
  border-radius: 5px;
  border: none;
  box-shadow: none;

  &::-webkit-input-placeholder { color: $gray6; }
  &:-ms-input-placeholder { color: $gray6; }
  &::placeholder { color: $gray6; }
}


.soc-l {
  position: absolute;
  top: 65px;
  left: 15px;
  z-index: 2;

  &-item {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;

    border-radius: 100px;
    border: 1px solid $gray2;

    svg {
      width: 20px;
      height: 20px;

      use {
        color: $black;
      }
    }

    &:hover {
      background: $black2;

      svg {
        use {
          color: $white;
        }
      }
    }
  }
}

.delivery {
  margin-top: 40px;

  &-title {
    margin-bottom: 10px;

    font-family: $font-family2;
    font-size: 24px;
    line-height: 130%;
    color: $black;
  }

  p {
    font-size: 18px;
    line-height: 180%;
    color: $gray6;

    & + .form__title {
      margin-top: 38px;
    }
  }
}

.cart-product {
  &__holder {

  }

  &__inner {
    display: flex;
    align-items: flex-start;

    position: relative;
  }

  &__slider {
    width: 800px;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;


    position: sticky;
    top: 0;

    background: $white;

    &-nav {
      width: 119px;
      display: flex;
      align-items: baseline;

      position: absolute;
      left: 0;
      top: 31px;

      opacity: 0;

      &.slick-initialized {
        display: block;
        opacity: 1;
        transition:opacity .3s ease-out;
      }

      .slick-list {
        display: flex;
        align-items: baseline;
      }

      .slick {
        &-track {

        }
      }

      &-item {
        width: 117px!important;
        height: 117px!important;
        margin-bottom: 2px;
        padding: 10px 5px;
        border-radius: 4px;

        border: 2px solid transparent;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;

        .slick-current.slick-active & {
          border-color: $ye;
          opacity: 1;
        }

        &-inner {
          width: 100%;
          height: 100%;

          position: relative;

          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;

          &.video {
            &:before {
              content: '';
              width: 35px;
              height: 35px;

              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              margin: auto;
              z-index: 2;

              background-color: $white;
              background-image: url("../images/svg/play2.svg");
              background-repeat: no-repeat;
              background-position: center;
              border-radius: 100px;
            }
          }
        }
      }
    }

    &-for {
      width: 568px;
      height: 600px;
      margin-left: 20px;
      opacity: 0;

      &.slick-initialized {
        display: block;
        opacity: 1;
        transition:opacity .3s ease-out;
      }

      .slick {
        &-arrow {
          width: 23px;
          height: 23px;

          top: inherit;
          bottom: -70px;

          span {
            border: none;
          }
        }

        &-next {
          right: -50px;
        }

        &-prev {
          left: inherit;
          right: -10px;
        }
      }

      &-item {
        padding: 40px 10px;
        width: 332px;
        height: 660px!important;

        text-align: center;

        .img {
          width: 100%;
          height: 100%;
          display: block;

          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;

          &.video {
            &:before {
              content: '';
              width: 45px;
              height: 45px;

              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              margin: auto;
              z-index: 2;

              background-color: $white;
              background-repeat: no-repeat;
              background-position: center;
              background-image: url("../images/svg/play2.svg");
              border-radius: 100px;
            }
          }
        }

      }
    }

    .catalog__item {
      &-labels,
      &-fav {
        display: none;
      }
    }
  }

  &__info {
    margin-top: 30px;
    width: calc(100% - 800px);

    &-all {
      margin-top: 40px;
    }

    &-size {
      &-title {
        display: inline-block;
        margin-bottom: 17px;

        font-weight: 600;
        font-size: 18px;
        line-height: 140%;
        color: $black2;

        position: relative;

        &:hover {
          .cart-product__info-size-title-dow {
            display: block;
          }
        }

        &-dow {
          width: 300px;
          display: none;
          padding: 10px 20px;

          position: absolute;
          bottom: 63px;
          left: 105px;

          border-radius: 4px 4px 4px 0;
          font-size: 12px;
          line-height: 150%;
          color: $black2;
          box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
          background: $white;

          &:before {
            content: '';
            width: 67px;
            height: 29px;
            display: block;

            position: absolute;
            bottom: -29px;
            left: -16px;
            z-index: 2;

            background: url("../images/ar.png");
            background-repeat: no-repeat;

          }
        }
      }

      &-list {
        margin-right: 20px;
        display: inline-block;
        vertical-align: middle;

        &-item {
          display: inline-block;
          vertical-align: middle;
          margin-bottom: 5px;

          input[type="radio"] {
            display: none;

            & + label {
              display: inline-block;
              padding: 8px 15px;
              margin-right: 3px;

              font-weight: 600;
              font-size: 18px;
              line-height: 140%;
              color: $black2;

              border-radius: 4px;
              border: 1px solid $gray2;
            }

            &:checked {
              & + label {
                background: $black2;
                color: $white;
                border-color: $black2;
              }
            }
          }
        }
      }

      &-link {
        display: inline-block;
        vertical-align: middle;

        font-weight: 600;
        font-size: 18px;
        line-height: 140%;
        color: $black2;

        &:hover {
          color: $hover;
        }
      }

      &-tx {
        margin-top: 10px;

        font-weight: 600;
        font-size: 18px;
        line-height: 140%;
        color: $gray6;
      }

      &-all {
        margin-top: 37px;
      }
    }

    &-fav,
    &-mr {
      display: inline-block;
      vertical-align: middle;
      margin-right: 30px;

      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.06em;
      text-transform: uppercase;
      color: $black2;
      cursor: pointer;

      svg {
        width: 25px;
        height: 25px;
        margin-right: 3px;

        use {
          color: $black2;
        }
      }

      &:hover {
        color: $ye;

        svg {
          use {
            color: $ye;
          }
        }
      }
    }

    &-price {
      margin-top: 20px;

      &.mob {
        display: none;
      }

      &-now {
        margin-right: 33px;
        display: inline-block;
        vertical-align: middle;

        font-weight: 600;
        font-size: 34px;
        line-height: 140%;
        color: $black2;

        &:after {
          display: inline-block;
          margin-left: 7px;
          font-family: $font-family3;
          content: "\20cf";
          font-weight: 400;
        }
      }

      &-old {
        display: inline-block;
        vertical-align: middle;

        font-weight: 600;
        font-size: 22px;
        line-height: 140%;
        color: $gray10;
        text-decoration: line-through;

        &:after {
          display: inline-block;
          margin-left: 7px;
          font-family: $font-family3;
          content: "\20cf";
          font-weight: 400;
        }
      }
    }

    &-btns {
      margin-top: 27px;

      &-link {
        display: inline-block;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        color: $black2;

        &:hover {
          color: $hover;
        }
      }

      .button {
        margin-bottom: 10px;
        margin-right: 50px;
      }
    }

    &-form {
      margin-top: 20px;
      display: flex;

      input {
        width: calc(100% - 269px);

        border: 1px solid $gray2;
        margin-right: 10px;
      }

      .button {
        width: 269px;
        padding: 17px 3px;
        box-sizing: content-box;
      }
    }

    &-descr {
      padding: 20px 25px;
      margin-top: 30px;

      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: 4px;

      &-title {
        margin-bottom: 8px;

        font-weight: 600;
        font-size: 18px;
        line-height: 140%;
        color: $black;
      }

      p {
        margin-bottom: 12px;

        font-size: 14px;
        line-height: 160%;
        color: $black2;
      }

      &-list {
        margin-bottom: 11px;

        &-item {
          margin-bottom: 7px;
          display: inline-block;
          vertical-align: middle;
          margin-right: 20px;

          font-weight: 700;
          font-size: 12px;
          line-height: 160%;
          letter-spacing: 0.06em;
          text-transform: uppercase;
          color: $black2;

          b {
            font-weight: 800;
            font-size: 14px;
            line-height: 160%;
            letter-spacing: 0.06em;
            text-transform: uppercase;
            color: $black2;
          }
        }
      }

      &-link {
        color: $black2;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.06em;
        text-transform: uppercase;

        &:hover {
          color: $ye;
        }
      }
    }

    &-acc {
      margin-top: 30px;

      &-list {
        width: 50%;

        &-row {
          margin-bottom: 13px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          position: relative;

          &:before {
            content: '';
            width: 100%;
            height: 1px;
            display: block;

            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 2;

            background: $gray8;
          }
        }

        &-item {
          position: relative;
          z-index: 3;

          font-size: 14px;
          line-height: 20px;

          background: $white;


          &:first-of-type {
            color: $gray6;
            padding-right: 5px;
          }

          &:last-of-type {
            padding-left: 5px ;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: $black2;
          }
        }
      }

      .accordion {
        &-item {
          border: none;
          background: transparent;

          border-top: 1px solid $gray2;
        }

        &-header {
          border: none;
          background: transparent;
        }

        &-button {
          padding: 20px 0;

          position: relative;

          font-weight: 700;
          font-size: 14px;
          line-height: 19px;
          letter-spacing: 0.06em;
          text-transform: uppercase;
          color: $black2;
          background: transparent;
          box-shadow: none;

          &:after {
            display: none;
          }

          &:before {
            content: '';
            width: 12px;
            height: 12px;
            display: block;

            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;

            background-repeat: no-repeat;
            background-image: url("../images/svg/arrow3.svg");
            transition: 0.3s;
          }

          &[aria-expanded="true"] {
            &:before {
              transform: rotate(180deg);
            }
          }
        }

        &-collapse {

        }

        &-body {
          padding: 0 0 10px;

          p {
            font-size: 18px;
            line-height: 160%;
            color: $gray;
          }
        }
      }
    }
  }

  &__top {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    &.mob {
      display: none;
    }

    &-inf {
      &-art {
        padding-right: 16px;
        display: inline-block;
        vertical-align: middle;

        position: relative;

        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        color: $gray6;

        &:before {
          content: '';
          width: 3px;
          height: 3px;
          display: block;

          position: absolute;
          right: 5px;
          top: 10px;
          z-index: 2;

          background: $gray6;
          border-radius: 100px;
        }
      }

      &-nl {
        display: inline-block;
        vertical-align: middle;


        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        color: $gr;
      }
    }
  }
}

.address {
  margin-bottom: 50px;
  margin-top: 35px;

  &__info {
    display: flex;

    background-image: url("../images/address-bg.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &-img {
      width: 41%;
      min-height: 400px;

      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

    }

    &-text {
      width: 59%;
      padding: 40px 70px;

      &-ln {
        font-weight: 600;
        font-size: 22px;
        line-height: 140%;
        text-transform: uppercase;
        color: $white;

        &:hover {
          color: $hover;
        }
      }

      h2 {
        color: $white;
      }

      &-list {
        display: flex;
        margin-top: 50px;

        &-col {
          width: 50%;
        }

        &-item {
          margin-bottom: 15px;
        }

        &-title {
          margin-bottom: 3px;

          font-weight: 600;
          font-size: 18px;
          line-height: 140%;
          color: $white;
          opacity: 0.5;
        }

        &-tx {
          font-weight: 600;
          font-size: 18px;
          line-height: 140%;
          color: $white;
        }

        &-link {
          font-weight: 600;
          font-size: 18px;
          line-height: 140%;
          color: $white;

          &:hover {
            color: $hover;
          }
        }
      }
    }
  }

  &__tb {
    margin-top: 60px;
  }

  .catalog-page__content-bottom {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  &__map {
    margin-top: 60px;
    display: flex;

    &-address {
      width: 520px;
      padding-right: 20px;


      position: relative;

      &:after {
        content: '';
        width: 100%;
        height: 70px;

        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 3;

        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 85.76%);
      }

      &-inner {
        height: 663px;
        //padding-bottom: 80px;
        overflow: auto;
      }

      &-item {
        padding: 20px 0;


        border-top: 1px solid $gray2;

        &:last-of-type {
          padding-bottom: 50px;
        }

        &-title {
          margin-bottom: 23px;
          padding: 0 15px;

          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: 0.06em;
          text-transform: uppercase;
          color: $black4;
        }

        &-ad {
          padding: 10px 15px;
          margin-bottom: 10px;

          cursor: pointer;

          &.active {
            background: $gray11;
            border-radius: 4px;
          }

          &-title {
            margin-bottom: 10px;

            color: $black2;
            font-weight: 700;
            font-size: 18px;
            line-height: 140%;
          }

          &-tx {
            margin-bottom: 5px;
            font-size: 14px;
            line-height: 120%;
            color: $gray6;
          }
        }
      }
    }

    &-mp {
      //width: calc(100% - 520px);
      height: 663px;

      position: relative;

      #map {
        width: 100%;
        height: 100%;

        position: absolute;
        top: 0;
        left: 0;
        z-index: 5;
      }
    }
  }
}

.ymaps-2-1-79-balloon__close+.ymaps-2-1-79-balloon__content {
  margin-right: 0!important;
  padding: 0!important;
}

.ymaps-2-1-79-balloon__close-button {
  display: none!important;
}

.ymaps-2-1-79-balloon__content > ymaps {
  width: 300px!important;
}

.ymaps-2-1-79-balloon__tail {
  left: 0!important;

  &:after {
    transform: rotate(102deg)
  }
}

.ymaps-2-1-79-balloon {
  top: -430px!important;
}

.ballon {
  &-img {
    height: 160px;
    margin-bottom: 13px;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  &__title {
    margin-bottom: 5px;
    padding: 0 20px;
    font-size: 14px;
    line-height: 120%;
    color: $black;
  }

  &__addr {
    height: 42px;
    overflow: hidden;
    margin-bottom: 15px;
    padding: 0 20px;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: $black2;
  }

  &__tel,
  &__time {
    padding: 0 20px;
    font-size: 12px;
    line-height: 170%;
    color: $gray6;
  }

  &__btn {
    margin-top: 10px;
    display: block;
    width: 100%;
    padding: 18px 5px;

    border-top: 1px solid $gray2;

    text-align: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: $black2;
    text-transform: uppercase;

    &:hover {
      color: $hover;
    }
  }
}

.enter {
  background-position: center 100%;
  background-size: cover;
  background-repeat: no-repeat;


  .page-top {
    padding-bottom: 0;
  }

  & + .footer {
    margin-top: 0;
  }

  &__inner {
    padding: 50px 0;
    height: 820px;
    position: relative;

  }

  &__top {
    &-tx {
      font-weight: 600;
      font-size: 18px;
      line-height: 140%;
      color: $gray6;

      svg {
        width: 18px;
        height: 18px;

        use {
          color: $gray;
        }
      }
    }
  }

  &__form {
    width: 407px;
    height: 83%;
    display: flex;
    align-items: center;

    .enter__top-tx {
      margin-bottom: 45px;
    }

    hr {
      background: $gray2;
    }

    &-check {
      margin-top: 10px;

      & + button {
        margin-top: 50px;
      }

      input {
        &[type="checkbox"] {
          display: none;

          & + label {
            padding-left: 30px;

            position: relative;

            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: $black2;
            cursor: pointer;
            transition: 0.3s;

            a {
              color: $black2;

              &:hover {
                color: $hover;
              }
            }

            &:before {
              content: '';
              width: 20px;
              height: 20px;
              display: block;

              position: absolute;
              top: -1px;
              left: 0;
              z-index: 2;

              border-radius: 3px;
              border: 1px solid $gray6;
              transition: 0.3s;
            }

            &:hover {


              &:before {
                border-color: $ye;
              }
            }

          }

          &:checked {
            & + label {
              &:before {
                background-image: url("../images/svg/check.svg");
                background-repeat: no-repeat;
                background-size: 20px 20px;
                background-position: center;
                background-color: $black2;
                border-color: $black2;
              }
            }
          }
        }
      }
    }

    input {
      margin-bottom: 20px;

      & + hr {
        margin-top: 0;
      }
    }

    .button {
      text-align: center;
      width: 100%;
    }

    &-tx {
      margin-top: 20px;

      text-align: center;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: $gray;

      a {
        color: $black2;

        &:hover {
          color: $hover;
        }
      }
    }

    &-pass {
      margin-bottom: 20px;

      text-align: right;

      a {
        font-size: 14px;
        line-height: 120%;
        color: $gray;

        &:hover {
          color: $hover;
        }
      }
    }
  }
}

.cart {
  &-in {
    &__inner {
      display: flex;
      align-items: flex-start;
    }

    &__left {
      width: calc(100% - 527px);
      padding-right: 20px;
    }

    &__all {
      width: 527px;

      &-inner {
        padding: 45px 40px;

        background: linear-gradient(294.11deg, #E6E6E6 0%, #F7F7F7 56.04%, #E6E6E6 114.47%);
        border-radius: 4px;

        .button {
          width: 100%;

          text-align: center;
        }
      }

      &-promo {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .button {
          width: 187px;
          background: $hover;
          color: $black2;
          border-radius: 0 4px 4px 0;
          color: $black2!important;

          &:hover {
            background: $black2;
            color: $white!important;
          }
        }
      }

      &-price {
        margin-bottom: 15px;

        text-align: right;

        &-title {
          margin-bottom: 5px;

          font-weight: 600;
          font-size: 16px;
          line-height: 140%;
          color: $gray6;
        }

        &-now {
          display: inline-block;
          vertical-align: middle;
          margin-left: 15px;

          font-weight: 600;
          font-size: 34px;
          line-height: 140%;
          color: $black2;
          text-transform: uppercase;

          &:after {
            display: inline-block;
            margin-left: 7px;
            font-family: $font-family3;
            content: "\20cf";
            font-weight: 400;
          }
        }

        &-old {
          display: inline-block;
          vertical-align: middle;

          text-decoration: line-through;
          font-weight: 600;
          font-size: 18px;
          line-height: 140%;
          color: $gray3;


          &:after {
            font-weight: 400;
            display: inline-block;
            margin-left: 7px;
            font-family: $font-family3;
            content: "\20cf";
          }
        }
      }
    }

    &__item {
      margin-bottom: 10px;
      padding: 20px 50px 20px 20px;
      display: flex;
      align-items: center;

      border: 1px solid $gray8;
      border-radius: 4px;

      &-close {
        cursor: pointer;

        svg {
          width: 18px;
          height: 18px;

          use {
            color: $gray6;
          }
        }

        &:hover {
          color: $hover;
        }
      }

      &-img {
        width: 100px;
        height: 100px;

        img {
          max-width: 100%;
          max-height: 98px;
        }
      }

      &-info {
        padding: 0 15px 0 20px;
        width: calc(100% - 506px);

        &-tx {
          margin-bottom: 5px;
          font-size: 14px;
          line-height: 120%;
          color: $gray6;
        }
      }

      &-title {
        margin-bottom: 10px;
        display: block;

        font-size: 24px;
        line-height: 130%;
        color: $black2;
        font-family: $font-family2;

        &:hover {
          color: $hover;
        }
      }

      &-price {
        width: 100px;

        text-align: right;

        &-now {
          font-weight: 600;
          font-size: 18px;
          line-height: 140%;
          color: $black2;
          text-transform: uppercase;

          &:after {
            display: inline-block;
            margin-left: 7px;
            font-family: $font-family3;
            content: "\20cf";
            font-weight: 400;
          }
        }

        &-old {
          text-decoration: line-through;
          font-weight: 600;
          font-size: 16px;
          line-height: 140%;
          color: $gray3;


          &:after {
            font-weight: 400;
            display: inline-block;
            margin-left: 7px;
            font-family: $font-family3;
            content: "\20cf";
          }
        }
      }

      &-amount {
        width: 200px;

        position: relative;

        &-nb {
          font-weight: 600;
          font-size: 18px;
          line-height: 140%;
          color: $black2;
        }

        .amount {
          margin: auto;
          width: 140px;
          position: relative;

          background: $bg;
        }

        &:before {
          content: '';
          width: 100%;
          height: 100%;

          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;

          background: transparent;
        }

        input {
          width: 100%;
          height: 50px;
          padding: 5px 40px;

          border: none;
          font-size: 16px;
          line-height: 125%;
          color: $black2;
          background: $bg;
          border-radius: 2px;
          text-align: center;
        }

        .up,
        .down {
          width: 20px;
          height: 20px;

          display: flex;
          align-items: center;
          justify-content: center;

          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          z-index: 3;

          cursor: pointer;
          transition: 0.3s;

          &:before {
            content: '';
            width: 12px;
            height: 2px;
            display: block;

            border-radius: 2px;
            background: $black2;
          }

          &:hover {

            &:before,
            &:after {
              background: $hover;
            }
          }
        }

        .down {
          left: 15px;
        }

        .up {
          right: 15px;

          &:after {
            content: '';
            width: 2px;
            height: 12px;
            display: block;

            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;

            border-radius: 2px;
            background: $black2;
          }
        }
      }
    }
  }

  &-order {
    &__total {
      width: 100%;
      border-radius: 4px;

      background: linear-gradient(294.11deg, #E6E6E6 0%, #F7F7F7 56.04%, #E6E6E6 114.47%);

      &-inner {
        padding: 38px 38px 0;
      }

      &-ct {
        input {
          height: 50px;
          width: 100%;
          padding: 5px 23px;
          margin-bottom: 13px;

          background: $bg;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: $black2;
          border: none;

          &::-webkit-input-placeholder { color: rgba(44,44,44,0.5);  }
          &:-ms-input-placeholder { color: rgba(44,44,44,0.5);  }
          &::placeholder { color: rgba(44,44,44,0.5);  }
        }

        &-form {
          .button-bord {
            display: block;
            width: 100%;
            padding: 10px;

            text-transform: inherit;
          }
        }

        &-btn {
          padding: 20px 0;
          border-top: 2px solid $bg;

          .button {
            width: 100%;
          }
        }

        &-all {
          margin: 30px 0;

          font-weight: 600;
          font-size: 24px;
          line-height: 29px;
          color: $black2;
          text-align: right;

          span {
            margin-left: 15px;

            color: $bg;

            &:after {
              display: inline-block;
              margin-left: 7px;
              font-family: $font-family2;
              content: "\20cf";
            }
          }
        }

        &-close {
          position: absolute;
          top: -95px;
          right: 0;
          z-index: 2;

          font-weight: 600;
          font-size: 16px;
          line-height: 136.9%;
          color: $bg;
          cursor: pointer;
          transition: 0.3s;

          svg {
            width: 16px;
            height: 16px;

            use {
              color: $bg;
            }
          }

          &:hover {
            color: $black2;

            svg {
              use {
                color: $black2;
              }
            }
          }
        }
      }

      &-item {
        margin-bottom: 23px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        &.all {
          padding: 30px 0 30px;
          margin-top: 20px;

          align-items: center;
          border-top: 1px solid $gray2;
        }
      }

      &-text {
        font-weight: 600;
        font-size: 18px;
        line-height: 140%;
        color: $gray6;

        .all & {
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          color: $black;
          text-align: center;
          letter-spacing: 0.03em;
          text-transform: uppercase;
        }
      }

      &-price {
        text-align: right;

        &-pr {
          font-weight: 600;
          font-size: 18px;
          line-height: 140%;
          color: $black;

          &.tw {
            &:after {
              display: none;
            }
          }

          &:after {
            display: inline-block;
            margin-left: 7px;
            font-family: $font-family3;
            content: "\20cf";
          }

          .all & {
            font-weight: 600;
            font-size: 34px;
            line-height: 140%;
            color: $black;
          }

        }

        &-min {
          font-size: 14px;
          line-height: 125%;
          color: $black2;
        }

        &-tx {
          font-weight: 600;
          font-size: 18px;
          line-height: 140%;
          color: $gray;
          margin-left: 11px;
        }
      }

      &-tt {
        display: flex;
        align-items: center;

        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: $black2;

        img {
          margin-right: 13px;
        }
      }
    }
  }
}

.ord {
  &__inner {
    display: flex;
    align-items: flex-start;
  }

  &__left {
    width: calc(100% - 527px);
  }

  &__all {
    width: 527px;

    &-prod {
      margin-bottom: 30px;

      border: 1px solid $gray8;
      border-radius: 4px;

      &-promo {
        display: flex;
        align-items: center;
        padding: 15px 35px;

        border-top: 1px solid $gray8;

        input {
          border: 1px solid $gray2;
          border-radius: 4px 0 0 4px;
        }

        .button {
          width: 187px;
          background: $hover;
          color: $black2;
          border-radius: 0 4px 4px 0;
          color: $black2!important;

          &:hover {
            background: $black2;
            color: $white!important;
          }
        }
      }

      &-list {
        padding: 20px 40px;


      }

      &-item {
        padding: 15px 0;
        display: flex;
        align-items: flex-start;

        border-bottom: 1px solid $gray8;

        &:last-of-type {
          border: none;
        }

        &-info {
          padding: 0 22px;
          width: calc(100% - 180px);
        }

        &-img {
          width: 80px;
          height: 80px;

          img {
            max-width: 100%;
            max-height: 80px;
          }
        }

        &-title {
          display: block;
          margin-bottom: 5px;

          font-weight: 600;
          font-size: 16px;
          line-height: 140%;
          color: $black2;

          &:hover {
            color: $hover;
          }
        }

        &-tx {
          margin-bottom: 10px;

          font-size: 14px;
          line-height: 120%;
          color: $gray6;
        }

        &-sh {
          font-size: 14px;
          line-height: 120%;
          color: $black2;
        }

        &-price {
          width: 100px;

          text-align: right;

          &-now {
            font-weight: 600;
            font-size: 18px;
            line-height: 140%;
            color: $black2;
            text-transform: uppercase;

            &:after {
              display: inline-block;
              margin-left: 7px;
              font-family: $font-family3;
              content: "\20cf";
              font-weight: 400;
            }
          }

          &-old {
            text-decoration: line-through;
            font-weight: 600;
            font-size: 16px;
            line-height: 140%;
            color: $gray3;


            &:after {
              font-weight: 400;
              display: inline-block;
              margin-left: 7px;
              font-family: $font-family3;
              content: "\20cf";
            }
          }
        }
      }

      &-title {
        padding: 20px 25px;

        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        color: $black2;
        background: linear-gradient(294.11deg, #E6E6E6 0%, #F7F7F7 56.04%, #E6E6E6 114.47%)
      }
    }
  }

  &__list {
    padding-right: 20px;
    max-width: 800px;

    .tab {
      &-pane {

      }

      &-content {
        margin-top: 40px;
      }
    }

    .nav {
      &-tabs {
        display: inline-flex;
        vertical-align: middle;
        border: none;
      }

      &-item {
        border: none;
      }

      &-link {
        margin: 0 10px 0 0;
        border: 1px solid $gray2!important;
        border-radius: 4px;

        font-weight: 600;
        font-size: 18px;
        line-height: 140%;
        color: $black2;

        &.active {
          border-color: $black2!important;

          color: $white;
          background: $black2;
        }
      }
    }

    &-item {
      margin-bottom: 35px;

      &-sr {
        input {
          margin-bottom: 15px;
        }

        input, textarea {
          border: 1px solid $gray2;

          &:focus {
            border-color: $black2;
          }
        }
      }

      &-title {
        margin-bottom: 30px;

        font-weight: 600;
        font-size: 18px;
        line-height: 140%;
        color: $black2;

        &-main {
          font-weight: 600;
          font-size: 18px;
          line-height: 140%;
          color: $black2;
          margin-bottom: 0;
          margin-right: 45px;
          display: inline-block;
          vertical-align: middle;
        }
      }

      &-group {
        .ord__list-item-top & {
          display: inline-block;
          vertical-align: middle;
        }

        &-item {
          margin-bottom: 15px;

          .ord__list-item-top & {
            margin-bottom: 0;

            display: inline-block;
            vertical-align: middle;
          }
        }

        &-scroll {
          height: 317px;
          overflow: auto;
        }

        input[type="radio"] {
          display: none;

          & + label {
            width: 100%;
            display: block;
            padding: 16px 15px 15px 59px;
            margin-right: 3px;

            position: relative;

            font-weight: 600;
            font-size: 18px;
            line-height: 140%;
            color: $black2;

            border-radius: 4px;
            border: none;

            cursor: pointer;

            .title {
              display: block;

              font-weight: 600;
              font-size: 18px;
              line-height: 140%;
              color: $black2;
            }

            .tx {
              display: block;

              font-size: 14px;
              line-height: 160%;
              color: $gray6;
            }

            .ord__list-item-top & {
              margin-right: 7px;
              display: inline-block;
              vertical-align: middle;

              padding: 10px 15px;

              font-weight: 600;
              font-size: 18px;
              line-height: 140%;
              color: $black2;
              border: 1px solid $gray2;
              border-radius: 4px;

              &:before,
              &:after {
                display: none;
              }
            }

            &:before {
              content: '';
              width: 20px;
              height: 20px;
              display: block;

              position: absolute;

              top: 17px;
              left: 28px;

              z-index: 2;

              border: 1px solid $gray2;
              border-radius: 100px;
            }

            &:after {
              content: '';
              width: 10px;
              height: 10px;
              display: block;
              opacity: 0;

              position: absolute;
              top: 22px;
              left: 33px;
              z-index: 2;

              border-radius: 100px;
              background: $black2;
            }
          }

          &:checked {
            & + label {
              background: $ye2;

              &:before {
                border-color: $black2;
              }

              &:after {
                opacity: 1;
              }

              .ord__list-item-top & {
                background: $black2;
                color: $white;
              }
            }
          }
        }
      }

      &-inp {

        input {
          margin-bottom: 15px;
        }

        input, textarea {
          border: 1px solid $gray2;

          &:focus {
            border-color: $black2;
          }
        }

        textarea {
          height: 140px;
        }

      }
    }
  }

  &__bottom {
    margin-top: 30px;

    &-check {
      margin-bottom: 30px;
    }

    input {
      &[type="checkbox"] {
        display: none;

        & + label {
          padding-left: 30px;

          position: relative;

          font-size: 14px;
          line-height: 120%;
          color: $gray;
          cursor: pointer;
          transition: 0.3s;

          a {
            color: $black2;

            &:hover {
              color:  $hover;
            }
          }

          &:before {
            content: '';
            width: 20px;
            height: 20px;
            display: block;

            position: absolute;
            top: -1px;
            left: 0;
            z-index: 2;

            border-radius: 3px;
            border: 1px solid $gray6;
            transition: 0.3s;
          }

          &:hover {
            &:before {
              border-color: $ye;
            }
          }

        }

        &:checked {
          & + label {
            &:before {
              background-image: url("../images/svg/check.svg");
              background-repeat: no-repeat;
              background-size: 20px 20px;
              background-position: center;
              background-color: $black2;
              border-color: $black2;
            }
          }
        }
      }
    }
  }

  &-done {

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    & + .footer {
      margin-top: 0;
    }

    &__inner {
      display: flex;
      align-items: center;
      height: 775px;
    }

    &__hol {
      width: 350px;
    }

    &__item {
      margin-bottom: 10px;

      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: $gray6;

      span {
        color: $black2;
      }
    }

    .button {
      margin-top: 40px;
    }
  }
}

.orders {
  & + .footer {
    margin-top: 0;
  }

  &__inner {
    display: flex;

    &-holder {
      margin-top: 30px;

      .button {
        margin-top: 40px;
      }
    }
  }

  &__left {
    margin-bottom: 100px;
    width: calc(100% - 345px);
    padding-right: 50px;

    &-in {
      width: 76%;
    }

    &-ctlg {
      & + .orders__left-ctlg {
        margin-top: 85px;
      }

      h2 {
        margin-bottom: 40px;

        font-size: 44px;
        line-height: 134%;
      }

      &-nn {
        margin: 100px 0;

        text-align: center;

        &-title {
          margin-top: 30px;

          font-size: 16px;
          line-height: 177%;
          color: $black2;

          a {
            color: $black2;
            font-weight: 500;

            &:hover {
              color: $hover;
            }
          }
        }
      }
    }

    &-bonus {
      .col-sm-6 {
        margin-bottom: 30px;
      }

      &-item {
        height: 100%;
        border: 1px solid $bg;
      }

      &-in {
        padding: 30px;
      }

      &-img {
        width: 100%;
        height: 111px;

        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }

      &-title {
        font-size: 26px;
        line-height: 120%;
        color: $black2;
        font-family: $font-family3;
      }

      &-numb {
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: $hover;
      }

      &-tx {
        margin-top: 16px;

        font-size: 14px;
        line-height: 166%;
        color: $black2;
      }
    }
  }

  &__right {
    width: 540px;
    margin-right: calc(-50vw + 817px);

    background: linear-gradient(345.72deg, #F0F0F0 20.19%, #FCFCFC 32.75%, #F0F0F0 50.77%);
    border-radius: 4px;
  }


  &__txx {
    font-size: 14px;
    line-height: 120%;
    color: $gray;
  }

  &__itt {
    margin-bottom: 15px;
    padding: 20px 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    border-radius: 4px;
    background: $bg;
  }

  &__title {
    p {
      margin-bottom: 5px;
      font-size: 13px;
      line-height: 140%;
      color: $gray;
    }

    .orders__status {
      margin-bottom: 10px;
      text-transform: inherit;
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
    }

    &-main {
      margin-bottom: 5px;

      font-weight: 600;
      font-size: 18px;
      line-height: 140%;
      color: $black2;

      .orders__top-big & {
        font-weight: 600;
        font-size: 16px;
        line-height: 120%;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: $black2;
      }
    }

    &-min {
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: $gray;

      .orders__top-big & {
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: rgba(44,44,44,0.5);
      }
    }
  }

  &__item {
    padding: 24px 30px;
    margin-bottom: 10px;

    background: $white;

    border: 1px solid $gray9;
    border-radius: 4px;

    & + .button-center {
      margin-top: 30px;
    }

    &-bottom {
      display: inline-block;
      padding-left: 28px;

      position: relative;

      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: $black2;

      img {
        position: absolute;
        left: 0;
      }

      &:hover {
        color: $black2;
        opacity: 0.8;
      }
    }

    &-load {
      display: inline-block;
      padding-left: 28px;

      position: relative;

      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      color: $bg;

      img {
        position: absolute;
        left: 0;
      }

      &:hover {
        color: $bg;
        opacity: 0.8;
      }
    }

    &-descr {
      padding: 15px 22px;
      margin: 22px 0;

      position: relative;

      background: $bg;
      border-radius: 4px;

      &.th {
        padding-right: 200px;
      }

      &-lt {
        &-item {
          display: inline-block;
          vertical-align: top;
          padding-right: 40px;

          &-title {
            margin-bottom: 3px;

            font-weight: 500;
            font-size: 14px;
            line-height: 140%;
            color: rgba(4,5,45,0.5);
          }

          &-tx {
            font-weight: 500;
            font-size: 16px;
            line-height: 140%;
            color: $black2;
          }
        }
      }

      .button-black {
        width: 170px;

        position: absolute;
        top: 62px;
        right: 26px;

        text-transform: capitalize;
      }

      .map {
        height: 195px;
      }

      p {
        margin-bottom: 8px;

        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        color: $gray6;
      }

      &-link {
        display: inline-block;

        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        color: $black2;

        &:hover {
          color: $hover;
        }
      }

      &-title {
        margin-bottom: 13px;

        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: $gray6;
      }

      &-count {
        margin-bottom: 5px;

        &-tit {
          display: inline-block;
          margin-right: 5px;

          font-weight: 600;
          font-size: 16px;
          line-height: 140%;
          color: $black2;
        }

        &-st {
          display: inline-block;
          padding: 1px 5px;

          font-weight: 600;
          font-size: 16px;
          line-height: 140%;
          color: $white;
          background: $black2;
          border-radius: 4px;

          &.tw {
            background: $ye;
          }
        }
      }

      &-recipient {
        &-tx {
          display: inline-block;

          font-weight: 500;
          font-size: 16px;
          line-height: 140%;
          color: $black2;
        }
      }
    }

    &-bt {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__pages {
    padding: 150px 0 20px;

    &-item {
      display: flex;
      align-items: center;
      min-height: 39px;
      padding: 18px 10px 18px 85px;

      position: relative;

      font-weight: 600;
      font-size: 18px;
      line-height: 140%;
      color: $gray;



      &:last-of-type {
        border: none;
        border-top: 1px solid rgba(0,0,0,0.1);
      }

      svg {
        width: 20px;
        height: 20px;

        position: absolute;
        top: 0;
        bottom: 0;
        left: 50px;
        margin: auto;

        use {
          color: $gray;
        }
      }

      &.active {
        color: $black2;

        svg {
          use {
            color: $black2;
          }
        }
      }

      &:hover {
        color: $hover;

        svg {
          use {
            color: $hover;
          }
        }
      }
    }
  }

  &__form {
    width: 60%;

    &-item {
      display: flex;
      align-items: center;
      margin-bottom: 18px;

      &-bt {
        align-items: flex-start;
      }
    }

    &-title {
      width: 275px;
      padding-right: 20px;

      font-weight: 600;
      font-size: 18px;
      line-height: 140%;
      color: $black2;
    }

    &-inp {
      width: calc(100% - 285px);

      .orders__form-inp-gr & {
        width: 32.333%;
        padding: 0 10px;
        box-sizing: border-box;

        &:last-of-type {
          padding-right: 0;
        }
      }

      &-tt {
        margin-top: 10px;

        font-size: 14px;
        line-height: 120%;
        color: $gray6;
      }


      &-gr {
        width: calc(100% - 260px);
        margin: 0 -10px;
        display: flex;
        align-items: center;
      }

      input {
        width: 100%;
        padding: 10px 18px;


        border: 1px solid $gray2;
        font-weight: 500;
        font-size: 14px;
        line-height: 166%;
        color: $black2;
        background: transparent;

        &:focus {
          border-color: $black2;
        }
      }

      p {
        margin-top: 20px;

        font-size: 13px;
        line-height: 140%;
        color: $black;
      }
    }

    &-btns {
      display: flex;
      align-items: center;
      margin-top: 42px;

      .button {
        display: inline-flex;
        align-items: center;
        margin-right: 20px;
        text-align: center;
      }

      .button-bord {
        padding: 17px 45px;
        font-weight: 700;
        color: $gray6;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.06em;
        text-transform: uppercase;

        &:hover {
          color: $hover;
        }
      }
    }
  }

  &__status {
    display: flex;
    align-items: center;
    text-align: right;

    font-weight: 600;
    font-size: 16px;
    line-height: 140%;

    .orders__top & {
      margin-bottom: 20px;
    }

    &-success {
      color: $black2;
      font-weight: 700;
      font-size: 18px;
      line-height: 140%;

      &:before {
        content: '';
        width: 16px;
        height: 16px;
        display: inline-block;
        margin-right: 7px;

        background-repeat: no-repeat;
        background-position: center;
        background-image: url("../images/svg/icon-status1.svg");
        background-size: 16px 16px;
      }
    }
    &-cancel {
      color: $hover;
      font-weight: 700;
      font-size: 18px;
      line-height: 140%;

      &:before {
        content: '';
        width: 16px;
        height: 16px;
        display: inline-block;
        margin-right: 7px;

        background-repeat: no-repeat;
        background-position: center;
        background-image: url("../images/svg/icon-status2.svg");
        background-size: 16px 16px;
      }
    }
  }

  &__top {
    margin-top: 50px;

    .orders__item & {
      margin-top: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__bonus {
    max-width: 1075px;

    &-tx {
      margin-bottom: 30px;

      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: $gray6;
      max-width: 700px;
    }

    &-item {
      margin-bottom: 20px;
      display: flex;
      align-items: flex-start;

      padding: 35px 40px;

      background: $bg;
      border-radius: 4px;

      &.act {
        background: linear-gradient(243.47deg, #00352C -32.38%, #28796C 26.43%, #0F6254 49.55%, #00352C 125.21%);
      }

      &-cell {
        width: 30%;
        padding-right: 30px;

        &:first-of-type {
          width: 40%;
        }
      }

      &-tx {
        &-title {
          margin-bottom: 15px;
          font-size: 24px;
          line-height: 130%;
          font-family: $font-family2;
          color: $gray;

          .act & {
            color: $white;
          }
        }

        &-tt {
          color: $gray;
          font-weight: 600;
          font-size: 16px;
          line-height: 140%;

          .act & {
            color: rgba(255,255,255,0.5);
          }
        }
      }

      &-inf {
        margin-bottom: 7px;

        font-size: 18px;
        line-height: 140%;
        color: $gray;

        span {
          padding: 0 7px;
        }

        svg {
          width: 20px;
          height: 20px;

          use {
            color: $gray;
          }
        }

        .act & {
          color: rgba(255,255,255,0.5);

          svg {
            use {
              color: rgba(255,255,255,0.5);
            }
          }
        }

        &.active {
          color: rgba(255,255,255,1);

          svg {
            use {
              color: rgba(255,255,255,1);
            }
          }
        }
      }
    }
  }

  &__ist {
    .cart-in {
      &__item {
        &-info {
          width: calc(100% - 450px);
        }

        &-price {
          width: 150px;
          text-align: right;
        }

        &-amount {
          text-align: center;
        }
      }
    }

    &-bottom {
      margin-top: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        color: $gray6;
      }

      &-right {
        text-align: right;
      }

      &-price {

        &-now {
          margin-right: 10px;
          display: inline-block;
          vertical-align: middle;

          font-weight: 600;
          font-size: 34px;
          line-height: 140%;
          color: $black2;
          text-transform: uppercase;

          &:after {
            display: inline-block;
            margin-left: 7px;
            font-family: $font-family3;
            content: "\20cf";
            font-weight: 400;
          }
        }

        &-old {
          display: inline-block;
          vertical-align: middle;
          margin-right: 20px;

          text-decoration: line-through;
          font-weight: 600;
          font-size: 18px;
          line-height: 140%;
          color: $gray3;


          &:after {
            font-weight: 400;
            display: inline-block;
            margin-left: 7px;
            font-family: $font-family3;
            content: "\20cf";
          }
        }
      }
    }
  }
}

.https404 {
  h1 {
    font-size: 150px;
    line-height: 120%;
  }

  .enter__form-in {
    width: 375px;

    text-align: center;

    .button {
      width: auto;
    }
  }
}

.policy {
  &__inner {
    max-width: 937px;
    margin: auto;
  }

  &__text {
    p {
      font-size: 18px;
      line-height: 180%;
      color: $gray6;
    }

    ul {
      li {
        padding-left: 20px!important;
        min-height: inherit!important;
        margin-bottom: 0!important;
        margin-top: 10px!important;


        position: relative;

        font-family: $font-family;
        font-size: 18px;
        line-height: 180%;
        color: $gray6;
        list-style: none;

        &:after {
          content: '';
          width: 6px;
          height: 6px;
          display: block;

          position: absolute;
          top: 15px;
          left: 0;

          background: $gray6;
          border-radius: 10px;
        }

        &:before {
          display: none;
        }
      }
    }

    ol {
      counter-reset: myCounter;
      padding-left: 0;
      margin-top: 60px;

      list-style-type: none;

      li {
        min-height: 40px;
        margin-bottom: 55px;

        position: relative;

        font-size: 34px;
        line-height: 135%;
        font-family: $font-family2;
        color: $black2;

        &:before{
          counter-increment: myCounter;
          content: counters(myCounter,".") ". ";


          font-size: 34px;
          line-height: 135%;
          font-family: $font-family2;
          color: $black2;
        }


        ol {
          margin-top: 20px;

          li {
            padding-left: 0;
            min-height: 40px;
            margin-bottom: 20px;

            position: relative;

            font-family: $font-family;
            font-size: 18px;
            line-height: 180%;
            color: $gray6;

            &:before{
              counter-increment: myCounter;
              content: counters(myCounter,".") " ";


              font-family: $font-family;
              font-size: 18px;
              line-height: 180%;
              color: $gray6;
            }


            ol {
              margin-top: 15px;

              li {
                padding-left: 0;
                min-height: 40px;
                margin-bottom: 10px;

                position: relative;

                font-family: $font-family;
                font-size: 18px;
                line-height: 180%;
                color: $gray6;

                &:before{
                  counter-increment: myCounter;
                  content: counters(myCounter,".") " ";


                  font-family: $font-family;
                  font-size: 18px;
                  line-height: 180%;
                  color: $gray6;
                }

              }
            }
          }
        }
      }
    }
  }
}

.modal {
  &-dialog {
    max-width: 527px;

    .w2 & {
      max-width: 662px;
    }

    .w3 & {
      max-width: 1620px;
    }
  }

  &-content {
    background: linear-gradient(294.11deg, #E6E6E6 0%, #F7F7F7 56.04%, #E6E6E6 114.47%);
    border-radius: 4px;
    border: none;

    .w3 & {
      background: $white;
    }

  }

  &-body {
    padding: 40px 70px 70px;

    position: relative;

    .w3 & {
      padding: 0;
    }

    .btn-close {
      width: 24px;
      height: 24px;

      position: absolute;
      top: 41px;
      right: 24px;
      z-index: 2;

      opacity: 1;

      box-shadow: none!important;

      svg {
        width: 24px;
        height: 24px;

        use {
          color: $black2;
        }
      }

      &:hover {
        svg {
          use {
            color: $hover;
          }
        }
      }
    }

    &-form {
      &-ord {
        text-align: center;

        .modal-body-form-title {
          font-size: 34px;
          line-height: 135%;
          margin-bottom: 30px;
        }

         &-item {
           margin-bottom: 10px;
          font-weight: 600;
          font-size: 16px;
          line-height: 140%;
          color: $gray6;

          span {
            color: $black2;
          }
        }

        & + .button {
          width: 100%;
          margin-top: 20px;
        }
      }

      &-title {
        margin-bottom: 10px;
        font-size: 24px;
        line-height: 130%;
        color: $black2;
        font-family: $font-family2;
        text-align: center;
      }

      &-tx {
        margin-bottom: 25px;

        font-size: 14px;
        line-height: 120%;
        color: $gray6;
        text-align: center;

        & + .button {
          width: 100%;
          margin-top: 20px;
        }
      }

      &-tt {
        margin-bottom: 23px;
        font-size: 14px;
        line-height: 120%;
        color: $gray;

        a {
          color: $black2;

          &:hover {
            color: $hover;
          }
        }
      }

      &-inner {
        input {
          margin-bottom: 15px;
        }

        .button {
          display: block;
          width: 100%;

          text-align: center;
        }
      }
    }

    &-product {
      margin: 30px 0;

      background: $white;

      &-btns {
        display: flex;
        align-items: center;

        .button {
          width: 50%;

          &-link {
            padding: 17px 22px;
            font-size: 14px;
            line-height: 19px;

            text-align: center;
            justify-content: center;
          }
        }
      }

      &-item {
        padding: 13px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-price {
          width: 150px;

          text-align: right;

          &-now {
            font-weight: 600;
            font-size: 18px;
            line-height: 140%;
            color: $black2;

            &:after {
              display: inline-block;
              margin-left: 7px;
              font-family: $font-family3;
              content: "\20cf";
              font-weight: 400;
            }
          }

          &-old {
            font-weight: 600;
            font-size: 16px;
            line-height: 140%;
            color: $gray10;
            text-decoration: line-through;

            &:after {
              display: inline-block;
              margin-left: 7px;
              font-family: $font-family3;
              content: "\20cf";
              font-weight: 400;
            }
          }
        }

        &-img {
          width: 80px;
          height: 80px;

          img {
            max-width: 100%;
            max-height: 80px;
          }
        }

        &-info {
          padding-left: 30px;
          width: calc(100% - 230px);
        }

        &-title {
          display: block;
          margin-bottom: 5px;

          font-weight: 600;
          font-size: 16px;
          line-height: 140%;
          color: $black2;

          &:hover {
            color: $hover;
          }
        }

        &-inf {
          font-size: 14px;
          line-height: 120%;
          color: $gray6;
        }
      }
    }
  }

  .address {
    &__tb {

    }

    &__map-address {
      &:after {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 85.76%);
      }

      &-item {
        &-ad {
          padding-right: 165px;
          position: relative;

          &-nl {
            width: 165px;

            position: absolute;
            bottom: 40px;
            right: 0;

            font-size: 14px;
            line-height: 120%;

            text-align: right;

            &.nn {
              color: $gr2;
            }

            &.er {
              color: $red;
            }
          }
        }
      }
    }

    &__tp {
      display: flex;
      align-items: center;

      &-title {
        width: 515px;

        font-size: 24px;
        line-height: 130%;
        color: $black2;
        font-family: $font-family2;
      }

      &-form {
        width: calc(100% - 600px);

        position: relative;

        input {
          padding: 3px 20px 3px 50px;

          background: linear-gradient(0deg, #FAF9FA, #FAF9FA), #191919;
        }

        .button-search {
          position: absolute;
          top: 14px;
          left: 20px;
          z-index: 2;

          svg {
            width: 20px;
            height: 20px;

            use {
              color: $black2;
            }
          }

          &:hover {
            svg {
              use {
                color: $hover;
              }
            }
          }
        }
      }
    }
  }
}


.slider-dots-box {

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  bottom: 35px;
  right: 1525px;
  left: 0;
  z-index: 10;
}

.slider-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
}

.slider-dots button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: block;
  border: 0;
  background-color: transparent;
  margin: 0 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 0;
  transition: .3s all;
  position: relative;
  z-index: 0;
}

.slider-dots-box .slick-active button {
  width: 40px;
  height: 40px;
  margin: 0;
}

.slider-dots-box .slider-dots li {
  width: 40px;
  height: 40px;

  position: relative;
}



.slider-dots-box .slider-dots li:before {
  content: '';
  width: 40px;
  height: 40px;
  display: block;

  position: absolute;
  top: 0;
  left: 0;


  border: 2px solid rgba(0,0,0,0.2);
  border-radius: 150px;
  opacity: 0;
}

.slider-dots-box .slider-dots li.slick-active:before {


  border: 2px solid rgba(0,0,0,0.2);
  border-radius: 150px;
  opacity: 1;
}

.slider-dots-box .slider-dots li:after {
  content: "";
  display: block;
  width: 6px;
  height: 6px;

  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: -1;
  background: $black;
  opacity: 0.2;
  border-radius: 50%;

}

.slider-dots-box .slider-dots li.slick-active:after {
  opacity: 1;
}

.circle-bg {
  fill: rgba(255, 255, 255, 0);
  stroke: rgba(0, 0, 0, 0.05);
  stroke-width: 2;
  stroke-linecap: butt;
}
.circle-go {
  fill: rgba(255, 255, 255, 0);
  stroke: $black;
  stroke-width: 2;
  stroke-linecap: round;
  animation: progress 22s;
  stroke-dasharray: 360px;
  stroke-dashoffset: 360;
}
.circle-tx {
  fill: #000;
  stroke-width: 1;
  font: bold 16px 'Arial';
  text-anchor: middle;
}

@keyframes progress {
  from {
    stroke-dashoffset: 360;
  }

  to {
    stroke-dashoffset: 0;
  }
}

.banner__inner {
  position: relative;
}




@media screen and(min-width: 991px) {
  .address {
    .nav-tabs {
      display: none;
    }

    .tab {
      &-content {
        display: flex;
        align-items: flex-start;
      }

      &-pane {
        display: block!important;
        opacity: 1!important;

        &:first-of-type {
          order: 2;
          width: calc(100% - 520px);
          height: 663px;
        }

        &:last-of-type {
          width: 520px;

          order: 1;
        }
      }
    }

  }
}

@media screen and(max-width: 1670px) {
  .orders {
    &__right {
      margin-right: -12px;
    }

    &__form {
      width: 80%;
    }
  }

  .slider-dots-box {
    left: 15px;
    right: inherit;
  }

  .banner {
    &__inner {
      .pagingInfo {
        right: 55px;
        left: inherit;
      }
    }

    &__slider {
      .slick {
        &-arrow {
          svg {

          }
        }

        &-next {
          right: 15px;
          left: inherit;
        }

        &-prev {
          right: 125px;
          left: inherit;
        }
      }
    }
  }
}

@media screen and(max-width: 1600px) {
  .banner-form {
    &__inner {
      padding-right: 30px;
      //justify-content: space-between;
    }

    &__text {
      width: 40%;
      margin-right: 20px;
    }
  }


}

@media screen and(min-width: 1400px) {
  .col-xl-2-1 {
    width: 20%!important;
  }

}

@media screen and(max-width: 1400px) {
  .header {
    &-mid__group-item {
      margin-right: 10px;
    }
  }

  .header-mid__group-item.sr {
    margin-left: 10px;
  }

  .banner__slider {
    &-img {
      position: relative;
      left: -19%;
    }

    &-holder  {
      overflow: hidden;
    }
  }


  .blog-item__text {
    padding-right: 40px;
  }

  .cart-product {
    &__slider {
      width: 600px;

      &-for {
        width: 400px;
      }
    }

    &__info {
      width: calc(100% - 600px);

      &-btns {
        .button {
          margin-right: 10px;
        }
      }
    }
  }

  .cart-in {
    &__left {
      width: calc(100% - 350px);
    }

    &__all {
      width: 350px;

      &-inner {
        padding: 30px;
      }

      &-promo {
        display: block;

        .button {
          width: 100%;
          margin-top: 5px;

          text-align: center;
          border-radius: 4px;
        }
      }

      &-price {
        &-now {
          display: block;
        }

        &-old {
          display: block;
        }
      }
    }
  }

  .orders__bonus {
    &-item {
      flex-wrap: wrap;
    }

    &-item {
      &-cell {
        width: 50%;
        flex: 0 0 50%;
        max-width: 50%;

        &:first-of-type {
          margin-bottom: 30px;
          width: 100%;
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }
  }

  .orders__ist  {
    .cart-in__item {
      &-amount {
        width: 50px;
      }

      &-price {
        width: 110px;
      }

      &-info {
        width: calc(100% - 240px);
      }
    }
  }

}

@media screen and(max-width: 1300px) {
  .banner-form {
    &__inner {
      background-position: 30% center;
    }
  }



  .row-right .blog__text {
    padding-left: 0;
  }

  .banner-form {
    &__holder {
      padding: 30px 30px 30px 50px;

      &:before {
        width: 500px;
        height: 500px;

        background-size: contain;
        background-position: center bottom;
      }
    }

    &.tw {
      .banner-form__text  {
        width: 700px;
      }
    }
  }

  .orders__form {
    width: 100%;
  }
}

@media screen and(max-width: 1200px) {
  .header-mid__group-search-block-list-item {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .header {
      &__logo {
        width: 230px;

        img {
          max-width: 100%;
        }
      }

      &-mid {
        &__group {
          width: 37%;

          &-item  {
            span {
              font-size: 12px;
            }

            svg {
              margin-right: 3px;
            }
          }

          &-search {
            &-block {
              box-shadow: 0 0 10px rgba(0,0,0,0.15);

              top: 57px;

              background: $white;

              &-inp {
                background: transparent;

                &-inner {
                  padding: 0;
                  margin: 15px 0;
                  background: $bg2;

                  input {
                    height: 56px;
                    padding-left: 60px;

                    font-size: 14px;
                    line-height: 19px;
                  }

                  .button-search {
                    left: 20px;
                    top: 14px;
                  }
                }
              }

              &-list {
                padding: 0 0 15px;

                &-in {
                  display: block;
                }

                &-item {
                  max-width: 100%;
                  flex: 0 0 100%;
                  margin-bottom: 10px;
                }

                &-tt {
                  display: none;
                }

                &-left {
                  width: 100%;
                  padding: 0 0 6px;

                  border-bottom: 1px solid $gray2;

                  ul {
                    li {
                      margin-bottom: 13px;
                    }
                  }
                }

                &-inner {
                  display: block;
                }

                &-prod {
                  padding-left: 0;
                  width: 100%;
                }
              }
            }
          }
        }
      }

      &-top__menu ul li a {
        font-size: 12px;
      }
    }

  .services__item-title {
    font-size: 16px;
  }

  .banner {
    &-form {
      &__text {
        width: 40%;

        &-in {
          display: block;

          input {
            margin-bottom: 20px;
          }

          .button {
            width: 100%;
          }
        }
      }
    }

    &-ab {
      &__text {
        padding-top: 140px;
      }
    }
  }

  .footer {
    &__text {
      width: 250px;
    }

    &__logo {
      img {
        max-width: 100%;
      }
    }

    &__menu {
      flex-wrap: wrap;
    }

    &__item {
      width: 50%;
      margin-bottom: 20px;
    }
  }

  .blog-item {
    &__text {
      width: calc(100% - 350px);
      padding-right: 40px;
    }

    &__form {
      width: 350px;
    }
  }

  .cart-product {
    &__slider {
      width: 500px;

      &-for {
        width: 300px;
      }
    }

    &__info {
      width: calc(100% - 500px);

      &-form {
        display: block;

        input {
          width: 100%;
          margin-bottom: 10px;
        }
      }

      &-acc {
        &-list {
          width: 100%;
        }
      }
    }
  }

  .banner-form {
    &__holder {
    }

    &.tw {
      .banner-form__text {
        width: 550px;
      }
    }
  }

  .enter__inner {
    background-position: 191px center;
  }

  .cart-in {
    &__inner {
      flex-wrap: wrap;
    }

    &__left {
      width: 100%;
      padding-right: 0;
    }

    &__all {
      width: 600px;

      &-promo {
        display: flex;

        .button {
          margin-top: 0;
          width: 187px;
        }
      }

      &-price {
        &-old {
          display: inline-block;
          vertical-align: middle;
        }

        &-now {
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
  }

  .orders__ist-bottom {
    &-price {
      &-old {
        display: block;
        margin-right: 0;
      }
    }
  }
}

@media screen and(max-width: 1100px) {
  .soc-l {
    display: none;
  }
}

@media screen and(max-width: 991px) {
  .slider-dots-box {
    bottom: 20px;

    .slider-dots {
      min-height: inherit;

      li {
        width: 6px;
        height: 6px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 8px;

        &:after {
          background: $white;
        }

        button {
          display: none;
        }

        &:before {
          display: none;
        }
      }
    }
  }

  h1 {
    font-size: 28px;
    line-height: 130%;

    span {
      font-size: 12px;
      line-height: 130%;
    }
  }

  h2 {
    margin-bottom: 10px;

    font-size: 20px;
    line-height: 130%;
  }

  .page-top {
    padding: 33px 0 15px;
  }

  .data {
    font-size: 10px;
    padding: 6px 8px;
  }

  .button {
    &-primary {
      padding: 14px 30px;

      font-size: 14px;
      line-height: 19px;
      text-align: center;
    }
  }

  .title-main {
    margin-bottom: 10px;

    .button-link {
      width: 100%;
      padding: 15px;
      position: absolute;
      bottom: 0;

      font-weight: 700;
      background: $gray8;
      border-radius: 4px;
      text-align: center;
      justify-content: center;
    }
  }

  .header {
    display: none;

    &-mob {
      display: block;

      &-top {
        position: relative;
        z-index: 100;

        padding: 12px 0;

        background: $white;
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);

        &-inner {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        &-logo {
          display: inline-block;
          width: 177px;

          img {
            max-width: 100%;
          }
        }

        &-link {
          width: 20px;
          height: 20px;
          display: inline-block;
          vertical-align: middle;
          margin-left: 15px;

          svg {
            width: 20px;
            height: 20px;

            use {
              color: $black2;
            }
          }
        }

        &-toggler {
          width: 20px;
          height: 20px;
          display: inline-block;
          vertical-align: middle;
          margin-left: 12px;

          svg {
            width: 20px;
            height: 20px;

            use {
              color: $black2;
            }
          }

          &.open {
            background-image: url("../images/svg/close.svg");

            svg {
              opacity: 0;
            }
          }
        }
      }

      &-all {
        display: none;
        padding: 90px 0 140px;

        width: 100%;
        height: 100vh;
        min-height: 100%;

        position: fixed;
        top: 0;
        left: 0;
        z-index: 99;

        background: $white;
        overflow: auto;

        &.open {
          display: block;
        }

        &-top {
          padding: 0 15px;
          margin-bottom: 25px;

          .header-mid__group-item {
            display: flex;
            align-items: center;
            margin-right: 0;
            margin-bottom: 19px;

            span {
              font-weight: 600;
              font-size: 14px;
              line-height: 19px;
            }

            svg {
              margin-right: 8px;
            }
          }
        }

        &-menu {
          padding: 20px 15px;

          background: $bg;

           ul {
             li {
               margin-bottom: 10px;

               &:last-of-type {
                 margin-bottom: 0;
               }

               a {
                 font-weight: 700;
                 font-size: 14px;
                 line-height: 19px;
                 color: $black2;
                 letter-spacing: 0.06em;
                 text-transform: uppercase;

                 &:hover {
                   color: $hover;
                 }
               }
             }
           }
        }

        &-od {
          padding: 18px 15px 10px;

          border-bottom: 1px solid $gray2;

          ul {
            li {
              margin-bottom: 10px;

              a {
                color: $gray6;
                font-weight: 600;
                font-size: 14px;
                line-height: 19px;
                letter-spacing: 0.06em;
                text-transform: uppercase;
              }
            }
          }
        }

        &-info {
          padding: 18px 15px;

          &-tel {
            margin-bottom: 5px;
            display: inline-block;

            font-weight: 700;
            font-size: 18px;
            line-height: 25px;
            letter-spacing: 0.06em;
            text-transform: uppercase;
            color: $black2;
          }

          &-time {
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            color: $gray;
            letter-spacing: 0.06em;
            text-transform: uppercase;
          }
        }
      }
    }
  }

  .banner {
    &__inner {
      .pagingInfo {
        display: none;
      }
    }

    &__slider {
      &-inner {
        .container {
          padding: 0;
        }
      }

      &-holder {
        height: 480px;
        padding: 0 15px;

        align-items: flex-end;

        position: relative;

        &:before {
          content: '';
          width: 100%;
          height: 102px;
          display: block;

          position: absolute;
          left: 0;
          bottom: 0;
          z-index: 2;

          background: linear-gradient(77.71deg, #6D513E 26.09%, #AA7D63 55.53%);
          filter: blur(65px);
        }
      }

      &-img {
        width: 100%;
        position: absolute;
        bottom: 0;
        right: 0;
        left: inherit;

      }

      &-tx {
        padding: 0 0 55px;
        width: 100%;

        position: relative;
        z-index: 3;

        p {
          margin-bottom: 0;

          font-weight: 600;
          font-size: 14px;
          line-height: 160%;
          color: $white;

          br {
            display: none;
          }
        }

        &-title {
          margin-bottom: 10px;

          font-size: 28px;
          line-height: 130%;
          color: $white;
        }

        .button {
          display: none;
        }
      }
    }

    &-min {
      margin: 20px 0;

      &__item {
        margin-bottom: 10px;
        height: 130px;
        padding: 15px 20px;

        &-title {
          font-size: 18px;
          line-height: 130%;
        }

        &-link {
          bottom: 15px;

          font-weight: 600;
          font-size: 12px;
          line-height: 140%;
          left: 20px;

          svg {
            width: 12px;
            height: 12px;
          }
        }
      }
    }

    &-info {
      margin: 40px 0 20px;

      &__inner {
        margin: 0 -12px;
        display: block;
      }

      &__img {
        height: 348px;
        width: 100%;
        min-height: inherit;

        background-position: center top;

        &-m {
          display: none;
        }
      }


      &__text {
        padding: 30px 15px 50px;
        width: 100%;
        margin: 0;

        &-min {
          margin-bottom: 5px;

          font-size: 8px;
          line-height: 11px;
        }

        p {
          margin-bottom: 30px;

          font-size: 14px;
          line-height: 160%;
        }

        .button {
          width: 100%;
        }
      }
    }

    &-rek {
      margin: 30px 0;

      & + .brands {
        margin-top: -30px;
      }

      &__inner {
        height: 446px;
        margin: 0 -12px;
        padding: 20px 15px;

        display: flex;
        align-items: flex-end;
      }

      &__tx {
        width: 100%;
        margin-bottom: 20px;

        &-min {
          margin-bottom: 5px;
          font-size: 8px;
          line-height: 11px;
          color: $white;
        }

        h2 {
          margin-bottom: 20px;

          color: $white;
        }

        .button {
          width: 100%;
        }
      }
    }

    &-nav {
      margin: 0;

      .container {
        padding: 0;
      }

      &__inner {
        flex-wrap: wrap;
      }

      &__left {
        display: none;
      }

      &__text {
        padding: 30px 15px 45px;
        width: 100%;
        order: 2;

        &-min {
          margin-bottom: 5px;

          font-size: 8px;
          line-height: 11px;
        }

        p {
          margin-bottom: 30px;

          font-size: 14px;
          line-height: 160%;
        }

        .button {
          width: 100%;
        }
      }

      &__right {
        height: 285px;
        min-height: inherit;
        width: 100%;
        order: 1;
      }
    }

    &-ct {
      margin: 0;

      &__inner {
        flex-wrap: wrap;
      }

      &__text {
        padding: 30px 0 45px;
        width: 100%;
        order: 2;

        &-min {
          margin-bottom: 5px;

          font-size: 8px;
          line-height: 11px;
        }

        p {
          margin-bottom: 30px;

          font-size: 14px;
          line-height: 160%;
        }

        .button {
          width: 100%;
        }
      }
    }

    &-ab {
      margin: 0;

      .container {
        padding: 0;
      }

      &__inner {
        display: flex;
        align-items: flex-end;
        height: 520px;
        padding: 0 15px;

        position: relative;

        background-position: center right;
      }

      &__img {
        width: 100%;
        height: 100%;

        position: absolute;
        top: 0;
        right: 0;
      }

      &__text {
        padding: 45px 0;
        width: 100%;

        position: relative;
        z-index: 4;

        img {
          display: none;
        }

        &-min {
          margin-bottom: 5px;

          font-size: 8px;
          line-height: 11px;
          color: $white;
        }

        &-in {
          padding: 0;
        }

        h2 {
          color: $white;
        }

        p {
          margin-bottom: 30px;

          font-size: 14px;
          line-height: 160%;
          color: $white;
        }

        .button {
          width: 100%;
        }
      }
    }

    &-form {
      margin: 0;

      .container {
        padding: 0;
      }

      &__inner {
        padding-bottom: 200px;
        padding-right: 0;
        flex-wrap: wrap;
        min-height: inherit;

        background-image: url("../images/banner-form-bg-mob.jpg")!important;
      }

      &__img {
        min-height: inherit;
        width: 100%;
        order: 2;

        text-align: center;

        img {
          max-width: 500px;
          margin: auto;
          position: relative;
          left: 0;
        }
      }

      &__text {
        width: 100%;
        order: 1;
        padding: 30px 15px;


        p {
          font-size: 14px;
          line-height: 160%;
        }

        &-in {
          margin-top: 20px;

          input {
            width: 100%;
            max-width: 100%;
            margin-right: 0;
          }
        }
      }

      &__holder {
        padding: 15px;

        &-bottom {
          display: block;

          .form__tx {
            width: 100%!important;
          }
        }

        &:before {
          display: none;
        }

        p {
          font-size: 14px!important;
        }
      }

      &.tw {
        margin-top: 20px;

        .banner-form__text {
          width: 100%;
        }
      }
    }

    &-dop {
      &__text {
        width: 100%;
        margin-left: 0;
        margin-bottom: 13px;

        h2 {
          margin-bottom: 10px;
        }

        p {
          font-size: 14px;
          line-height: 160%;
        }
      }

      &__inner {
        height: 385px;
        align-items: flex-end;
      }
    }
  }

  .catalog {
    &-min {
      margin: 20px 0;

      &__inner {
        padding-bottom: 65px;

        position: relative;
      }

      .slick {
        &-list,
        &-track {
          width: 100%!important;
          transform: none!important;
        }

        &-track {
          display: flex;
          flex-wrap: wrap;
        }

        &-arrow {
          display: none!important;
        }

        &-slide {
          max-width: 50%;
          flex: 0 0 50%;
          width: 50%!important;
        }

        &-slide {
          display: none;

          &:nth-of-type(1),
          &:nth-of-type(2),
          &:nth-of-type(3),
          &:nth-of-type(4) {
            display: block;
          }
        }
      }
    }

    &__item {
      margin-bottom: 10px;

      &-img {
        height: 165px;

        img {
          max-height: 160px;
          width: auto;
          margin: auto;
        }
      }

      &-labels {
        &-item {
          padding: 1px 4px;
          margin-right: 1px;
          font-size: 8px;
          line-height: 11px;
        }
      }

      &-title {
        font-size: 14px;
        line-height: 140%;
      }

      &-price {
        &-now {
          font-size: 16px;
          line-height: 140%;
        }

        &-old {
          font-size: 12px;
          line-height: 150%;
        }
      }

      &-fav {
        svg {
          width: 18px;
          height: 18px;
        }
      }
    }

    &-page {
      &__mob {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-sort {
          width: 200px;
        }
      }

      &__inner {
        display: block;
        padding-top: 22px;
      }

      &__left {
        width: 100%;
      }

      &__content {
        width: 100%;
        padding-left: 0;

        h1 {
          margin-top: 0;
        }

        &-tx {
          &-inner {
            p {
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
      }

      &__tags {
        display: none;
      }

      &__sorting {
        display: none;
      }

      &__list {
        .col-sm-6 {
          width: 50%;
        }
      }
    }
  }

  .footer {

  }

  .brands {
    margin: 0;
    padding: 30px 0;

    .container {
      position: relative;
    }

    .button-link {
      width: 97%;
    }

    &__inner {
      padding-bottom: 25px;

      position: relative;
    }

    &__item {
      margin-bottom: 10px;
      height: 102px;
      flex: 0 0 50%;
      max-width: 50%;
      padding: 0 20px;
      display: none;

      background: transparent!important;
      box-shadow: none!important;

      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3),
      &:nth-of-type(4),
      &:nth-of-type(5),
      &:nth-of-type(6) {
        display: flex;
      }

      &:after {
        display: none;
      }

      &-img {
        opacity: 1!important;

        &-hov {
          display: none;
        }

        img {
          max-height: 100px;
        }
      }
    }

    &-page {
      padding-bottom: 30px;

      .brands__item {
        display: flex!important;
      }
    }
  }

  .services {
    margin: 30px 0;

    .container {
      padding-bottom: 80px;
      position: relative;
    }

    &__item {
      margin-bottom: 10px;

      &-title {
        margin-top: 10px;

        font-size: 12px;
        line-height: 130%;
      }

      &-img {
        height: 165px;
      }
    }

    &__text {
      h2 {
        margin-bottom: 10px;
      }

      &-min {
        font-size: 8px;
        line-height: 11px;
      }

      p {
        margin-bottom: 20px;

        font-size: 14px;
        line-height: 160%;
      }

      .button {
        width: 98%;
        margin: auto;

        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
      }
    }

  }

  .blog {
    margin-bottom: 40px;

    .row {
      margin-bottom: 40px;

      &-right {
        .col-md-12 {
          &:first-of-type {
            order: 2;
          }

          &:last-of-type {
            order: 1;
          }
        }
      }
    }

    &__text {
      margin-bottom: 30px;

      p {
        margin-bottom: 20px;

        font-size: 14px;
        line-height: 20px;
      }
    }

    &__img {
      height: 250px;
    }

    &-bottom {
      &-title {
        font-size: 24px;
        line-height: 30px;
      }

      p {
        font-size: 14px;
        line-height: 20px;
      }
    }

    &-slider {
      margin: 40px 0 0;
      padding: 30px 0 50px;

      .container {
        position: relative;
        padding-bottom: 56px;

        .button-link {
          width: 97%;
          color: $black2;

          svg {
            use {
              color: $black2;
            }
          }
        }
      }
    }

    &__item {
      margin-bottom: 10px;
      height: 150px;
      padding: 20px 25px;

      &-data {
        padding: 5px 8px;
        margin-bottom: 10px;

        font-size: 8px;
        line-height: 11px;
      }

      &-title {
        height: 61px;
        font-size: 16px;
        line-height: 130%;
      }

      &-link {
        font-size: 12px;
        line-height: 140%;

        left: 25px;
        bottom: 15px;

        svg {
          width: 12px;
          height: 12px;
        }
      }
    }

    &-item {
      margin-bottom: 40px;

      &__form {
        padding: 20px 15px;
        margin-top: 40px;
        width: 100%;
      }

      &__inner {
        margin-top: 0;
        display: block;
      }

      &-banner {
        height: 300px;
      }

      &__text {
        width: 100%;
        padding-right: 0;
      }
    }

    &-page {
      padding-bottom: 30px;
    }
  }

  .footer {
    margin-top: 0;

    &__main {
      padding: 25px 0 15px;

      &-inner {
        display: block;
      }
    }

    &__text {
      width: 100%;
      margin-bottom: 25px;

      p {
        font-size: 14px;
        line-height: 160%
      }
    }

    &__logo {
      margin-bottom: 15px;
      width: 250px;
    }

    &__menu {
      width: 100%;
      padding-top: 0;
    }

    &__call {
      position: absolute;
      top: 38px;
      right: 0;
    }

    &__time {
      width: 100%;
      margin-top: 20px;
    }

    &__inst {
      padding-bottom: 15px;

      border-bottom: 1px solid $gray7;
    }

    &__bottom {
      padding-bottom: 30px;

      border: none;

      &-inner {
        display: block;
        padding-top: 55px;

        position: relative;
      }

      &-tx {
        margin-right: 10px;
        display: inline-block;
      }

      &-links {
        position: absolute;
        top: 0;
        left: 0;

        a {
          display: block;
          margin: 0;
        }
      }
    }

    &__dev {
      display: inline-block;
    }

    &__item {
      width: 100%!important;
      padding: 0;

      position: relative;

      border-bottom: 1px solid $gray7;

      &:last-of-type {
        border: none;
      }

      &.open {
        ul {
          display: block;
          margin-bottom: 20px;
        }
      }

      &-title {
        position: relative;

        svg {
          display: block;
          width: 20px;
          height: 20px;
          transform: rotate(90deg);

          position: absolute;
          top: 0;
          right: 0;

          transition: 0.3s;

          use {
            color: $gray6;
          }
        }

        .open & {
          svg {
            transform: rotate(270deg);
          }
        }
      }

      ul {
        display: none;
      }
    }
  }

  .filter {
    padding: 66px 20px;

    display: none;
    width: 100%;
    height: 100vh;
    min-height: 100%;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 98;

    background: $white;
    overflow: auto;

    &.op {
      display: block;
    }

    &__total {
      display: none;
    }

    &__top {
      &-tt {
        padding-bottom: 0;
        display: block;

        font-size: 28px;
        line-height: 130%;
        color: $black2;
        font-family: $font-family2;
        font-weight: 400;
        text-transform: inherit;
      }


      &-close {
        display: block;

        position: absolute;
        right: 20px;
        top: 86px;
      }
    }
  }

  .pagination  {
    .page {
      &-item  {
        margin: 0;

        &.prev,
        &.next {
          display: none!important;
        }
      }

      &-link {
        width: 40px!important;
        height: 40px!important;
        font-size: 16px!important;
      }
    }
  }

  .text {
    blockquote {
      font-size: 14px;
      line-height: 20px;
    }

    p {
      font-size: 14px;
      line-height: 20px;
    }

    h2 {
      margin-top: 25px;
    }

    h3 {
      font-size: 20px;
    }

    ol {
      li {
        padding-left: 20px;

        font-size: 14px;
        line-height: 20px;

        &:before {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    &__slider {
      margin: 20px 0;

      &-inner {
        height: 350px;
      }

      .pagingInfo {
        width: 40px;

        bottom: 25px;

        .numb {
          &-prev,
          &-next {
            font-size: 15px!important;

            &:before {
              font-size: 15px!important;
            }
          }
        }
      }
    }

    &__acc {
      margin: 15px 0;

      .accordion {
        &-button {
          font-size: 18px;
          line-height: 20px;
        }

        &-body {
          p {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }

    &__table {
      table {
        tr {
          th, td {
            padding: 10px;

            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
  }

  .actions-item__pages {
    margin-top: 30px;
  }

  .delivery {
    margin-top: 20px;

    &-title {
      font-size: 20px;
    }

    p {
      font-size: 14px;
      list-style: 20px;

      & + .form__title {
        margin-top: 10px;
      }
    }
  }

  .cart-product {
    &__inner {
      display: block;
    }

    &__info {
      width: 100%;

      &-price {
        display: none;
        margin-top: 10px;

        &.mob {
          display: block;
        }

        &-now {
          margin-right: 10px;

          font-size: 26px;
          line-height: 140%;
        }

        &-old {
          font-size: 16px;
          line-height: 140%;
        }
      }

      &-fav {
        display: none;
      }

      &-all {
        margin-top: 20px;
      }

      &-form {
        margin-top: 37px;

        .button {
          width: 100%;

          text-align: center;
        }
      }

      &-descr {
        margin-top: 20px;
        padding: 13px 18px;

        p {
          font-size: 12px;
          line-height: 140%;
        }

        &-list {
          &-item {
            font-size: 10px;
            line-height: 160%;
          }
        }

        &-link {
          font-size: 12px;
          line-height: 16px;
        }
      }

      &-acc {
        .accordion-body p {
          font-size: 14px;
          line-height: 20px;
        }

        &-list {
          &-row {
            &:before {
              bottom: 4px;
            }
          }

          &-item {
            font-size: 12px;
            line-height: 150%;

            &:last-of-type {
              font-size: 16px;
              line-height: 140%;
            }
          }
        }
      }

      &-btns {
        .button {
          width: 100%;
        }

        &-link {
          margin-top: 15px;
          width: 100%;
          display: block;
          text-align: center;
        }
      }

      &-size {
        position: relative;

        &-tx {
          font-size: 16px;
          line-height: 140%;
        }

        &-title {
          margin-bottom: 10px;

          font-size: 14px;
          line-height: 160%;

          &-dow {
            width: 220px;

            bottom: 43px;
            left: 61px;
          }
        }

        &-link {
          position: absolute;
          top: 0;
          right: 0;
          z-index: 1;

          font-size: 14px;
          line-height: 160%;
        }

        &-list {
          margin-right: 0;

          &-item {
            input[type="radio"] {
              & + label {
                font-size: 14px;
                line-height: 160%;
              }
            }
          }
        }
      }
    }

    &__slider {
      width: 100%;
      height: 370px;

      position: relative;

      .catalog__item {
        &-labels {
          display: block;

          left: 0;
          top: 20px;
        }

        &-fav {
          height: 18px;
          display: block;

          bottom: 0;
          top: auto;

          svg {
            width: 24px;
            height: 24px;
          }
        }
      }

      &-nav {
        display: none!important;
      }

      &-for {
        height: 370px;
        width: 100%;
        margin-left: 0;

        &-item {
          padding: 10px;
          height: 370px!important;
        }
      }
    }

    &__top {
      display: none;
      margin-top: 30px;

      &.mob {
        display: block;
      }

      &-inf {
        margin-top: 10px;

        &-art,
        &-nl {
          font-size: 14px;
          line-height: 160%;
        }
      }
    }
  }

  .address {
    margin-bottom: 0;

    .container {
      position: relative;
    }

    .tab-content>.active {
      opacity: 1;
    }

    .tab-pane {
      opacity: 0;
    }

    &__info {
      display: block;

      &-img {
        width: 100%;
        height: 225px;
        min-height: inherit;
      }

      &-text {
        width: 100%;
        padding: 30px 20px;

        &-list {
          display: block;
          margin-top: 30px;

          &-item {
            display: flex;
            align-items: center;
          }

          &-col {
            width: 100%;
          }

          &-title {
            width: 140px;
          }

          &-tx{
            width: calc(100% - 140px);
          }

          &-title,
          &-tx ,
          &-link{
            font-weight: 600;
            font-size: 14px;
            line-height: 160%;
          }

        }
      }
    }

    &__map {
      display: block;

      &-mp {
        width: 100%;
        height: 510px;
      }

      &-address {
        width: 100%;
        padding-top: 63px;

        &-inner {
          padding-bottom: 80px;
          height: 320px;
        }

        &-item {
          border-top: none;
          border-bottom: 1px solid #D2D2D2;

          padding-bottom: 10px;

          &-title {
            font-size: 12px;
            line-height: 16px;
          }

          &-ad {
            margin-bottom: 5px;

            &-title {
              margin-bottom: 5px;

              font-size: 16px;
              line-height: 140%;
            }
          }
        }
      }
    }

    &__tb {
      margin-top: 0;
      position: relative;
    }

    .nav {
      &-tabs{
        border: none;

        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 10;
      }

      &-link {
        font-weight: 600;
        font-size: 14px;
        line-height: 160%;
        color: $black2;
        border: 1px solid $gray2!important;
        margin-right: 10px;
        border-radius: 3px;
        background: $white;
        overflow: hidden;

        &.active {
          background: $black2;
          border-color: $black2;
          color: $white;
        }
      }
    }
  }

  .enter {
    background: linear-gradient(180deg, #F5F6F8 9.77%, #F3F4F7 27.08%, #EFF0F4 49.23%, #EDEDEE 65.05%)!important;

    &__inner {
      height: auto;
      padding: 30px 0;

      background-image: none!important;
    }

    &__top {
      margin-bottom: 30px;

      &-tx {
        font-size: 14px;
      }
    }

    &__form {
      display: block;
      width: 100%;
      height: auto;

      &-tx {
        font-size: 14px;
      }
    }
  }

  .cart {
    &-in {
      &__all {
        margin-top: 20px;
        width: 100%;

        &-inner {
          padding: 15px;
          margin: 0 -12px;

          border-radius: 0;
        }

        &-promo {
          .button {
            height: 55px;

            border-radius: 0 4px 4px 0;
          }
        }

        &-price {
          &-now {
            margin-left: 10px;
            font-weight: 600;
            font-size: 22px;
            line-height: 140%;
          }

          &-old {
            font-size: 12px;
            line-height: 150%;
          }
        }
      }

      &__item {
        display: block;
        padding: 12px 15px 12px 140px;

        position: relative;

        &-title {
          margin-bottom: 5px;

          width: 100%;
          font-size: 12px;
          line-height: 130%;
        }

        &-img {
          width: 80px;
          height: 80px;

          position: absolute;
          top: 10px;
          left: 46px;
        }

        &-close {
          position: absolute;
          top: 37px;
          left: 22px;
        }

        &-info {
          width: 100%;
          padding: 0;

          &-tx {
            margin-bottom: 2px;
            font-size: 12px;
            line-height: 150%;
          }
        }

        &-amount {
          width: 114px;
          margin: 10px 0;

          .amount {
            width: 114px;

            input {
              height: 46px;
            }
          }
        }

        &-price {
          width: 100%;
          text-align: left;

          &.mob {
            display: none;
          }

          &-now {
            font-size: 16px;
            line-height: 140%;
          }

          &-old {
            font-size: 12px;
            line-height: 150%;
          }
        }
      }
    }

    &-order {
      &__total {
        &-inner {
          padding: 20px 20px 0;
        }

        &-text {
          font-size: 14px;
          line-height: 160%;

          .all & {
            font-size: 8px;
            line-height: 11px;
          }
        }

        &-price {
          &-pr {
            font-size: 14px;
            line-height: 160%;

            .all & {
              font-size: 16px;
              line-height: 140%;
            }
          }

          &-tx {
            font-size: 14px;
            line-height: 160%;
          }
        }

        &-item {
          margin-bottom: 10px;

          &.all {
            padding: 20px 0;
          }
        }
      }
    }
  }

  .ord {
    margin-bottom: 50px;

    &__inner {
      flex-wrap: wrap;
    }

    &__left {
      width: 100%;
      max-width: 100%;
      padding-left: 0;
      order: 2;
    }

    &__all {
      margin-bottom: 20px;

      width: 100%;

      &-prod {
        &-promo {
          padding: 15px 0;

          .button {
            height: 55px;
          }
        }

        &-list {
          padding: 5px 18px;
        }

        &-item {
          display: block;
          padding: 15px 0 15px 90px;

          position: relative;

          &-img {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
          }

          &-info {
            width: 100%;
            padding: 0;
          }

          &-price {
            margin-top: 15px;
            text-align: left;
          }
        }
      }
    }

    &__bottom {
      &-check {
        label {
          font-size: 12px!important;
          line-height: 150%;
        }
      }

      .button {
        width: 100%;
        text-align: center;
      }
    }

    &__list {
      max-width: 100%;
      padding-left: 0;

      &-item {
        &-title {
          margin-bottom: 12px;

          font-size: 16px;
          line-height: 140%;

          &-main {
            display: block;
            margin-right: 0;
            margin-bottom: 10px;

            font-size: 16px;
            line-height: 140%;
          }
        }

        &-group {
          &-item {
            margin-bottom: 5px;

            input[type="radio"] {
              & + label {
                padding: 16px 15px 15px 51px;

                .title {
                  font-size: 14px;
                  line-height: 160%;
                }

                .tx {
                  font-size: 12px;
                  line-height: 150%;
                }

                &:before {
                  left: 20px;
                }

                &:after {
                  left: 25px;
                }
              }
            }
          }
        }
      }

      .nav {
        &-item {

        }

        &-link {
          font-weight: 600;
          font-size: 14px;
          line-height: 160%;
        }
      }
      .tab {
        &-content {
          margin-top: 30px;
        }
      }
    }

    &-done {
      &__inner {
        height: auto;
        padding: 40px 0 150px;
        display: block;

        h2 {
          margin-bottom: 30px;
        }
      }
    }
  }

  .orders {
    &__inner {
      flex-wrap: wrap;

      position: relative;
    }

    &__left {
      width: 100%;
      margin-bottom: 40px;
      padding-right: 0;
      order: 2;

      .breadcrumb-block {
        display: none;
      }

      .page-top {
        padding-bottom: 100px;
      }
    }

    &__right {
      width: 100%;
      margin: 0;
      order: 1;

      position: absolute;
      top: 87px;

      background: transparent;
    }

    &__pages {
      padding: 10px 0;
      display: flex;

      background: transparent;
      overflow: auto;

      &-item {
        padding: 0 30px 0 25px;
        display: flex;
        align-items: center;
        white-space: nowrap;
        border: none!important;

        font-size: 16px;
        line-height: 140%;

        svg {
          left: 0;
        }
      }
    }

    &__form {
      &-item {
        display: block;
      }

      &-btns {
        .button-bord {
          padding: 17px 27px;
        }
      }

      &-inp {
        width: 100%;

        &-gr {
          width: 100%;
        }
      }

      &-title {
        width: 100%;
        padding: 0;
        font-size: 16px;
        margin-bottom: 10px;
      }
    }

    &__top {
      .orders__item & {
        display: block;
      }
    }

    &__item {
      padding: 25px 15px;
    }

    &__itt {
      display: block;

      .orders__status {
        margin-top: 15px;
      }
    }

    &__bonus {
      &-tx {
        display: none;
      }

      &-item {
        padding: 25px 30px 80px;


        position: relative;

        &-inf {
          margin-bottom: 5px;

          font-size: 16px;
          line-height: 140%;
        }

        &-cell {
          width: 100%;
          flex: 0 0 100%;
          max-width: 100%;
          padding-right: 0!important;

          &:first-of-type {
            margin-bottom: 13px;
          }
        }

        &-tx {
          &-title {
            font-size: 20px;
            line-height: 130%;
          }

          &-tt {
            position: absolute;
            bottom: 35px;
            padding-right: 20px;

            font-weight: 600;
            font-size: 14px;
            line-height: 160%;
          }
        }
      }
    }

    &__ist {
      .cart-in {
        &__item {
          padding: 12px 15px 12px 102px;

          &-info {
            width: 100%;
          }

          &-amount {
            width: 100%;

            text-align: left;

            &-nb {
              font-size: 12px;
            }
          }

          &-price {
            width: 100%;

            text-align: left;
          }

          &-img {
            left: 5px;
          }
        }
      }

      &-bottom {
        margin-top: 20px;
        flex-wrap: wrap;

        .button {
          width: 100%;
          order: 2;
        }

        &-right {
          width: 100%;
          margin-bottom: 20px;

          order: 1;

          text-align: left;
        }

        &-price {
          &-old {
            font-size: 16px;
          }

          &-now {
            font-size: 24px;
          }
        }
      }
    }
  }

  .https404  {
    .enter__form-in {
      width: 100%;
    }
  }

  .policy {
    &__text {
      p {
        font-size: 14px;
      }

      ol {
        li {
          font-size: 20px;

          &:before {
            font-size: 20px;
          }

          ol {
            li {
              font-size: 14px;

              &:before {
                font-size: 14px;
              }

              ol {
                li {
                  font-size: 14px;

                  &:before {
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .modal {
    &-body {
      padding: 30px 25px 40px;

      .w3 & {
        padding: 20px;
      }

      .btn-close {
        top: 20px;
      }
    }

    .address {
      &__tp {
        display: block;

        &-title {
          width: 100%;
        }

        &-form {
          margin: 20px 0;
          width: 100%;

          input {
            width: 100%;
          }
        }
      }

      &__map-address {
        &-inner {
          padding-bottom: 0;
        }

        &-item {
          &-ad {
            &-nl {
              width: 100%;

              text-align: left;
              margin-top: 20px;
              position: relative;
              bottom: 0;
            }
          }
        }
      }
    }
  }

}

@media screen and(max-width: 768px) {
  .modal-body {
    &-product {
      &-btns {
        display: block;

        .button {
          width: 100%;

        }
      }

      &-item {
        display: block;
        padding: 13px 13px 13px 80px;

        position: relative;

        &-img {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
        }

        &-info {
          margin-bottom: 15px;
          width: 100%;
          margin-left: 0;
          padding-left: 0;
        }

        &-title {
          width: 100%;
        }

        &-price {
          width: 100%;

          text-align: left;
        }
      }
    }
  }

  .banner__slider-img {
    right: 20%;
  }

  .banner-rek__inner {
    background-position: 63% center;
  }

  .banner-ab__inner {
    background-position: 70% center;
  }

  .services {
    .col-sm-6 {
      width: 50%!important;
    }
  }

  .text__table {
    overflow: auto;
    padding-bottom: 10px;

    table {
      width: 768px;
    }
  }

  .actions-item__pages {
    display: block;

    text-align: center;

    &-prev,
    &-next  {
      display: block;
      margin: 10px 0;
    }
  }
}

@media screen and(max-width: 530px) {
  .banner__slider-img {
    right: 70%;
  }

  .catalog__item-labels-item {
    display: table;
    margin-bottom: 5px;
  }
}


