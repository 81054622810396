$white: #ffffff;
$black: #000000;
$black2: #191919;
$black3:  #04052D;
$black4: #333333;

$gray: #A2A2A2;
$gray2: #D2D2D2;
$gray3: #A9AEB2;
$gray4: #376565;
$gray5: #E5E6E9;
$gray6: #797979;
$gray7: #5E5E5E;
$gray8: #EDEDED;
$gray9: #E0E0E0;
$gray10: #828282;
$gray11: #F6F6F6;
$gray12: #E5E5E5;

$green: #06473C;

$ye: #C19935;
$ye2: #EFE5D9;
$hover: #D7BB76;

$bg: #F7F7F7;
$bg2: #FAF9FA;

$gr: #27AE60;
$gr2: #219653;
$red: #EB5757;


$font-family: 'Nunito', sans-serif;
$font-family2: 'Lora', sans-serif;
$font-family3: 'PT Sans', sans-serif;
