@charset "UTF-8";
@font-face {
  font-family: 'rawline';
  src: url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-100.eot");
  src: url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-100.eot?#iefix") format("embedded-opentype"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-100.woff2") format("woff2"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-100.woff") format("woff"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-100.ttf") format("truetype"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-100.svg") format("svg");
  font-weight: 100;
  font-style: normal; }

* {
  margin: 0;
  padding: 0; }

body {
  min-width: 320px;
  font-family: "Nunito", sans-serif; }
  body.overfl {
    overflow: hidden;
    height: 100vh; }
  body.op:after {
    content: '';
    width: 100%;
    height: 100vh;
    min-height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 70;
    background: #191919;
    opacity: 0.5; }

.start-page {
  padding: 30px; }
  .start-page-logo {
    display: block;
    margin-bottom: 30px; }
  .start-page ul {
    display: inline-block;
    vertical-align: top;
    padding-right: 60px; }
    .start-page ul li {
      padding: 5px 0; }
      .start-page ul li b {
        font-size: 20px; }
      .start-page ul li a {
        font-size: 16px;
        line-height: 140%;
        color: #000000; }

div:focus,
span:focus,
a:focus,
input:focus,
textarea:focus,
select:focus,
button:focus,
.button:focus {
  outline: none; }

input,
textarea {
  box-shadow: none !important; }

ul {
  margin: 0;
  padding: 0; }
  ul li {
    list-style: none; }

a {
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none; }
  a:hover {
    text-decoration: none; }

svg use {
  transition: 0.3s; }

.container {
  max-width: 100%; }
  @media screen and (min-width: 1670px) {
    .container {
      max-width: 1640px; } }

.button {
  background: transparent;
  border: none;
  box-shadow: none;
  outline: none;
  transition: 0.3s; }
  .button-primary {
    padding: 17px 45px;
    display: inline-block;
    background: #191919;
    border-radius: 4px;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #ffffff !important;
    letter-spacing: 0.06em;
    text-transform: uppercase; }
    .button-primary.disabled {
      background: #D2D2D2 !important; }
    .button-primary:hover {
      background: #D7BB76; }
  .button-link {
    display: inline-flex;
    align-items: center;
    color: #191919;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.1em;
    text-transform: uppercase; }
    .button-link svg {
      width: 41px;
      height: 6px;
      margin-left: 7px; }
      .button-link svg use {
        color: #191919; }
    .button-link.disabled {
      color: #D2D2D2 !important; }
      .button-link.disabled svg use {
        color: #D2D2D2 !important; }
    .button-link:hover {
      color: #D7BB76; }
      .button-link:hover svg use {
        color: #D7BB76; }
  .button-gray {
    padding: 17px 45px;
    display: inline-block;
    background: #EDEDED;
    border-radius: 4px;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #191919 !important;
    letter-spacing: 0.06em;
    text-transform: uppercase; }
    .button-gray.disabled {
      background: #D2D2D2 !important; }
    .button-gray:hover {
      background: #191919;
      color: #ffffff !important; }
  .button-center {
    text-align: center; }

h1 {
  margin-bottom: 15px;
  font-family: "Lora", sans-serif;
  font-size: 52px;
  line-height: 120%;
  font-weight: 400;
  color: #191919; }
  h1 span {
    position: relative;
    top: -20px;
    font-size: 26px;
    line-height: 120%;
    color: #797979; }
    h1 span.or {
      top: -8px;
      padding: 1px 5px;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: #ffffff;
      background: #C19935;
      border-radius: 4px;
      font-family: "Nunito", sans-serif; }

h2 {
  margin-bottom: 15px;
  font-family: "Lora", sans-serif;
  font-size: 34px;
  line-height: 135%;
  color: #191919; }

h3 {
  margin-bottom: 15px;
  font-family: "Lora", sans-serif;
  font-size: 24px;
  line-height: 130%;
  color: #191919; }

.title-main {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 40px; }
  .title-main h2 {
    margin-bottom: 0; }
    .blog-slider .title-main h2 {
      color: #ffffff; }

.header {
  position: relative;
  z-index: 71; }
  .header-mob {
    display: none; }
  .header-top {
    background: #ffffff;
    border-bottom: 1px solid rgba(6, 71, 60, 0.05); }
    .header-top__inner {
      padding: 10px 0;
      display: flex;
      align-items: center;
      justify-content: space-between; }
    .header-top__menu ul li {
      display: inline-block;
      vertical-align: middle;
      margin-left: 31px; }
      .header-top__menu ul li a {
        letter-spacing: 0.06em;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #A2A2A2; }
        .header-top__menu ul li a:hover {
          color: #C19935; }
  .header-mid {
    background: #ffffff; }
    .header-mid__inner {
      display: flex;
      align-items: center;
      justify-content: space-between; }
    .header-mid__group {
      width: 35%; }
      .header-mid__group-search-title {
        cursor: pointer; }
      .header-mid__group-search-block {
        width: 100%;
        display: none;
        position: absolute;
        left: 0;
        top: 116px;
        z-index: 9; }
        .header-mid__group-search-block.open {
          display: block; }
        .header-mid__group-search-block-inp {
          height: 74px;
          background: linear-gradient(0deg, #FAF9FA, #FAF9FA), #191919; }
          .header-mid__group-search-block-inp-inner {
            padding: 10px 0;
            position: relative; }
            .header-mid__group-search-block-inp-inner input {
              padding-left: 30px;
              background: transparent;
              font-weight: 600;
              font-size: 18px;
              line-height: 140%; }
            .header-mid__group-search-block-inp-inner .button-search {
              position: absolute;
              top: 24px;
              left: 0;
              z-index: 2; }
              .header-mid__group-search-block-inp-inner .button-search svg {
                width: 22px;
                height: 22px; }
                .header-mid__group-search-block-inp-inner .button-search svg use {
                  color: #191919 !important; }
              .header-mid__group-search-block-inp-inner .button-search:hover {
                color: #D7BB76 !important; }
        .header-mid__group-search-block-list {
          padding: 41px 0;
          background: #ffffff; }
          .header-mid__group-search-block-list-inner {
            display: flex;
            align-items: flex-start; }
          .header-mid__group-search-block-list-tt {
            margin-bottom: 20px;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 0.06em;
            text-transform: uppercase;
            color: #A2A2A2; }
          .header-mid__group-search-block-list-left {
            width: 300px; }
            .header-mid__group-search-block-list-left ul li {
              margin-bottom: 18px; }
              .header-mid__group-search-block-list-left ul li a {
                font-weight: 600;
                font-size: 16px;
                line-height: 140%;
                color: #191919; }
                .header-mid__group-search-block-list-left ul li a:hover {
                  color: #D7BB76; }
          .header-mid__group-search-block-list-prod {
            padding-left: 30px;
            width: calc(100% - 300px); }
          .header-mid__group-search-block-list-in {
            display: flex;
            align-items: center;
            flex-wrap: wrap; }
          .header-mid__group-search-block-list-item {
            margin-bottom: 20px;
            max-width: 33.333%;
            flex: 0 0 33.333%;
            padding: 20px 10px 10px 120px;
            position: relative; }
          .header-mid__group-search-block-list-img {
            display: block;
            width: 100px;
            height: 100px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2; }
            .header-mid__group-search-block-list-img img {
              max-width: 100%;
              max-height: 100px; }
          .header-mid__group-search-block-list-title {
            display: block;
            font-weight: 600;
            font-size: 16px;
            line-height: 140%;
            color: #191919; }
            .header-mid__group-search-block-list-title:hover {
              color: #D7BB76; }
          .header-mid__group-search-block-list-price {
            margin-top: 5px; }
            .header-mid__group-search-block-list-price.mob {
              display: none; }
            .header-mid__group-search-block-list-price-now {
              margin-right: 13px;
              display: inline-block;
              vertical-align: middle;
              font-weight: 600;
              font-size: 20px;
              line-height: 140%;
              color: #191919; }
              .header-mid__group-search-block-list-price-now:after {
                display: inline-block;
                margin-left: 7px;
                font-family: "PT Sans", sans-serif;
                content: "\20cf";
                font-weight: 400; }
            .header-mid__group-search-block-list-price-old {
              display: inline-block;
              vertical-align: middle;
              font-weight: 600;
              font-size: 14px;
              line-height: 140%;
              color: #828282;
              text-decoration: line-through; }
              .header-mid__group-search-block-list-price-old:after {
                display: inline-block;
                margin-left: 7px;
                font-family: "PT Sans", sans-serif;
                content: "\20cf";
                font-weight: 400; }
      .header-mid__group-right {
        text-align: right; }
      .header-mid__group-item {
        margin-right: 40px;
        display: inline-block;
        vertical-align: middle; }
        .header-mid__group-item.sr {
          margin-left: 40px; }
        .header-mid__group-item:last-of-type {
          margin-right: 0; }
        .header-mid__group-item svg {
          width: 20px;
          height: 20px;
          margin-right: 10px; }
          .header-mid__group-item svg use {
            color: #191919; }
        .header-mid__group-item span {
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          color: #191919;
          letter-spacing: 0.06em;
          text-transform: uppercase;
          transition: 0.3s; }
        .header-mid__group-item:hover svg use, .header-mid__group-item:hover span {
          color: #C19935; }
  .header-menu {
    padding: 16px 0;
    background: linear-gradient(86.68deg, #00352C -15.56%, #0F6254 39.54%, #2A7E70 60.18%, #0F6254 79.56%, #00352C 105.88%), #F2F1F2; }
    .header-menu__inner ul {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .header-menu__inner ul li {
        display: inline-block;
        vertical-align: middle; }
        .header-menu__inner ul li a {
          font-weight: 700;
          font-size: 14px;
          line-height: 19px;
          color: #ffffff;
          letter-spacing: 0.06em;
          text-transform: uppercase; }
        .header-menu__inner ul li:hover .dropdn {
          visibility: visible;
          opacity: 1; }
  .header__tel {
    margin-right: 15px;
    display: inline-block;
    vertical-align: middle;
    color: #191919;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.06em;
    text-transform: uppercase; }
    .header__tel:hover {
      color: #C19935; }
  .header__time {
    display: inline-block;
    vertical-align: middle;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #A2A2A2;
    letter-spacing: 0.06em;
    text-transform: uppercase; }
  .header__logo {
    width: 30%;
    position: relative;
    top: -11px;
    text-align: center; }

.dropdn {
  width: 100%;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
  position: absolute;
  top: 173px;
  left: 0;
  z-index: 8; }
  .dropdn-in {
    background: #ffffff;
    position: relative;
    z-index: 71; }
  .dropdn:before {
    content: '';
    width: 15%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background: #F7F7F7; }
  .dropdn:after {
    content: '';
    width: 100%;
    height: 18px;
    position: absolute;
    top: -17px;
    left: 0;
    z-index: 2;
    background: transparent; }
  .dropdn__inner {
    display: flex;
    position: relative;
    z-index: 71; }
    .dropdn__inner ul {
      display: block;
      margin-bottom: 40px; }
      .dropdn__inner ul li {
        margin-bottom: 13px;
        display: block; }
        .dropdn__inner ul li:last-of-type {
          margin-bottom: 0; }
        .dropdn__inner ul li a {
          font-weight: 600;
          font-size: 16px !important;
          line-height: 140%;
          color: #191919 !important;
          text-transform: inherit;
          letter-spacing: 0.001em !important; }
          .dropdn__inner ul li a:hover {
            color: #A2A2A2 !important; }
  .dropdn__left {
    padding: 40px 20px;
    background: #F7F7F7; }
  .dropdn__right {
    width: calc(100% - 230px);
    padding: 40px 20px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between; }
    .dropdn__right-inner {
      -webkit-column-count: 3;
      column-count: 3; }
    .dropdn__right-img {
      padding-left: 40px; }
      .dropdn__right-img img {
        max-width: 100%; }
  .dropdn__title {
    margin-bottom: 11px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #A2A2A2;
    letter-spacing: 0.06em;
    text-transform: uppercase; }

.banner__inner {
  position: relative; }
  .banner__inner .pagingInfo {
    width: 55px;
    display: inline-block;
    position: absolute;
    bottom: 33px;
    right: 0;
    left: 1500px;
    margin: auto;
    z-index: 3; }
    .banner__inner .pagingInfo .number-slide .numb-prev, .banner__inner .pagingInfo .number-slide .numb-next {
      padding: 0 3px;
      font-size: 20px;
      line-height: 160%;
      font-family: "Lora", sans-serif; }
    .banner__inner .pagingInfo .number-slide .numb-next:before {
      content: '/';
      display: inline-block;
      vertical-align: middle;
      position: relative;
      left: -4px;
      top: -3px;
      font-size: 18px;
      line-height: 160%; }

.banner__slider .slick-arrow {
  width: 40px;
  height: 40px;
  top: inherit;
  bottom: 30px;
  left: 0;
  right: 0;
  margin: auto;
  border-color: rgba(0, 0, 0, 0.2); }
  .banner__slider .slick-arrow svg {
    width: 13px;
    height: 13px; }

.banner__slider .slick-prev {
  left: 1385px; }

.banner__slider .slick-next {
  left: 1580px; }

.banner__slider-holder {
  display: flex; }

.banner__slider-inner {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }

.banner__slider-img {
  width: 62%; }

.banner__slider-tx {
  width: 38%;
  padding: 60px 0 30px; }
  .banner__slider-tx-title {
    margin-bottom: 20px;
    font-family: "Lora", sans-serif;
    font-size: 52px;
    line-height: 120%;
    color: #191919; }
  .banner__slider-tx p {
    margin-bottom: 50px;
    color: #191919;
    font-size: 18px;
    line-height: 160%; }

.banner-min {
  margin: 60px 0; }
  .banner-min__item {
    height: 250px;
    padding: 25px 37px;
    margin-bottom: 30px;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 4px; }
    .banner-min__item a {
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2; }
    .banner-min__item-title {
      font-size: 28px;
      line-height: 130%;
      color: #191919;
      font-family: "Lora", sans-serif; }
    .banner-min__item-link {
      position: absolute;
      left: 37px;
      bottom: 34px;
      z-index: 1;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: #191919;
      transition: 0.3s; }
      .banner-min__item-link svg {
        width: 16px;
        height: 16px; }
        .banner-min__item-link svg use {
          color: #191919; }
    .banner-min__item:hover .banner-min__item-link {
      color: #C19935; }
      .banner-min__item:hover .banner-min__item-link svg use {
        color: #C19935; }

.banner-info {
  margin: 80px 0; }
  .banner-info__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #F7F7F7;
    border-radius: 4px;
    overflow: hidden;
    background-image: url("../images/bg1.png");
    background-repeat: no-repeat;
    background-position: top right; }
  .banner-info__img {
    width: 33%;
    min-height: 536px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; }
    .banner-info__img-m {
      width: 22%; }
      .banner-info__img-m img {
        max-width: 100%; }
  .banner-info__text {
    width: 550px;
    margin: 0 40px; }
    .banner-info__text-min {
      margin-bottom: 10px;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #191919; }
    .banner-info__text p {
      margin-bottom: 45px;
      font-size: 16px;
      line-height: 190%;
      color: #191919; }

.banner-rek {
  margin: 80px 0; }
  .banner-rek__inner {
    padding: 59px 69px 73px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }
  .banner-rek__tx {
    width: 690px; }
    .banner-rek__tx-min {
      margin-bottom: 10px;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #000000; }
    .banner-rek__tx h2 {
      margin-bottom: 45px; }

.banner-nav {
  margin: 60px 0; }
  .banner-nav__inner {
    display: flex;
    justify-content: space-between;
    background-color: #EFE5D9;
    background-image: url("../images/bg5.png");
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 4px; }
  .banner-nav__left {
    width: 25%;
    display: flex;
    align-items: flex-end; }
    .banner-nav__left img {
      max-width: 100%; }
  .banner-nav__right {
    width: 41%;
    min-height: 535px; }
  .banner-nav__text {
    width: 30%;
    padding: 120px 0 0; }
    .banner-nav__text p {
      margin-bottom: 50px;
      font-size: 16px;
      line-height: 190%;
      color: #191919; }
    .banner-nav__text-min {
      margin-bottom: 10px;
      color: #191919;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.1em;
      text-transform: uppercase; }

.banner-ct {
  margin: 100px 0;
  background: #F7F7F7; }
  .banner-ct__inner {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .banner-ct__img img {
    max-width: 100%; }
  .banner-ct__text {
    width: 40%; }
    .banner-ct__text p {
      margin-bottom: 50px;
      font-size: 16px;
      line-height: 190%;
      color: #191919; }
    .banner-ct__text-min {
      margin-bottom: 10px;
      color: #191919;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.1em;
      text-transform: uppercase; }

.banner-ab {
  margin: 60px 0; }
  .banner-ab__inner {
    display: flex;
    justify-content: space-between;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 4px; }
  .banner-ab__text {
    width: 40%;
    padding-top: 215px; }
    .banner-ab__text-in {
      padding: 0 70px 50px; }
    .banner-ab__text p {
      margin-bottom: 30px;
      font-size: 16px;
      line-height: 190%;
      color: #191919; }
    .banner-ab__text-min {
      margin-bottom: 10px;
      color: #191919;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.1em;
      text-transform: uppercase; }
  .banner-ab__img {
    width: 54%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; }

.banner-form {
  margin: 80px 0; }
  .banner-form.tw {
    background: transparent; }
    .banner-form.tw textarea {
      margin-bottom: 20px;
      height: 148px; }
    .banner-form.tw input {
      margin-bottom: 20px; }
    .banner-form.tw .form__tx {
      width: 60%; }
  .banner-form__holder {
    padding: 66px 0 66px 100px;
    position: relative;
    background: linear-gradient(293.33deg, #E6E6E6 25.15%, #F7F7F7 67.89%, #E6E6E6 98.66%);
    border-radius: 4px;
    overflow: hidden; }
    .banner-form__holder:before {
      content: '';
      width: 730px;
      height: 570px;
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 1;
      background-image: url("../images/fr-img.png");
      background-repeat: no-repeat; }
    .banner-form__holder-bottom {
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between; }
    .banner-form__holder p {
      margin-bottom: 44px !important;
      font-weight: 600;
      font-size: 18px !important;
      line-height: 140%;
      color: #A2A2A2 !important; }
  .banner-form__inner {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-repeat: no-repeat;
    background-position: center left;
    background-size: cover;
    border-radius: 4px;
    min-height: 350px; }
  .banner-form__img {
    width: calc(100% - 794px); }
  .banner-form__text {
    width: 628px;
    padding: 30px 0;
    margin-right: 10%;
    position: relative;
    z-index: 3; }
    .tw .banner-form__text {
      width: 835px; }
    .banner-form__text p {
      margin-bottom: 25px;
      font-size: 16px;
      line-height: 155%;
      color: #191919;
      opacity: 0.8; }
    .banner-form__text-in {
      display: flex;
      align-items: center; }
      .banner-form__text-in input {
        margin-right: 10px;
        max-width: 410px; }
  .banner-form__holder {
    display: flex; }

.banner-dop {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  .banner-dop__inner {
    height: 300px;
    display: flex;
    align-items: center; }
  .banner-dop__text {
    width: 360px;
    margin-left: 63%; }
    .banner-dop__text p {
      font-size: 16px;
      line-height: 190%;
      color: #191919; }

.catalog-min {
  margin: 60px 0; }
  .catalog-min__slider {
    margin: 0 -10px; }
    .catalog-min__slider .slick-slide {
      padding: 0 10px; }

.catalog__item {
  position: relative; }
  .catalog__item-labels {
    padding-right: 40px;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 2; }
    .catalog__item-labels-item {
      padding: 5px 9px;
      margin-right: 2px;
      margin-bottom: 2px;
      display: inline-block;
      vertical-align: middle;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 12px;
      line-height: 16px;
      border-radius: 4px;
      border: 2px solid transparent; }
      .catalog__item-labels-item.sale {
        border-color: #C19935;
        color: #C19935; }
      .catalog__item-labels-item.hit {
        border-color: #191919;
        color: #191919; }
      .catalog__item-labels-item.new {
        border-color: #376565;
        color: #376565; }
  .catalog__item-fav {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 2;
    cursor: pointer; }
    .catalog__item-fav svg {
      width: 24px;
      height: 24px; }
      .catalog__item-fav svg use {
        color: #191919; }
    .catalog__item-fv .catalog__item-fav:before {
      content: '';
      width: 24px;
      height: 24px;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
      background-image: url("../images/svg/fv.svg");
      background-repeat: no-repeat;
      background-position: center; }
  .catalog__item-img {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 308px;
    width: 100%;
    margin-bottom: 11px; }
    .catalog__item-img img {
      width: 100%;
      max-height: 305px;
      max-width: 100%; }
  .catalog__item-title {
    margin-bottom: 3px;
    display: block;
    font-size: 16px;
    line-height: 140%;
    color: #191919; }
    .catalog__item-title:hover {
      color: #C19935; }
  .catalog__item-price-now {
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
    font-weight: 600;
    font-size: 22px;
    line-height: 140%;
    color: #191919;
    text-transform: uppercase; }
    .catalog__item-price-now:after {
      display: inline-block;
      margin-left: 7px;
      font-family: "PT Sans", sans-serif;
      content: "\20cf";
      font-weight: 400; }
  .catalog__item-price-old {
    display: inline-block;
    vertical-align: middle;
    text-decoration: line-through;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: #A9AEB2; }
    .catalog__item-price-old:after {
      font-weight: 400;
      display: inline-block;
      margin-left: 7px;
      font-family: "PT Sans", sans-serif;
      content: "\20cf"; }

.catalog-page {
  margin-bottom: 50px; }
  .catalog-page__mob {
    display: none; }
  .catalog-page__inner {
    display: flex;
    padding: 35px 0 0; }
  .catalog-page__left {
    width: 253px; }
  .catalog-page__content {
    width: calc(100% - 253px);
    padding-left: 75px; }
    .catalog-page__content h1 {
      margin-top: 17px; }
    .catalog-page__content-bottom .button {
      display: block;
      width: 100%;
      text-align: center; }
    .catalog-page__content-tx {
      margin-top: 50px; }
      .catalog-page__content-tx-inner {
        height: 112px;
        overflow: hidden; }
        .open .catalog-page__content-tx-inner {
          height: auto; }
      .catalog-page__content-tx h3 {
        margin-bottom: 25px; }
      .catalog-page__content-tx-btn {
        margin-top: 20px; }
        .catalog-page__content-tx-btn-item {
          font-weight: 600;
          font-size: 16px;
          line-height: 140%;
          color: #191919;
          cursor: pointer;
          transition: 0.3s; }
          .catalog-page__content-tx-btn-item:hover {
            color: #C19935; }
            .catalog-page__content-tx-btn-item:hover svg use {
              color: #C19935; }
          .catalog-page__content-tx-btn-item svg {
            width: 18px;
            height: 18px;
            transform: rotate(90deg); }
            .open .catalog-page__content-tx-btn-item svg {
              transform: rotate(-90deg); }
            .catalog-page__content-tx-btn-item svg use {
              color: #191919; }
          .catalog-page__content-tx-btn-item.opn {
            display: inline-block; }
            .open .catalog-page__content-tx-btn-item.opn {
              display: none; }
          .catalog-page__content-tx-btn-item.cl {
            display: none; }
            .open .catalog-page__content-tx-btn-item.cl {
              display: inline-block; }
  .catalog-page__tags {
    margin-top: 32px;
    margin-bottom: 18px; }
    .catalog-page__tags-del {
      margin-left: 12px;
      margin-bottom: 10px;
      display: inline-flex;
      align-items: center;
      vertical-align: middle;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: #797979;
      cursor: pointer;
      transition: 0.3s; }
      .catalog-page__tags-del svg {
        width: 13px;
        height: 13px;
        margin-right: 5px; }
        .catalog-page__tags-del svg use {
          color: #797979; }
      .catalog-page__tags-del:hover {
        color: #C19935; }
        .catalog-page__tags-del:hover svg use {
          color: #C19935; }
    .catalog-page__tags-item {
      margin-bottom: 10px;
      margin-right: 5px;
      display: inline-flex;
      align-items: center;
      padding: 8px 15px;
      border-radius: 4px;
      background: #F7F7F7; }
    .catalog-page__tags-title {
      margin-right: 13px;
      font-weight: 600;
      font-size: 18px;
      line-height: 140%;
      color: #797979; }
      .catalog-page__tags-title span {
        color: #191919; }
    .catalog-page__tags-close {
      cursor: pointer; }
      .catalog-page__tags-close svg {
        width: 13px;
        height: 13px; }
        .catalog-page__tags-close svg use {
          color: #D7BB76; }
      .catalog-page__tags-close:hover svg use {
        color: #C19935; }
  .catalog-page__sorting-title {
    margin-right: 25px;
    display: inline-block;
    vertical-align: middle;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: #797979; }
  .catalog-page__sorting ul {
    display: inline-block;
    vertical-align: middle; }
    .catalog-page__sorting ul li {
      margin-right: 15px;
      display: inline-block;
      vertical-align: middle; }
      .catalog-page__sorting ul li a {
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        color: #191919; }
        .catalog-page__sorting ul li a:hover {
          color: #C19935; }
      .catalog-page__sorting ul li.active a {
        color: #C19935; }
  .catalog-page__list {
    margin-top: 25px; }
    .catalog-page__list .catalog__item {
      margin-bottom: 50px; }

.slick-arrow {
  width: 78px;
  height: 78px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 3;
  border-radius: 1000px;
  border: 1px solid #E5E6E9;
  background: transparent;
  transform: none;
  transition: 0.3s;
  cursor: pointer; }
  .slick-arrow svg {
    width: 22px;
    height: 22px; }
    .slick-arrow svg use {
      color: #191919; }
  .slick-arrow:hover {
    border-color: #191919;
    background: #191919; }
    .slick-arrow:hover svg use {
      color: #ffffff; }

.slick-prev {
  left: 0;
  transform: rotate(180deg); }

.slick-next {
  right: 0; }

.slick-disabled {
  opacity: 0;
  z-index: -9; }

.slick-dots {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 5; }
  .slick-dots li {
    width: 6px;
    height: 6px;
    display: inline-block;
    margin-right: 7px;
    background: #797979;
    opacity: 0.5;
    border-radius: 10px; }
    .slick-dots li button {
      display: none; }
    .slick-dots li.slick-active {
      opacity: 1; }

.brands {
  margin: 70px 0;
  padding: 62px 0;
  background: #F7F7F7; }
  .brands__inner {
    display: flex;
    flex-wrap: wrap; }
  .brands__item {
    padding: 0 10px;
    height: 200px;
    flex: 0 0 20%;
    max-width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 4px;
    overflow: hidden; }
    .brands__item:after {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      transition: 0.3s;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      background-image: url("../images/brands-hov.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center; }
    .brands__item-img {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 2;
      transition: 0.3s; }
      .brands__item-img img {
        max-width: 100%;
        margin: auto; }
      .brands__item-img-hov {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        z-index: 2;
        opacity: 0;
        transition: 0.3s; }
        .brands__item-img-hov img {
          max-width: 100%;
          margin: auto; }
    .brands__item:hover {
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05); }
      .brands__item:hover:after {
        opacity: 1; }
      .brands__item:hover .brands__item-img {
        opacity: 0; }
        .brands__item:hover .brands__item-img-hov {
          opacity: 1; }
  .brands-page {
    padding-bottom: 90px; }

.services {
  margin: 70px 0; }
  .services__text-min {
    margin-bottom: 5px;
    font-size: 12px;
    line-height: 16px;
    color: #191919;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-weight: 700; }
  .services__text h2 {
    margin-bottom: 18px; }
  .services__text p {
    margin-bottom: 50px;
    font-size: 16px;
    line-height: 160%;
    color: #191919; }
  .services__item {
    position: relative; }
    .services__item a {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2; }
    .services__item-img {
      height: 390px;
      width: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover; }
    .services__item-title {
      margin-top: 20px;
      font-size: 24px;
      line-height: 130%;
      color: #191919;
      transition: 0.3s;
      font-family: "Lora", sans-serif; }
    .services__item:hover .services__item-title {
      color: #C19935; }

.blog {
  margin-bottom: 80px; }
  .blog-slider {
    margin: 100px 0;
    padding: 60px 0 100px;
    background: linear-gradient(85.76deg, #00352C 9.48%, #0F6254 48.58%, #2A7E70 68.64%, #0F6254 84.71%, #00352C 100.97%), #023626; }
    .blog-slider .button-link {
      color: #ffffff; }
      .blog-slider .button-link svg use {
        color: #ffffff; }
      .blog-slider .button-link:hover {
        color: #D7BB76; }
        .blog-slider .button-link:hover svg use {
          color: #D7BB76; }
  .blog__item {
    margin-bottom: 30px;
    height: 270px;
    padding: 34px 40px 60px 40px;
    position: relative;
    background: #ffffff;
    border-radius: 5px;
    overflow: hidden; }
    .blog__item a {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3; }
    .blog__item:before {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      opacity: 0;
      transition: 0.3s;
      background-image: url("../images/bg6.jpg");
      background-repeat: no-repeat;
      background-position: center; }
    .blog__item:hover:before {
      opacity: 1; }
    .blog__item:hover .blog__item-link {
      color: #C19935; }
      .blog__item:hover .blog__item-link svg use {
        color: #C19935; }
    .blog__item-data {
      display: inline-block;
      padding: 7px 12px;
      margin-bottom: 20px;
      position: relative;
      z-index: 2;
      font-size: 12px;
      line-height: 16px;
      color: #191919;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      font-weight: 700;
      background: #D7BB76;
      border-radius: 4px; }
    .blog__item-title {
      height: 110px;
      position: relative;
      z-index: 2;
      font-style: italic;
      font-weight: 500;
      font-size: 28px;
      line-height: 130%;
      color: #191919;
      font-family: "Lora", sans-serif;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden; }
    .blog__item-link {
      position: absolute;
      left: 37px;
      bottom: 34px;
      z-index: 2;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: #191919;
      transition: 0.3s; }
      .blog__item-link svg {
        width: 16px;
        height: 16px; }
        .blog__item-link svg use {
          color: #191919; }
  .blog__text p {
    margin-bottom: 45px;
    font-weight: 300;
    font-size: 16px;
    line-height: 160%;
    color: #191919; }
  .row-right .blog__text {
    padding-left: 80px; }
  .blog__img {
    height: 450px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; }
  .blog .row {
    margin-bottom: 100px;
    align-items: center; }
    .blog .row:last-of-type {
      margin-bottom: 0; }
  .blog-item {
    margin-bottom: 80px; }
    .blog-item-banner {
      height: 450px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center; }
    .blog-item__inner {
      margin: 40px 0;
      display: flex;
      align-items: flex-start; }
    .blog-item__text {
      padding-right: 150px;
      width: calc(100% - 527px); }
    .blog-item__form {
      width: 527px;
      padding: 40px;
      border-radius: 4px;
      background: linear-gradient(294.11deg, #E6E6E6 0%, #F7F7F7 56.04%, #E6E6E6 114.47%); }
  .blog-bottom {
    padding: 40px 20px 50px;
    margin-top: 30px;
    position: relative;
    background: linear-gradient(84.52deg, #00352C 12.61%, #0F6254 57.61%, #2A7E70 80.68%, #0F6254 99.19%, #00352C 117.89%), #EFE5D9;
    text-align: center;
    border-radius: 4px; }
    .blog-bottom:before {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      background-image: url("../images/bg11.png"); }
    .blog-bottom-title {
      margin-bottom: 20px;
      position: relative;
      z-index: 4;
      font-family: "Lora", sans-serif;
      font-size: 34px;
      line-height: 135%;
      color: #ffffff; }
    .blog-bottom p {
      position: relative;
      z-index: 4;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: #ffffff;
      opacity: 0.5; }
    .blog-bottom .button-link {
      position: relative;
      z-index: 4;
      color: #ffffff; }
      .blog-bottom .button-link svg use {
        color: #ffffff; }
      .blog-bottom .button-link:hover {
        color: #C19935; }
        .blog-bottom .button-link:hover svg use {
          color: #C19935; }
  .blog-page {
    padding-bottom: 70px; }

.text blockquote {
  padding: 30px;
  margin: 10px 0 30px;
  font-weight: 600;
  font-size: 18px;
  line-height: 160%;
  color: #191919;
  background: #EFE5D9;
  border-radius: 4px;
  text-align: center; }

.text p {
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 190%;
  color: #797979; }

.text img {
  margin: 30px 0;
  width: 100%;
  max-width: 100%; }

.text h2 {
  margin-top: 55px;
  margin-bottom: 30px; }

.text h3 {
  margin-bottom: 10px; }

.text ol {
  margin: 30px 0;
  padding-left: 0;
  counter-reset: myCounter; }
  .text ol li {
    padding-left: 45px;
    margin-bottom: 15px;
    position: relative;
    font-size: 18px;
    line-height: 180%;
    color: #797979;
    list-style: none; }
    .text ol li:before {
      counter-increment: myCounter;
      content: counters(myCounter, ".") ".";
      display: block;
      position: absolute;
      top: -1px;
      left: 0;
      z-index: 2;
      font-size: 24px;
      line-height: 130%;
      color: #04052D;
      font-family: "Lora", sans-serif; }

.text__acc {
  margin-top: 30px; }
  .text__acc .accordion-item {
    padding: 0;
    margin-bottom: 0;
    border: none;
    border-bottom: 1px solid #D2D2D2 !important; }
  .text__acc .accordion-header {
    border: none !important;
    background: transparent; }
  .text__acc .accordion-button {
    padding: 20px 0 !important;
    font-size: 24px;
    line-height: 130%;
    color: #191919;
    font-family: "Lora", sans-serif;
    border: none !important;
    background: transparent;
    box-shadow: none !important; }
  .text__acc .accordion-body {
    padding: 0 0 20px !important; }
    .text__acc .accordion-body p {
      font-size: 18px;
      line-height: 190%;
      color: #797979; }

.text__table {
  margin: 30px 0; }
  .text__table table {
    width: 100%;
    border: 1px solid #D2D2D2; }
    .text__table table tr th {
      font-weight: 600;
      font-size: 18px;
      line-height: 140%;
      color: #191919;
      border: 1px solid #D2D2D2; }
    .text__table table tr td {
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: #191919;
      border: 1px solid #D2D2D2; }
    .text__table table tr th, .text__table table tr td {
      padding: 15px; }

.text__link {
  margin: 30px 0; }
  .text__link-item {
    margin-bottom: 4px;
    display: inline-flex;
    align-items: center;
    padding: 9px 17px;
    margin-right: 5px;
    font-size: 14px;
    line-height: 120%;
    color: #191919 !important;
    background: #EFE5D9;
    border-radius: 4px; }
    .text__link-item svg {
      width: 17px;
      height: 17px;
      margin-left: 10px; }
      .text__link-item svg use {
        color: #191919; }
    .text__link-item:hover {
      background: #D7BB76; }

.text__video {
  position: relative; }
  .text__video img {
    max-width: 100%; }
  .text__video:before {
    content: '';
    width: 95px;
    height: 95px;
    display: inline-flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 2;
    background: #191919;
    border-radius: 1000px;
    transition: 0.3s; }
  .text__video:after {
    content: '';
    width: 34px;
    height: 34px;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 3;
    background-image: url("../images/svg/pley.svg");
    background-repeat: no-repeat;
    background-position: center; }
  .text__video:hover:before {
    background: #D7BB76; }

.text__slider {
  margin: 30px 0;
  padding-bottom: 70px;
  position: relative; }
  .text__slider .pagingInfo {
    width: 50px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
    margin: auto; }
    .text__slider .pagingInfo .number-slide .numb-prev, .text__slider .pagingInfo .number-slide .numb-next {
      padding: 0 3px;
      font-size: 20px;
      line-height: 160%;
      font-family: "Lora", sans-serif; }
    .text__slider .pagingInfo .number-slide .numb-next:before {
      content: '/';
      display: inline-block;
      vertical-align: middle;
      position: relative;
      left: -4px;
      top: -3px;
      font-size: 18px;
      line-height: 160%; }
  .text__slider .slick-arrow {
    width: 40px;
    height: 40px;
    top: inherit;
    bottom: -53px;
    left: 0;
    right: 0;
    margin: auto;
    border: 2px solid rgba(0, 0, 0, 0.2); }
    .text__slider .slick-arrow svg {
      width: 18px;
      height: 18px; }
    .text__slider .slick-arrow:hover {
      background: #191919; }
      .text__slider .slick-arrow:hover svg use {
        color: #ffffff; }
  .text__slider .slick-prev {
    left: -120px; }
  .text__slider .slick-next {
    right: -100px; }
  .text__slider-inner {
    height: 505px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; }

.footer {
  margin-top: 100px;
  background-image: url("../images/bg10.png");
  background-repeat: no-repeat;
  background-position: center right;
  background-color: #191919;
  background-size: cover; }
  .footer__main {
    padding: 30px 0 140px; }
    .footer__main-inner {
      display: flex;
      align-items: flex-start;
      justify-content: space-between; }
  .footer__bottom {
    padding: 10px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.05); }
    .footer__bottom-inner {
      display: flex;
      justify-content: space-between;
      align-items: center; }
    .footer__bottom-tx {
      color: #5E5E5E;
      font-size: 14px;
      line-height: 140%; }
    .footer__bottom-links a {
      display: inline-block;
      vertical-align: middle;
      margin: 0 25px;
      color: #5E5E5E;
      font-size: 14px;
      line-height: 140%; }
      .footer__bottom-links a:hover {
        color: #D7BB76; }
  .footer__dev {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    line-height: 140%;
    color: #5E5E5E; }
    .footer__dev svg {
      width: 22px;
      height: 22px;
      margin-left: 5px; }
      .footer__dev svg use {
        color: #5E5E5E; }
    .footer__dev:hover {
      color: #D7BB76; }
      .footer__dev:hover svg use {
        color: #D7BB76; }
  .footer__logo {
    margin-bottom: 25px;
    display: inline-block; }
  .footer__tel {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #ffffff; }
    .footer__tel:hover {
      color: #C19935; }
  .footer__call {
    font-size: 14px;
    line-height: 19px;
    color: #797979; }
    .footer__call:hover {
      color: #C19935; }
  .footer__time {
    margin-top: 30px;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #ffffff;
    letter-spacing: 0.06em;
    text-transform: uppercase; }
  .footer__menu {
    width: 50%;
    padding-top: 10px;
    display: flex; }
  .footer__text {
    width: 352px; }
    .footer__text p {
      font-size: 15px;
      line-height: 150%;
      color: #797979; }
  .footer__inst {
    padding-top: 10px; }
    .footer__inst a {
      display: inline-flex;
      align-items: center;
      font-weight: 800;
      font-size: 16px;
      line-height: 22px;
      color: #D7BB76;
      letter-spacing: 0.08em;
      text-transform: uppercase; }
      .footer__inst a svg {
        width: 24px;
        height: 24px;
        margin-right: 5px; }
        .footer__inst a svg use {
          color: #D7BB76; }
      .footer__inst a:hover {
        color: #C19935; }
        .footer__inst a:hover svg use {
          color: #C19935; }
  .footer__item {
    width: 22%;
    padding: 0 15px;
    box-sizing: border-box; }
    .footer__item:last-of-type {
      width: 36%; }
    .footer__item-title {
      margin-bottom: 15px;
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      color: #797979;
      letter-spacing: 0.06em;
      text-transform: uppercase; }
      .footer__item-title svg {
        display: none; }
    .footer__item ul li {
      margin-bottom: 8px; }
      .footer__item ul li a {
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        color: #ffffff; }
        .footer__item ul li a:hover {
          color: #D7BB76; }

.pagination {
  display: flex;
  align-items: center;
  justify-content: center; }
  .pagination-bl {
    margin-top: 50px; }
  .pagination .page-item {
    margin: 0 5px; }
    .pagination .page-item a, .pagination .page-item span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 45px;
      height: 45px;
      font-weight: 700;
      font-size: 18px;
      line-height: 140%;
      text-align: center;
      text-transform: uppercase;
      border-radius: 100px !important;
      border: none;
      box-shadow: none !important;
      background: transparent;
      color: #191919; }
      .pagination .page-item a:hover, .pagination .page-item span:hover {
        color: #C19935; }
    .pagination .page-item span {
      cursor: inherit !important;
      color: #5E5E5E;
      background: transparent; }
    .pagination .page-item.active a {
      background: #191919;
      color: #ffffff; }
    .pagination .page-item.prev, .pagination .page-item.next {
      width: 30px;
      height: 40px;
      margin: 0 18px;
      position: relative; }
      .pagination .page-item.prev:hover, .pagination .page-item.next:hover {
        background: transparent; }
      .pagination .page-item.prev span.page-link .ar, .pagination .page-item.next span.page-link .ar {
        opacity: 0.5; }
      .pagination .page-item.prev .page-link, .pagination .page-item.next .page-link {
        padding: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 40px; }
      .pagination .page-item.prev .ar, .pagination .page-item.next .ar {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 5px; }
      .pagination .page-item.prev a, .pagination .page-item.next a {
        position: initial;
        width: auto;
        height: auto;
        background: transparent !important; }
        .pagination .page-item.prev a:hover .ar, .pagination .page-item.next a:hover .ar {
          border-color: #F7F7F7; }
        .pagination .page-item.prev a:hover .tt, .pagination .page-item.next a:hover .tt {
          color: #F7F7F7; }
        .pagination .page-item.prev a:hover svg use, .pagination .page-item.next a:hover svg use {
          color: #C19935; }
      .pagination .page-item.prev svg, .pagination .page-item.next svg {
        width: 30px;
        height: 30px; }
        .pagination .page-item.prev svg use, .pagination .page-item.next svg use {
          color: #191919; }
    .pagination .page-item.prev {
      margin-left: 0;
      transform: rotate(180deg); }
      .pagination .page-item.prev svg {
        position: relative;
        top: 4px; }
    .pagination .page-item.next svg {
      position: relative;
      top: 7px; }

.mCSB_dragger_bar {
  width: 2px !important;
  background: #D2D2D2 !important;
  border-radius: 0 !important; }

.mCSB_draggerRail {
  background: #EDEDED !important;
  width: 2px !important;
  border-radius: 0 !important; }

.filter {
  width: 100%; }
  .filter__total {
    width: 152px;
    height: 79px;
    padding: 19px 17px 20px 35px;
    position: absolute;
    right: -55px;
    top: 120px;
    z-index: 30;
    background-image: url("../images/svg/tooltip.svg");
    background-repeat: no-repeat;
    background-position: center; }
    .filter__total-quantity {
      font-size: 14px;
      line-height: 120%;
      color: #A2A2A2; }
    .filter__total-all {
      display: inline-block;
      font-size: 14px;
      line-height: 120%;
      letter-spacing: 0.01em;
      color: #191919; }
      .filter__total-all:hover {
        color: #C19935 !important; }
  .filter__top {
    display: none; }
    .filter__top-close {
      display: none; }
    .filter__top-tt {
      display: none;
      padding: 15px 0;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 17px;
      color: #191919; }
      .filter__top-tt-main {
        margin-bottom: 18px;
        font-weight: 700;
        font-size: 18px;
        line-height: 121.03%;
        color: #191919;
        text-transform: uppercase; }
  .filter__btn {
    padding: 18px 0;
    margin: 15px auto 0; }
    .filter__btn .button {
      display: block;
      width: 100%;
      margin-bottom: 10px;
      text-align: center; }
      .filter__btn .button-link {
        padding: 10px 0;
        display: block;
        color: #797979;
        font-size: 14px;
        line-height: 19px;
        margin-top: 10px; }
        .filter__btn .button-link:hover {
          color: #D7BB76; }
  .filter__top {
    display: none; }
  .filter__item {
    padding: 18px 0;
    position: relative;
    border-bottom: 1px solid #EDEDED; }
    .filter__list .filter__item {
      padding: 8px 0;
      border: none; }
    .filter__item.active .filter__title {
      color: #F7F7F7; }
    .filter__item:last-of-type {
      margin-bottom: 20px; }
  .filter__container {
    display: none;
    margin-top: 10px; }
    .filter__list .filter__container {
      margin-top: 10px; }
    .open .filter__container {
      display: block; }
  .filter__title {
    position: relative;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    color: #191919;
    cursor: pointer; }
    .filter__title + .filter__title-t-sr {
      margin-top: 32px; }
    .filter__title-m {
      display: none;
      margin-bottom: 12px;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #191919; }
      .filter__title-m:hover {
        color: #F7F7F7; }
      .filter__title-m:nth-of-type(1), .filter__title-m:nth-of-type(2), .filter__title-m:nth-of-type(3), .filter__title-m:nth-of-type(4) {
        display: block; }
      .on .filter__title-m {
        display: block; }
    .filter__title-t {
      display: block;
      position: relative;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: #000000; }
      .filter__title-t:after {
        content: '';
        display: inline-block;
        width: 12px;
        height: 12px;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        transition: 0.3s;
        background-image: url("../images/svg/arrow.svg");
        background-repeat: no-repeat;
        background-position: center;
        transform: rotate(90deg); }
      .open .filter__title-t:after {
        transform: rotate(0deg); }
      .tw .filter__title-t {
        text-transform: uppercase; }
        .tw .filter__title-t:after {
          display: none; }
      .filter__title-t-inner {
        padding-bottom: 35px; }
        .open .filter__title-t-inner {
          display: block; }
        .tw .filter__title-t-inner {
          padding-bottom: 0;
          padding-top: 15px; }
      .filter__title-t-btn {
        margin-top: 10px;
        font-weight: 500;
        font-size: 14px;
        line-height: 130%;
        color: #191919;
        cursor: pointer;
        transition: 0.3s; }
        .filter__title-t-btn:hover {
          color: #C19935; }
        .open .tw .filter__title-t-btn {
          display: none; }
        .on .filter__title-t-btn {
          display: none; }
    .filter__title:after {
      content: '';
      display: inline-block;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 5px;
      right: 0;
      z-index: 1;
      background-image: url("../images/svg/arrow.svg");
      background-repeat: no-repeat;
      background-position: center;
      transform: rotate(90deg);
      transition: 0.3s; }
      .open .filter__title:after {
        transform: rotate(270deg) !important; }
    .filter__title.open:after {
      transform: rotate(180deg); }
  .filter__all span {
    font-size: 13px;
    line-height: 140%;
    letter-spacing: 0.01em;
    color: #F7F7F7;
    cursor: pointer; }
    .filter__all span:hover {
      opacity: 0.7; }
    .filter__all span.show {
      display: block; }
    .filter__all span.hide {
      display: none; }
  .filter__all.open span.show {
    display: none; }
  .filter__all.open span.hide {
    display: block;
    margin-top: 10px; }
  .filter__group-check {
    position: relative;
    height: 155px;
    overflow: hidden; }
    .filter__list.tw .filter__group-check {
      height: auto;
      overflow: inherit; }
    .filter__group-check .filter__check {
      display: none; }
      .filter__group-check .filter__check:nth-of-type(1), .filter__group-check .filter__check:nth-of-type(2), .filter__group-check .filter__check:nth-of-type(3), .filter__group-check .filter__check:nth-of-type(4), .filter__group-check .filter__check:nth-of-type(5) {
        display: block; }
      .on .filter__group-check .filter__check {
        display: block !important; }
  .filter__check {
    margin-bottom: 7px;
    position: relative; }
    .filter__check:last-of-type {
      margin-bottom: 0; }
    .filter__group-check.open .filter__check {
      display: block; }
    .filter__check input[type="checkbox"] {
      display: none; }
      .filter__check input[type="checkbox"] + label {
        padding-left: 30px;
        position: relative;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #191919;
        cursor: pointer;
        transition: 0.3s; }
        .filter__check input[type="checkbox"] + label:before {
          content: '';
          width: 20px;
          height: 20px;
          display: block;
          position: absolute;
          top: -1px;
          left: 0;
          z-index: 2;
          border-radius: 3px;
          border: 1px solid #797979;
          transition: 0.3s; }
        .filter__check input[type="checkbox"] + label:hover {
          color: #C19935; }
          .filter__check input[type="checkbox"] + label:hover:before {
            border-color: #C19935; }
      .filter__check input[type="checkbox"]:checked + label:before {
        background-image: url("../images/svg/check.svg");
        background-repeat: no-repeat;
        background-size: 20px 20px;
        background-position: center;
        background-color: #191919;
        border-color: #191919; }
  .filter__list {
    padding: 20px 0;
    border-top: 4px solid rgba(44, 44, 44, 0.2); }
    .filter__list ul {
      padding-left: 25px;
      margin-bottom: 0; }
      .filter__list ul li {
        margin-bottom: 6px;
        position: relative; }
        .filter__list ul li:last-of-type {
          margin-bottom: 0; }
        .filter__list ul li.dp:after {
          content: '';
          width: 6px;
          height: 6px;
          display: block;
          position: absolute;
          top: 10px;
          right: 0;
          background-repeat: no-repeat;
          background-position: center;
          background-image: url(../images/svg/arrow2.svg);
          background-size: 6px 6px;
          -webkit-transition: 0.3s;
          transition: 0.3s; }
        .filter__list ul li a {
          font-size: 12px;
          line-height: 166%;
          color: #191919; }
          .filter__list ul li a:hover {
            color: #F7F7F7; }
        .filter__list ul li span {
          font-size: 12px;
          line-height: 166%;
          color: #191919;
          cursor: pointer; }
        .filter__list ul li.active a {
          color: #F7F7F7; }
        .filter__list ul li ul {
          display: none;
          margin-top: 10px; }
          .filter__list ul li ul li {
            position: relative; }
      .filter__list ul.op li ul {
        display: block; }
      .filter__list ul.op li.dp:after {
        top: 7px;
        transform: rotate(180deg); }
  .filter__range-item {
    position: absolute;
    top: 55px; }
    .filter__range-item.on {
      left: 0; }
      .filter__range-item.on:before {
        content: 'От';
        display: block;
        position: absolute;
        left: 13px;
        top: 11px;
        font-size: 14px;
        line-height: 120%;
        color: #797979; }
    .filter__range-item.tw {
      right: 0; }
      .filter__range-item.tw:before {
        content: 'До';
        display: block;
        position: absolute;
        left: 13px;
        top: 11px;
        font-size: 14px;
        line-height: 120%;
        color: #797979; }
  .filter__range.polzunok-container-5 {
    margin: 75px 0 5px;
    box-sizing: border-box;
    outline: none; }
    .filter__range.polzunok-container-5:after {
      content: "";
      clear: both;
      display: table; }
    .filter__range.polzunok-container-5 .ui-slider {
      height: 4px;
      position: relative;
      background: #D2D2D2;
      border-radius: 2px; }
      .filter__range.polzunok-container-5 .ui-slider .ui-slider-range {
        height: 4px;
        position: absolute;
        z-index: 1;
        border: none;
        background: #191919; }
    .filter__range.polzunok-container-5 .ui-slider-handle {
      width: 16px;
      height: 16px;
      margin-left: -6px;
      position: absolute;
      top: -5px;
      cursor: pointer;
      background: #191919;
      z-index: 2;
      border-radius: 100px; }
      .filter__range.polzunok-container-5 .ui-slider-handle:focus {
        outline: none; }
    .filter__range.polzunok-container-5 .polzunok-input-5-left,
    .filter__range.polzunok-container-5 .polzunok-input-5-right {
      display: inline-block;
      width: 109px;
      height: 37px;
      padding: 10px 10px 10px 35px;
      font-size: 14px;
      line-height: 17px;
      background: #ffffff;
      color: #191919;
      border-radius: 2px;
      border: 1px solid #D2D2D2 !important; }
      .filter__range.polzunok-container-5 .polzunok-input-5-left:focus,
      .filter__range.polzunok-container-5 .polzunok-input-5-right:focus {
        border-color: #F7F7F7; }
    .filter__range.polzunok-container-5 .polzunok-input-5-left {
      left: 0; }
    .filter__range.polzunok-container-5 .polzunok-input-5-right {
      right: 0; }

.select {
  background-image: url("../images/svg/ar.svg");
  background-position: center right;
  background-repeat: no-repeat; }
  .select select {
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: #191919;
    background: transparent;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; }

.page-top {
  padding: 33px 0 40px; }
  .page-top h1 {
    margin-bottom: 0; }

.breadcrumb {
  padding: 0; }
  .breadcrumb-block {
    margin-bottom: 15px; }
    .breadcrumb-block .breadcrumb {
      padding: 0;
      margin: 0;
      background: transparent; }
      .breadcrumb-block .breadcrumb-item {
        font-size: 14px;
        line-height: 120%;
        color: #797979; }
        .breadcrumb-block .breadcrumb-item a {
          font-size: 14px;
          line-height: 120%;
          color: #191919; }
          .breadcrumb-block .breadcrumb-item a:hover {
            color: #C19935; }
        .breadcrumb-block .breadcrumb-item + .breadcrumb-item:before {
          content: '—';
          display: inline-block;
          vertical-align: middle;
          margin: 0 8px 0 1px;
          padding: 0;
          font-size: 14px;
          line-height: 120%;
          color: #797979; }

.form__inner input {
  margin-bottom: 20px; }

.form__inner .button {
  width: 100%;
  text-align: center; }

.form__title {
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 130%;
  color: #191919;
  font-family: "Lora", sans-serif; }

.form p {
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 120%;
  color: #797979; }

.form__text {
  text-align: center; }

.form__tx {
  margin-top: 5px;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 120%;
  color: #797979; }
  .form__tx a {
    color: #191919; }
    .form__tx a:hover {
      color: #C19935; }

.bg {
  background: #F7F7F7; }

.size .container {
  position: relative; }

.size__inner {
  max-width: 937px;
  margin: auto; }

.actions-item {
  margin-bottom: 40px; }
  .actions-item .container {
    position: relative; }
  .actions-item__inner {
    margin-top: 20px; }
  .actions-item__pages {
    margin-top: 60px;
    padding: 25px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #E0E0E0; }
    .actions-item__pages-prev, .actions-item__pages-next {
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #191919; }
      .actions-item__pages-prev svg, .actions-item__pages-next svg {
        width: 40px;
        height: 10px; }
        .actions-item__pages-prev svg use, .actions-item__pages-next svg use {
          color: #191919; }
      .actions-item__pages-prev:hover, .actions-item__pages-next:hover {
        color: #D7BB76; }
        .actions-item__pages-prev:hover use, .actions-item__pages-next:hover use {
          color: #D7BB76; }
    .actions-item__pages-prev svg {
      margin-right: 5px;
      transform: rotate(180deg); }
    .actions-item__pages-next svg {
      margin-left: 5px; }

.data {
  margin-top: 17px;
  display: inline-block;
  padding: 9px 14px;
  background: #D7BB76;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #191919;
  border-radius: 3px; }

input {
  width: 100%;
  height: 55px;
  padding: 3px 20px;
  font-size: 14px;
  line-height: 19px;
  color: #191919;
  background: #ffffff;
  border-radius: 5px;
  border: none;
  box-shadow: none; }
  input::-webkit-input-placeholder {
    color: #797979; }
  input:-ms-input-placeholder {
    color: #797979; }
  input::placeholder {
    color: #797979; }

textarea {
  height: 87px;
  width: 100%;
  padding: 10px 20px;
  font-size: 14px;
  line-height: 19px;
  color: #191919;
  background: #ffffff;
  border-radius: 5px;
  border: none;
  box-shadow: none; }
  textarea::-webkit-input-placeholder {
    color: #797979; }
  textarea:-ms-input-placeholder {
    color: #797979; }
  textarea::placeholder {
    color: #797979; }

.soc-l {
  position: absolute;
  top: 65px;
  left: 15px;
  z-index: 2; }
  .soc-l-item {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    border-radius: 100px;
    border: 1px solid #D2D2D2; }
    .soc-l-item svg {
      width: 20px;
      height: 20px; }
      .soc-l-item svg use {
        color: #000000; }
    .soc-l-item:hover {
      background: #191919; }
      .soc-l-item:hover svg use {
        color: #ffffff; }

.delivery {
  margin-top: 40px; }
  .delivery-title {
    margin-bottom: 10px;
    font-family: "Lora", sans-serif;
    font-size: 24px;
    line-height: 130%;
    color: #000000; }
  .delivery p {
    font-size: 18px;
    line-height: 180%;
    color: #797979; }
    .delivery p + .form__title {
      margin-top: 38px; }

.cart-product__inner {
  display: flex;
  align-items: flex-start;
  position: relative; }

.cart-product__slider {
  width: 800px;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
  background: #ffffff; }
  .cart-product__slider-nav {
    width: 119px;
    display: flex;
    align-items: baseline;
    position: absolute;
    left: 0;
    top: 31px;
    opacity: 0; }
    .cart-product__slider-nav.slick-initialized {
      display: block;
      opacity: 1;
      transition: opacity .3s ease-out; }
    .cart-product__slider-nav .slick-list {
      display: flex;
      align-items: baseline; }
    .cart-product__slider-nav-item {
      width: 117px !important;
      height: 117px !important;
      margin-bottom: 2px;
      padding: 10px 5px;
      border-radius: 4px;
      border: 2px solid transparent;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center; }
      .slick-current.slick-active .cart-product__slider-nav-item {
        border-color: #C19935;
        opacity: 1; }
      .cart-product__slider-nav-item-inner {
        width: 100%;
        height: 100%;
        position: relative;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center; }
        .cart-product__slider-nav-item-inner.video:before {
          content: '';
          width: 35px;
          height: 35px;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          z-index: 2;
          background-color: #ffffff;
          background-image: url("../images/svg/play2.svg");
          background-repeat: no-repeat;
          background-position: center;
          border-radius: 100px; }
  .cart-product__slider-for {
    width: 568px;
    height: 600px;
    margin-left: 20px;
    opacity: 0; }
    .cart-product__slider-for.slick-initialized {
      display: block;
      opacity: 1;
      transition: opacity .3s ease-out; }
    .cart-product__slider-for .slick-arrow {
      width: 23px;
      height: 23px;
      top: inherit;
      bottom: -70px; }
      .cart-product__slider-for .slick-arrow span {
        border: none; }
    .cart-product__slider-for .slick-next {
      right: -50px; }
    .cart-product__slider-for .slick-prev {
      left: inherit;
      right: -10px; }
    .cart-product__slider-for-item {
      padding: 40px 10px;
      width: 332px;
      height: 660px !important;
      text-align: center; }
      .cart-product__slider-for-item .img {
        width: 100%;
        height: 100%;
        display: block;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center; }
        .cart-product__slider-for-item .img.video:before {
          content: '';
          width: 45px;
          height: 45px;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          z-index: 2;
          background-color: #ffffff;
          background-repeat: no-repeat;
          background-position: center;
          background-image: url("../images/svg/play2.svg");
          border-radius: 100px; }
  .cart-product__slider .catalog__item-labels, .cart-product__slider .catalog__item-fav {
    display: none; }

.cart-product__info {
  margin-top: 30px;
  width: calc(100% - 800px); }
  .cart-product__info-all {
    margin-top: 40px; }
  .cart-product__info-size-title {
    display: inline-block;
    margin-bottom: 17px;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    color: #191919;
    position: relative; }
    .cart-product__info-size-title:hover .cart-product__info-size-title-dow {
      display: block; }
    .cart-product__info-size-title-dow {
      width: 300px;
      display: none;
      padding: 10px 20px;
      position: absolute;
      bottom: 63px;
      left: 105px;
      border-radius: 4px 4px 4px 0;
      font-size: 12px;
      line-height: 150%;
      color: #191919;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
      background: #ffffff; }
      .cart-product__info-size-title-dow:before {
        content: '';
        width: 67px;
        height: 29px;
        display: block;
        position: absolute;
        bottom: -29px;
        left: -16px;
        z-index: 2;
        background: url("../images/ar.png");
        background-repeat: no-repeat; }
  .cart-product__info-size-list {
    margin-right: 20px;
    display: inline-block;
    vertical-align: middle; }
    .cart-product__info-size-list-item {
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 5px; }
      .cart-product__info-size-list-item input[type="radio"] {
        display: none; }
        .cart-product__info-size-list-item input[type="radio"] + label {
          display: inline-block;
          padding: 8px 15px;
          margin-right: 3px;
          font-weight: 600;
          font-size: 18px;
          line-height: 140%;
          color: #191919;
          border-radius: 4px;
          border: 1px solid #D2D2D2; }
        .cart-product__info-size-list-item input[type="radio"]:checked + label {
          background: #191919;
          color: #ffffff;
          border-color: #191919; }
  .cart-product__info-size-link {
    display: inline-block;
    vertical-align: middle;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    color: #191919; }
    .cart-product__info-size-link:hover {
      color: #D7BB76; }
  .cart-product__info-size-tx {
    margin-top: 10px;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    color: #797979; }
  .cart-product__info-size-all {
    margin-top: 37px; }
  .cart-product__info-fav, .cart-product__info-mr {
    display: inline-block;
    vertical-align: middle;
    margin-right: 30px;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: #191919;
    cursor: pointer; }
    .cart-product__info-fav svg, .cart-product__info-mr svg {
      width: 25px;
      height: 25px;
      margin-right: 3px; }
      .cart-product__info-fav svg use, .cart-product__info-mr svg use {
        color: #191919; }
    .cart-product__info-fav:hover, .cart-product__info-mr:hover {
      color: #C19935; }
      .cart-product__info-fav:hover svg use, .cart-product__info-mr:hover svg use {
        color: #C19935; }
  .cart-product__info-price {
    margin-top: 20px; }
    .cart-product__info-price.mob {
      display: none; }
    .cart-product__info-price-now {
      margin-right: 33px;
      display: inline-block;
      vertical-align: middle;
      font-weight: 600;
      font-size: 34px;
      line-height: 140%;
      color: #191919; }
      .cart-product__info-price-now:after {
        display: inline-block;
        margin-left: 7px;
        font-family: "PT Sans", sans-serif;
        content: "\20cf";
        font-weight: 400; }
    .cart-product__info-price-old {
      display: inline-block;
      vertical-align: middle;
      font-weight: 600;
      font-size: 22px;
      line-height: 140%;
      color: #828282;
      text-decoration: line-through; }
      .cart-product__info-price-old:after {
        display: inline-block;
        margin-left: 7px;
        font-family: "PT Sans", sans-serif;
        content: "\20cf";
        font-weight: 400; }
  .cart-product__info-btns {
    margin-top: 27px; }
    .cart-product__info-btns-link {
      display: inline-block;
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.06em;
      text-transform: uppercase;
      color: #191919; }
      .cart-product__info-btns-link:hover {
        color: #D7BB76; }
    .cart-product__info-btns .button {
      margin-bottom: 10px;
      margin-right: 50px; }
  .cart-product__info-form {
    margin-top: 20px;
    display: flex; }
    .cart-product__info-form input {
      width: calc(100% - 269px);
      border: 1px solid #D2D2D2;
      margin-right: 10px; }
    .cart-product__info-form .button {
      width: 269px;
      padding: 17px 3px;
      box-sizing: content-box; }
  .cart-product__info-descr {
    padding: 20px 25px;
    margin-top: 30px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 4px; }
    .cart-product__info-descr-title {
      margin-bottom: 8px;
      font-weight: 600;
      font-size: 18px;
      line-height: 140%;
      color: #000000; }
    .cart-product__info-descr p {
      margin-bottom: 12px;
      font-size: 14px;
      line-height: 160%;
      color: #191919; }
    .cart-product__info-descr-list {
      margin-bottom: 11px; }
      .cart-product__info-descr-list-item {
        margin-bottom: 7px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 20px;
        font-weight: 700;
        font-size: 12px;
        line-height: 160%;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        color: #191919; }
        .cart-product__info-descr-list-item b {
          font-weight: 800;
          font-size: 14px;
          line-height: 160%;
          letter-spacing: 0.06em;
          text-transform: uppercase;
          color: #191919; }
    .cart-product__info-descr-link {
      color: #191919;
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.06em;
      text-transform: uppercase; }
      .cart-product__info-descr-link:hover {
        color: #C19935; }
  .cart-product__info-acc {
    margin-top: 30px; }
    .cart-product__info-acc-list {
      width: 50%; }
      .cart-product__info-acc-list-row {
        margin-bottom: 13px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative; }
        .cart-product__info-acc-list-row:before {
          content: '';
          width: 100%;
          height: 1px;
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 2;
          background: #EDEDED; }
      .cart-product__info-acc-list-item {
        position: relative;
        z-index: 3;
        font-size: 14px;
        line-height: 20px;
        background: #ffffff; }
        .cart-product__info-acc-list-item:first-of-type {
          color: #797979;
          padding-right: 5px; }
        .cart-product__info-acc-list-item:last-of-type {
          padding-left: 5px;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: #191919; }
    .cart-product__info-acc .accordion-item {
      border: none;
      background: transparent;
      border-top: 1px solid #D2D2D2; }
    .cart-product__info-acc .accordion-header {
      border: none;
      background: transparent; }
    .cart-product__info-acc .accordion-button {
      padding: 20px 0;
      position: relative;
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.06em;
      text-transform: uppercase;
      color: #191919;
      background: transparent;
      box-shadow: none; }
      .cart-product__info-acc .accordion-button:after {
        display: none; }
      .cart-product__info-acc .accordion-button:before {
        content: '';
        width: 12px;
        height: 12px;
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        background-repeat: no-repeat;
        background-image: url("../images/svg/arrow3.svg");
        transition: 0.3s; }
      .cart-product__info-acc .accordion-button[aria-expanded="true"]:before {
        transform: rotate(180deg); }
    .cart-product__info-acc .accordion-body {
      padding: 0 0 10px; }
      .cart-product__info-acc .accordion-body p {
        font-size: 18px;
        line-height: 160%;
        color: #A2A2A2; }

.cart-product__top {
  display: flex;
  align-items: flex-end;
  justify-content: space-between; }
  .cart-product__top.mob {
    display: none; }
  .cart-product__top-inf-art {
    padding-right: 16px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: #797979; }
    .cart-product__top-inf-art:before {
      content: '';
      width: 3px;
      height: 3px;
      display: block;
      position: absolute;
      right: 5px;
      top: 10px;
      z-index: 2;
      background: #797979;
      border-radius: 100px; }
  .cart-product__top-inf-nl {
    display: inline-block;
    vertical-align: middle;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: #27AE60; }

.address {
  margin-bottom: 50px;
  margin-top: 35px; }
  .address__info {
    display: flex;
    background-image: url("../images/address-bg.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }
    .address__info-img {
      width: 41%;
      min-height: 400px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover; }
    .address__info-text {
      width: 59%;
      padding: 40px 70px; }
      .address__info-text-ln {
        font-weight: 600;
        font-size: 22px;
        line-height: 140%;
        text-transform: uppercase;
        color: #ffffff; }
        .address__info-text-ln:hover {
          color: #D7BB76; }
      .address__info-text h2 {
        color: #ffffff; }
      .address__info-text-list {
        display: flex;
        margin-top: 50px; }
        .address__info-text-list-col {
          width: 50%; }
        .address__info-text-list-item {
          margin-bottom: 15px; }
        .address__info-text-list-title {
          margin-bottom: 3px;
          font-weight: 600;
          font-size: 18px;
          line-height: 140%;
          color: #ffffff;
          opacity: 0.5; }
        .address__info-text-list-tx {
          font-weight: 600;
          font-size: 18px;
          line-height: 140%;
          color: #ffffff; }
        .address__info-text-list-link {
          font-weight: 600;
          font-size: 18px;
          line-height: 140%;
          color: #ffffff; }
          .address__info-text-list-link:hover {
            color: #D7BB76; }
  .address__tb {
    margin-top: 60px; }
  .address .catalog-page__content-bottom {
    margin-top: 20px;
    margin-bottom: 20px; }
  .address__map {
    margin-top: 60px;
    display: flex; }
    .address__map-address {
      width: 520px;
      padding-right: 20px;
      position: relative; }
      .address__map-address:after {
        content: '';
        width: 100%;
        height: 70px;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 3;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 85.76%); }
      .address__map-address-inner {
        height: 663px;
        overflow: auto; }
      .address__map-address-item {
        padding: 20px 0;
        border-top: 1px solid #D2D2D2; }
        .address__map-address-item:last-of-type {
          padding-bottom: 50px; }
        .address__map-address-item-title {
          margin-bottom: 23px;
          padding: 0 15px;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: 0.06em;
          text-transform: uppercase;
          color: #333333; }
        .address__map-address-item-ad {
          padding: 10px 15px;
          margin-bottom: 10px;
          cursor: pointer; }
          .address__map-address-item-ad.active {
            background: #F6F6F6;
            border-radius: 4px; }
          .address__map-address-item-ad-title {
            margin-bottom: 10px;
            color: #191919;
            font-weight: 700;
            font-size: 18px;
            line-height: 140%; }
          .address__map-address-item-ad-tx {
            margin-bottom: 5px;
            font-size: 14px;
            line-height: 120%;
            color: #797979; }
    .address__map-mp {
      height: 663px;
      position: relative; }
      .address__map-mp #map {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 5; }

.ymaps-2-1-79-balloon__close + .ymaps-2-1-79-balloon__content {
  margin-right: 0 !important;
  padding: 0 !important; }

.ymaps-2-1-79-balloon__close-button {
  display: none !important; }

.ymaps-2-1-79-balloon__content > ymaps {
  width: 300px !important; }

.ymaps-2-1-79-balloon__tail {
  left: 0 !important; }
  .ymaps-2-1-79-balloon__tail:after {
    transform: rotate(102deg); }

.ymaps-2-1-79-balloon {
  top: -430px !important; }

.ballon-img {
  height: 160px;
  margin-bottom: 13px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }

.ballon__title {
  margin-bottom: 5px;
  padding: 0 20px;
  font-size: 14px;
  line-height: 120%;
  color: #000000; }

.ballon__addr {
  height: 42px;
  overflow: hidden;
  margin-bottom: 15px;
  padding: 0 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #191919; }

.ballon__tel, .ballon__time {
  padding: 0 20px;
  font-size: 12px;
  line-height: 170%;
  color: #797979; }

.ballon__btn {
  margin-top: 10px;
  display: block;
  width: 100%;
  padding: 18px 5px;
  border-top: 1px solid #D2D2D2;
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #191919;
  text-transform: uppercase; }
  .ballon__btn:hover {
    color: #D7BB76; }

.enter {
  background-position: center 100%;
  background-size: cover;
  background-repeat: no-repeat; }
  .enter .page-top {
    padding-bottom: 0; }
  .enter + .footer {
    margin-top: 0; }
  .enter__inner {
    padding: 50px 0;
    height: 820px;
    position: relative; }
  .enter__top-tx {
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    color: #797979; }
    .enter__top-tx svg {
      width: 18px;
      height: 18px; }
      .enter__top-tx svg use {
        color: #A2A2A2; }
  .enter__form {
    width: 407px;
    height: 83%;
    display: flex;
    align-items: center; }
    .enter__form .enter__top-tx {
      margin-bottom: 45px; }
    .enter__form hr {
      background: #D2D2D2; }
    .enter__form-check {
      margin-top: 10px; }
      .enter__form-check + button {
        margin-top: 50px; }
      .enter__form-check input[type="checkbox"] {
        display: none; }
        .enter__form-check input[type="checkbox"] + label {
          padding-left: 30px;
          position: relative;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #191919;
          cursor: pointer;
          transition: 0.3s; }
          .enter__form-check input[type="checkbox"] + label a {
            color: #191919; }
            .enter__form-check input[type="checkbox"] + label a:hover {
              color: #D7BB76; }
          .enter__form-check input[type="checkbox"] + label:before {
            content: '';
            width: 20px;
            height: 20px;
            display: block;
            position: absolute;
            top: -1px;
            left: 0;
            z-index: 2;
            border-radius: 3px;
            border: 1px solid #797979;
            transition: 0.3s; }
          .enter__form-check input[type="checkbox"] + label:hover:before {
            border-color: #C19935; }
        .enter__form-check input[type="checkbox"]:checked + label:before {
          background-image: url("../images/svg/check.svg");
          background-repeat: no-repeat;
          background-size: 20px 20px;
          background-position: center;
          background-color: #191919;
          border-color: #191919; }
    .enter__form input {
      margin-bottom: 20px; }
      .enter__form input + hr {
        margin-top: 0; }
    .enter__form .button {
      text-align: center;
      width: 100%; }
    .enter__form-tx {
      margin-top: 20px;
      text-align: center;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: #A2A2A2; }
      .enter__form-tx a {
        color: #191919; }
        .enter__form-tx a:hover {
          color: #D7BB76; }
    .enter__form-pass {
      margin-bottom: 20px;
      text-align: right; }
      .enter__form-pass a {
        font-size: 14px;
        line-height: 120%;
        color: #A2A2A2; }
        .enter__form-pass a:hover {
          color: #D7BB76; }

.cart-in__inner {
  display: flex;
  align-items: flex-start; }

.cart-in__left {
  width: calc(100% - 527px);
  padding-right: 20px; }

.cart-in__all {
  width: 527px; }
  .cart-in__all-inner {
    padding: 45px 40px;
    background: linear-gradient(294.11deg, #E6E6E6 0%, #F7F7F7 56.04%, #E6E6E6 114.47%);
    border-radius: 4px; }
    .cart-in__all-inner .button {
      width: 100%;
      text-align: center; }
  .cart-in__all-promo {
    display: flex;
    align-items: center;
    margin-bottom: 20px; }
    .cart-in__all-promo .button {
      width: 187px;
      background: #D7BB76;
      color: #191919;
      border-radius: 0 4px 4px 0;
      color: #191919 !important; }
      .cart-in__all-promo .button:hover {
        background: #191919;
        color: #ffffff !important; }
  .cart-in__all-price {
    margin-bottom: 15px;
    text-align: right; }
    .cart-in__all-price-title {
      margin-bottom: 5px;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: #797979; }
    .cart-in__all-price-now {
      display: inline-block;
      vertical-align: middle;
      margin-left: 15px;
      font-weight: 600;
      font-size: 34px;
      line-height: 140%;
      color: #191919;
      text-transform: uppercase; }
      .cart-in__all-price-now:after {
        display: inline-block;
        margin-left: 7px;
        font-family: "PT Sans", sans-serif;
        content: "\20cf";
        font-weight: 400; }
    .cart-in__all-price-old {
      display: inline-block;
      vertical-align: middle;
      text-decoration: line-through;
      font-weight: 600;
      font-size: 18px;
      line-height: 140%;
      color: #A9AEB2; }
      .cart-in__all-price-old:after {
        font-weight: 400;
        display: inline-block;
        margin-left: 7px;
        font-family: "PT Sans", sans-serif;
        content: "\20cf"; }

.cart-in__item {
  margin-bottom: 10px;
  padding: 20px 50px 20px 20px;
  display: flex;
  align-items: center;
  border: 1px solid #EDEDED;
  border-radius: 4px; }
  .cart-in__item-close {
    cursor: pointer; }
    .cart-in__item-close svg {
      width: 18px;
      height: 18px; }
      .cart-in__item-close svg use {
        color: #797979; }
    .cart-in__item-close:hover {
      color: #D7BB76; }
  .cart-in__item-img {
    width: 100px;
    height: 100px; }
    .cart-in__item-img img {
      max-width: 100%;
      max-height: 98px; }
  .cart-in__item-info {
    padding: 0 15px 0 20px;
    width: calc(100% - 506px); }
    .cart-in__item-info-tx {
      margin-bottom: 5px;
      font-size: 14px;
      line-height: 120%;
      color: #797979; }
  .cart-in__item-title {
    margin-bottom: 10px;
    display: block;
    font-size: 24px;
    line-height: 130%;
    color: #191919;
    font-family: "Lora", sans-serif; }
    .cart-in__item-title:hover {
      color: #D7BB76; }
  .cart-in__item-price {
    width: 100px;
    text-align: right; }
    .cart-in__item-price-now {
      font-weight: 600;
      font-size: 18px;
      line-height: 140%;
      color: #191919;
      text-transform: uppercase; }
      .cart-in__item-price-now:after {
        display: inline-block;
        margin-left: 7px;
        font-family: "PT Sans", sans-serif;
        content: "\20cf";
        font-weight: 400; }
    .cart-in__item-price-old {
      text-decoration: line-through;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: #A9AEB2; }
      .cart-in__item-price-old:after {
        font-weight: 400;
        display: inline-block;
        margin-left: 7px;
        font-family: "PT Sans", sans-serif;
        content: "\20cf"; }
  .cart-in__item-amount {
    width: 200px;
    position: relative; }
    .cart-in__item-amount-nb {
      font-weight: 600;
      font-size: 18px;
      line-height: 140%;
      color: #191919; }
    .cart-in__item-amount .amount {
      margin: auto;
      width: 140px;
      position: relative;
      background: #F7F7F7; }
    .cart-in__item-amount:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      background: transparent; }
    .cart-in__item-amount input {
      width: 100%;
      height: 50px;
      padding: 5px 40px;
      border: none;
      font-size: 16px;
      line-height: 125%;
      color: #191919;
      background: #F7F7F7;
      border-radius: 2px;
      text-align: center; }
    .cart-in__item-amount .up,
    .cart-in__item-amount .down {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      z-index: 3;
      cursor: pointer;
      transition: 0.3s; }
      .cart-in__item-amount .up:before,
      .cart-in__item-amount .down:before {
        content: '';
        width: 12px;
        height: 2px;
        display: block;
        border-radius: 2px;
        background: #191919; }
      .cart-in__item-amount .up:hover:before, .cart-in__item-amount .up:hover:after,
      .cart-in__item-amount .down:hover:before,
      .cart-in__item-amount .down:hover:after {
        background: #D7BB76; }
    .cart-in__item-amount .down {
      left: 15px; }
    .cart-in__item-amount .up {
      right: 15px; }
      .cart-in__item-amount .up:after {
        content: '';
        width: 2px;
        height: 12px;
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        border-radius: 2px;
        background: #191919; }

.cart-order__total {
  width: 100%;
  border-radius: 4px;
  background: linear-gradient(294.11deg, #E6E6E6 0%, #F7F7F7 56.04%, #E6E6E6 114.47%); }
  .cart-order__total-inner {
    padding: 38px 38px 0; }
  .cart-order__total-ct input {
    height: 50px;
    width: 100%;
    padding: 5px 23px;
    margin-bottom: 13px;
    background: #F7F7F7;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #191919;
    border: none; }
    .cart-order__total-ct input::-webkit-input-placeholder {
      color: rgba(44, 44, 44, 0.5); }
    .cart-order__total-ct input:-ms-input-placeholder {
      color: rgba(44, 44, 44, 0.5); }
    .cart-order__total-ct input::placeholder {
      color: rgba(44, 44, 44, 0.5); }
  .cart-order__total-ct-form .button-bord {
    display: block;
    width: 100%;
    padding: 10px;
    text-transform: inherit; }
  .cart-order__total-ct-btn {
    padding: 20px 0;
    border-top: 2px solid #F7F7F7; }
    .cart-order__total-ct-btn .button {
      width: 100%; }
  .cart-order__total-ct-all {
    margin: 30px 0;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #191919;
    text-align: right; }
    .cart-order__total-ct-all span {
      margin-left: 15px;
      color: #F7F7F7; }
      .cart-order__total-ct-all span:after {
        display: inline-block;
        margin-left: 7px;
        font-family: "Lora", sans-serif;
        content: "\20cf"; }
  .cart-order__total-ct-close {
    position: absolute;
    top: -95px;
    right: 0;
    z-index: 2;
    font-weight: 600;
    font-size: 16px;
    line-height: 136.9%;
    color: #F7F7F7;
    cursor: pointer;
    transition: 0.3s; }
    .cart-order__total-ct-close svg {
      width: 16px;
      height: 16px; }
      .cart-order__total-ct-close svg use {
        color: #F7F7F7; }
    .cart-order__total-ct-close:hover {
      color: #191919; }
      .cart-order__total-ct-close:hover svg use {
        color: #191919; }
  .cart-order__total-item {
    margin-bottom: 23px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between; }
    .cart-order__total-item.all {
      padding: 30px 0 30px;
      margin-top: 20px;
      align-items: center;
      border-top: 1px solid #D2D2D2; }
  .cart-order__total-text {
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    color: #797979; }
    .all .cart-order__total-text {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
      text-align: center;
      letter-spacing: 0.03em;
      text-transform: uppercase; }
  .cart-order__total-price {
    text-align: right; }
    .cart-order__total-price-pr {
      font-weight: 600;
      font-size: 18px;
      line-height: 140%;
      color: #000000; }
      .cart-order__total-price-pr.tw:after {
        display: none; }
      .cart-order__total-price-pr:after {
        display: inline-block;
        margin-left: 7px;
        font-family: "PT Sans", sans-serif;
        content: "\20cf"; }
      .all .cart-order__total-price-pr {
        font-weight: 600;
        font-size: 34px;
        line-height: 140%;
        color: #000000; }
    .cart-order__total-price-min {
      font-size: 14px;
      line-height: 125%;
      color: #191919; }
    .cart-order__total-price-tx {
      font-weight: 600;
      font-size: 18px;
      line-height: 140%;
      color: #A2A2A2;
      margin-left: 11px; }
  .cart-order__total-tt {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #191919; }
    .cart-order__total-tt img {
      margin-right: 13px; }

.ord__inner {
  display: flex;
  align-items: flex-start; }

.ord__left {
  width: calc(100% - 527px); }

.ord__all {
  width: 527px; }
  .ord__all-prod {
    margin-bottom: 30px;
    border: 1px solid #EDEDED;
    border-radius: 4px; }
    .ord__all-prod-promo {
      display: flex;
      align-items: center;
      padding: 15px 35px;
      border-top: 1px solid #EDEDED; }
      .ord__all-prod-promo input {
        border: 1px solid #D2D2D2;
        border-radius: 4px 0 0 4px; }
      .ord__all-prod-promo .button {
        width: 187px;
        background: #D7BB76;
        color: #191919;
        border-radius: 0 4px 4px 0;
        color: #191919 !important; }
        .ord__all-prod-promo .button:hover {
          background: #191919;
          color: #ffffff !important; }
    .ord__all-prod-list {
      padding: 20px 40px; }
    .ord__all-prod-item {
      padding: 15px 0;
      display: flex;
      align-items: flex-start;
      border-bottom: 1px solid #EDEDED; }
      .ord__all-prod-item:last-of-type {
        border: none; }
      .ord__all-prod-item-info {
        padding: 0 22px;
        width: calc(100% - 180px); }
      .ord__all-prod-item-img {
        width: 80px;
        height: 80px; }
        .ord__all-prod-item-img img {
          max-width: 100%;
          max-height: 80px; }
      .ord__all-prod-item-title {
        display: block;
        margin-bottom: 5px;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        color: #191919; }
        .ord__all-prod-item-title:hover {
          color: #D7BB76; }
      .ord__all-prod-item-tx {
        margin-bottom: 10px;
        font-size: 14px;
        line-height: 120%;
        color: #797979; }
      .ord__all-prod-item-sh {
        font-size: 14px;
        line-height: 120%;
        color: #191919; }
      .ord__all-prod-item-price {
        width: 100px;
        text-align: right; }
        .ord__all-prod-item-price-now {
          font-weight: 600;
          font-size: 18px;
          line-height: 140%;
          color: #191919;
          text-transform: uppercase; }
          .ord__all-prod-item-price-now:after {
            display: inline-block;
            margin-left: 7px;
            font-family: "PT Sans", sans-serif;
            content: "\20cf";
            font-weight: 400; }
        .ord__all-prod-item-price-old {
          text-decoration: line-through;
          font-weight: 600;
          font-size: 16px;
          line-height: 140%;
          color: #A9AEB2; }
          .ord__all-prod-item-price-old:after {
            font-weight: 400;
            display: inline-block;
            margin-left: 7px;
            font-family: "PT Sans", sans-serif;
            content: "\20cf"; }
    .ord__all-prod-title {
      padding: 20px 25px;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.06em;
      text-transform: uppercase;
      color: #191919;
      background: linear-gradient(294.11deg, #E6E6E6 0%, #F7F7F7 56.04%, #E6E6E6 114.47%); }

.ord__list {
  padding-right: 20px;
  max-width: 800px; }
  .ord__list .tab-content {
    margin-top: 40px; }
  .ord__list .nav-tabs {
    display: inline-flex;
    vertical-align: middle;
    border: none; }
  .ord__list .nav-item {
    border: none; }
  .ord__list .nav-link {
    margin: 0 10px 0 0;
    border: 1px solid #D2D2D2 !important;
    border-radius: 4px;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    color: #191919; }
    .ord__list .nav-link.active {
      border-color: #191919 !important;
      color: #ffffff;
      background: #191919; }
  .ord__list-item {
    margin-bottom: 35px; }
    .ord__list-item-sr input {
      margin-bottom: 15px; }
    .ord__list-item-sr input, .ord__list-item-sr textarea {
      border: 1px solid #D2D2D2; }
      .ord__list-item-sr input:focus, .ord__list-item-sr textarea:focus {
        border-color: #191919; }
    .ord__list-item-title {
      margin-bottom: 30px;
      font-weight: 600;
      font-size: 18px;
      line-height: 140%;
      color: #191919; }
      .ord__list-item-title-main {
        font-weight: 600;
        font-size: 18px;
        line-height: 140%;
        color: #191919;
        margin-bottom: 0;
        margin-right: 45px;
        display: inline-block;
        vertical-align: middle; }
    .ord__list-item-top .ord__list-item-group {
      display: inline-block;
      vertical-align: middle; }
    .ord__list-item-group-item {
      margin-bottom: 15px; }
      .ord__list-item-top .ord__list-item-group-item {
        margin-bottom: 0;
        display: inline-block;
        vertical-align: middle; }
    .ord__list-item-group-scroll {
      height: 317px;
      overflow: auto; }
    .ord__list-item-group input[type="radio"] {
      display: none; }
      .ord__list-item-group input[type="radio"] + label {
        width: 100%;
        display: block;
        padding: 16px 15px 15px 59px;
        margin-right: 3px;
        position: relative;
        font-weight: 600;
        font-size: 18px;
        line-height: 140%;
        color: #191919;
        border-radius: 4px;
        border: none;
        cursor: pointer; }
        .ord__list-item-group input[type="radio"] + label .title {
          display: block;
          font-weight: 600;
          font-size: 18px;
          line-height: 140%;
          color: #191919; }
        .ord__list-item-group input[type="radio"] + label .tx {
          display: block;
          font-size: 14px;
          line-height: 160%;
          color: #797979; }
        .ord__list-item-top .ord__list-item-group input[type="radio"] + label {
          margin-right: 7px;
          display: inline-block;
          vertical-align: middle;
          padding: 10px 15px;
          font-weight: 600;
          font-size: 18px;
          line-height: 140%;
          color: #191919;
          border: 1px solid #D2D2D2;
          border-radius: 4px; }
          .ord__list-item-top .ord__list-item-group input[type="radio"] + label:before, .ord__list-item-top .ord__list-item-group input[type="radio"] + label:after {
            display: none; }
        .ord__list-item-group input[type="radio"] + label:before {
          content: '';
          width: 20px;
          height: 20px;
          display: block;
          position: absolute;
          top: 17px;
          left: 28px;
          z-index: 2;
          border: 1px solid #D2D2D2;
          border-radius: 100px; }
        .ord__list-item-group input[type="radio"] + label:after {
          content: '';
          width: 10px;
          height: 10px;
          display: block;
          opacity: 0;
          position: absolute;
          top: 22px;
          left: 33px;
          z-index: 2;
          border-radius: 100px;
          background: #191919; }
      .ord__list-item-group input[type="radio"]:checked + label {
        background: #EFE5D9; }
        .ord__list-item-group input[type="radio"]:checked + label:before {
          border-color: #191919; }
        .ord__list-item-group input[type="radio"]:checked + label:after {
          opacity: 1; }
        .ord__list-item-top .ord__list-item-group input[type="radio"]:checked + label {
          background: #191919;
          color: #ffffff; }
    .ord__list-item-inp input {
      margin-bottom: 15px; }
    .ord__list-item-inp input, .ord__list-item-inp textarea {
      border: 1px solid #D2D2D2; }
      .ord__list-item-inp input:focus, .ord__list-item-inp textarea:focus {
        border-color: #191919; }
    .ord__list-item-inp textarea {
      height: 140px; }

.ord__bottom {
  margin-top: 30px; }
  .ord__bottom-check {
    margin-bottom: 30px; }
  .ord__bottom input[type="checkbox"] {
    display: none; }
    .ord__bottom input[type="checkbox"] + label {
      padding-left: 30px;
      position: relative;
      font-size: 14px;
      line-height: 120%;
      color: #A2A2A2;
      cursor: pointer;
      transition: 0.3s; }
      .ord__bottom input[type="checkbox"] + label a {
        color: #191919; }
        .ord__bottom input[type="checkbox"] + label a:hover {
          color: #D7BB76; }
      .ord__bottom input[type="checkbox"] + label:before {
        content: '';
        width: 20px;
        height: 20px;
        display: block;
        position: absolute;
        top: -1px;
        left: 0;
        z-index: 2;
        border-radius: 3px;
        border: 1px solid #797979;
        transition: 0.3s; }
      .ord__bottom input[type="checkbox"] + label:hover:before {
        border-color: #C19935; }
    .ord__bottom input[type="checkbox"]:checked + label:before {
      background-image: url("../images/svg/check.svg");
      background-repeat: no-repeat;
      background-size: 20px 20px;
      background-position: center;
      background-color: #191919;
      border-color: #191919; }

.ord-done {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; }
  .ord-done + .footer {
    margin-top: 0; }
  .ord-done__inner {
    display: flex;
    align-items: center;
    height: 775px; }
  .ord-done__hol {
    width: 350px; }
  .ord-done__item {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: #797979; }
    .ord-done__item span {
      color: #191919; }
  .ord-done .button {
    margin-top: 40px; }

.orders + .footer {
  margin-top: 0; }

.orders__inner {
  display: flex; }
  .orders__inner-holder {
    margin-top: 30px; }
    .orders__inner-holder .button {
      margin-top: 40px; }

.orders__left {
  margin-bottom: 100px;
  width: calc(100% - 345px);
  padding-right: 50px; }
  .orders__left-in {
    width: 76%; }
  .orders__left-ctlg + .orders__left-ctlg {
    margin-top: 85px; }
  .orders__left-ctlg h2 {
    margin-bottom: 40px;
    font-size: 44px;
    line-height: 134%; }
  .orders__left-ctlg-nn {
    margin: 100px 0;
    text-align: center; }
    .orders__left-ctlg-nn-title {
      margin-top: 30px;
      font-size: 16px;
      line-height: 177%;
      color: #191919; }
      .orders__left-ctlg-nn-title a {
        color: #191919;
        font-weight: 500; }
        .orders__left-ctlg-nn-title a:hover {
          color: #D7BB76; }
  .orders__left-bonus .col-sm-6 {
    margin-bottom: 30px; }
  .orders__left-bonus-item {
    height: 100%;
    border: 1px solid #F7F7F7; }
  .orders__left-bonus-in {
    padding: 30px; }
  .orders__left-bonus-img {
    width: 100%;
    height: 111px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; }
  .orders__left-bonus-title {
    font-size: 26px;
    line-height: 120%;
    color: #191919;
    font-family: "PT Sans", sans-serif; }
  .orders__left-bonus-numb {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #D7BB76; }
  .orders__left-bonus-tx {
    margin-top: 16px;
    font-size: 14px;
    line-height: 166%;
    color: #191919; }

.orders__right {
  width: 540px;
  margin-right: calc(-50vw + 817px);
  background: linear-gradient(345.72deg, #F0F0F0 20.19%, #FCFCFC 32.75%, #F0F0F0 50.77%);
  border-radius: 4px; }

.orders__txx {
  font-size: 14px;
  line-height: 120%;
  color: #A2A2A2; }

.orders__itt {
  margin-bottom: 15px;
  padding: 20px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  background: #F7F7F7; }

.orders__title p {
  margin-bottom: 5px;
  font-size: 13px;
  line-height: 140%;
  color: #A2A2A2; }

.orders__title .orders__status {
  margin-bottom: 10px;
  text-transform: inherit;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%; }

.orders__title-main {
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: #191919; }
  .orders__top-big .orders__title-main {
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #191919; }

.orders__title-min {
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #A2A2A2; }
  .orders__top-big .orders__title-min {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: rgba(44, 44, 44, 0.5); }

.orders__item {
  padding: 24px 30px;
  margin-bottom: 10px;
  background: #ffffff;
  border: 1px solid #E0E0E0;
  border-radius: 4px; }
  .orders__item + .button-center {
    margin-top: 30px; }
  .orders__item-bottom {
    display: inline-block;
    padding-left: 28px;
    position: relative;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #191919; }
    .orders__item-bottom img {
      position: absolute;
      left: 0; }
    .orders__item-bottom:hover {
      color: #191919;
      opacity: 0.8; }
  .orders__item-load {
    display: inline-block;
    padding-left: 28px;
    position: relative;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #F7F7F7; }
    .orders__item-load img {
      position: absolute;
      left: 0; }
    .orders__item-load:hover {
      color: #F7F7F7;
      opacity: 0.8; }
  .orders__item-descr {
    padding: 15px 22px;
    margin: 22px 0;
    position: relative;
    background: #F7F7F7;
    border-radius: 4px; }
    .orders__item-descr.th {
      padding-right: 200px; }
    .orders__item-descr-lt-item {
      display: inline-block;
      vertical-align: top;
      padding-right: 40px; }
      .orders__item-descr-lt-item-title {
        margin-bottom: 3px;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: rgba(4, 5, 45, 0.5); }
      .orders__item-descr-lt-item-tx {
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        color: #191919; }
    .orders__item-descr .button-black {
      width: 170px;
      position: absolute;
      top: 62px;
      right: 26px;
      text-transform: capitalize; }
    .orders__item-descr .map {
      height: 195px; }
    .orders__item-descr p {
      margin-bottom: 8px;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: #797979; }
    .orders__item-descr-link {
      display: inline-block;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #191919; }
      .orders__item-descr-link:hover {
        color: #D7BB76; }
    .orders__item-descr-title {
      margin-bottom: 13px;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #797979; }
    .orders__item-descr-count {
      margin-bottom: 5px; }
      .orders__item-descr-count-tit {
        display: inline-block;
        margin-right: 5px;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        color: #191919; }
      .orders__item-descr-count-st {
        display: inline-block;
        padding: 1px 5px;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        color: #ffffff;
        background: #191919;
        border-radius: 4px; }
        .orders__item-descr-count-st.tw {
          background: #C19935; }
    .orders__item-descr-recipient-tx {
      display: inline-block;
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      color: #191919; }
  .orders__item-bt {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between; }

.orders__pages {
  padding: 150px 0 20px; }
  .orders__pages-item {
    display: flex;
    align-items: center;
    min-height: 39px;
    padding: 18px 10px 18px 85px;
    position: relative;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    color: #A2A2A2; }
    .orders__pages-item:last-of-type {
      border: none;
      border-top: 1px solid rgba(0, 0, 0, 0.1); }
    .orders__pages-item svg {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50px;
      margin: auto; }
      .orders__pages-item svg use {
        color: #A2A2A2; }
    .orders__pages-item.active {
      color: #191919; }
      .orders__pages-item.active svg use {
        color: #191919; }
    .orders__pages-item:hover {
      color: #D7BB76; }
      .orders__pages-item:hover svg use {
        color: #D7BB76; }

.orders__form {
  width: 60%; }
  .orders__form-item {
    display: flex;
    align-items: center;
    margin-bottom: 18px; }
    .orders__form-item-bt {
      align-items: flex-start; }
  .orders__form-title {
    width: 275px;
    padding-right: 20px;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    color: #191919; }
  .orders__form-inp {
    width: calc(100% - 285px); }
    .orders__form-inp-gr .orders__form-inp {
      width: 32.333%;
      padding: 0 10px;
      box-sizing: border-box; }
      .orders__form-inp-gr .orders__form-inp:last-of-type {
        padding-right: 0; }
    .orders__form-inp-tt {
      margin-top: 10px;
      font-size: 14px;
      line-height: 120%;
      color: #797979; }
    .orders__form-inp-gr {
      width: calc(100% - 260px);
      margin: 0 -10px;
      display: flex;
      align-items: center; }
    .orders__form-inp input {
      width: 100%;
      padding: 10px 18px;
      border: 1px solid #D2D2D2;
      font-weight: 500;
      font-size: 14px;
      line-height: 166%;
      color: #191919;
      background: transparent; }
      .orders__form-inp input:focus {
        border-color: #191919; }
    .orders__form-inp p {
      margin-top: 20px;
      font-size: 13px;
      line-height: 140%;
      color: #000000; }
  .orders__form-btns {
    display: flex;
    align-items: center;
    margin-top: 42px; }
    .orders__form-btns .button {
      display: inline-flex;
      align-items: center;
      margin-right: 20px;
      text-align: center; }
    .orders__form-btns .button-bord {
      padding: 17px 45px;
      font-weight: 700;
      color: #797979;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.06em;
      text-transform: uppercase; }
      .orders__form-btns .button-bord:hover {
        color: #D7BB76; }

.orders__status {
  display: flex;
  align-items: center;
  text-align: right;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%; }
  .orders__top .orders__status {
    margin-bottom: 20px; }
  .orders__status-success {
    color: #191919;
    font-weight: 700;
    font-size: 18px;
    line-height: 140%; }
    .orders__status-success:before {
      content: '';
      width: 16px;
      height: 16px;
      display: inline-block;
      margin-right: 7px;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("../images/svg/icon-status1.svg");
      background-size: 16px 16px; }
  .orders__status-cancel {
    color: #D7BB76;
    font-weight: 700;
    font-size: 18px;
    line-height: 140%; }
    .orders__status-cancel:before {
      content: '';
      width: 16px;
      height: 16px;
      display: inline-block;
      margin-right: 7px;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("../images/svg/icon-status2.svg");
      background-size: 16px 16px; }

.orders__top {
  margin-top: 50px; }
  .orders__item .orders__top {
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between; }

.orders__bonus {
  max-width: 1075px; }
  .orders__bonus-tx {
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: #797979;
    max-width: 700px; }
  .orders__bonus-item {
    margin-bottom: 20px;
    display: flex;
    align-items: flex-start;
    padding: 35px 40px;
    background: #F7F7F7;
    border-radius: 4px; }
    .orders__bonus-item.act {
      background: linear-gradient(243.47deg, #00352C -32.38%, #28796C 26.43%, #0F6254 49.55%, #00352C 125.21%); }
    .orders__bonus-item-cell {
      width: 30%;
      padding-right: 30px; }
      .orders__bonus-item-cell:first-of-type {
        width: 40%; }
    .orders__bonus-item-tx-title {
      margin-bottom: 15px;
      font-size: 24px;
      line-height: 130%;
      font-family: "Lora", sans-serif;
      color: #A2A2A2; }
      .act .orders__bonus-item-tx-title {
        color: #ffffff; }
    .orders__bonus-item-tx-tt {
      color: #A2A2A2;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%; }
      .act .orders__bonus-item-tx-tt {
        color: rgba(255, 255, 255, 0.5); }
    .orders__bonus-item-inf {
      margin-bottom: 7px;
      font-size: 18px;
      line-height: 140%;
      color: #A2A2A2; }
      .orders__bonus-item-inf span {
        padding: 0 7px; }
      .orders__bonus-item-inf svg {
        width: 20px;
        height: 20px; }
        .orders__bonus-item-inf svg use {
          color: #A2A2A2; }
      .act .orders__bonus-item-inf {
        color: rgba(255, 255, 255, 0.5); }
        .act .orders__bonus-item-inf svg use {
          color: rgba(255, 255, 255, 0.5); }
      .orders__bonus-item-inf.active {
        color: white; }
        .orders__bonus-item-inf.active svg use {
          color: white; }

.orders__ist .cart-in__item-info {
  width: calc(100% - 450px); }

.orders__ist .cart-in__item-price {
  width: 150px;
  text-align: right; }

.orders__ist .cart-in__item-amount {
  text-align: center; }

.orders__ist-bottom {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .orders__ist-bottom-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: #797979; }
  .orders__ist-bottom-right {
    text-align: right; }
  .orders__ist-bottom-price-now {
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
    font-weight: 600;
    font-size: 34px;
    line-height: 140%;
    color: #191919;
    text-transform: uppercase; }
    .orders__ist-bottom-price-now:after {
      display: inline-block;
      margin-left: 7px;
      font-family: "PT Sans", sans-serif;
      content: "\20cf";
      font-weight: 400; }
  .orders__ist-bottom-price-old {
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
    text-decoration: line-through;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    color: #A9AEB2; }
    .orders__ist-bottom-price-old:after {
      font-weight: 400;
      display: inline-block;
      margin-left: 7px;
      font-family: "PT Sans", sans-serif;
      content: "\20cf"; }

.https404 h1 {
  font-size: 150px;
  line-height: 120%; }

.https404 .enter__form-in {
  width: 375px;
  text-align: center; }
  .https404 .enter__form-in .button {
    width: auto; }

.policy__inner {
  max-width: 937px;
  margin: auto; }

.policy__text p {
  font-size: 18px;
  line-height: 180%;
  color: #797979; }

.policy__text ul li {
  padding-left: 20px !important;
  min-height: inherit !important;
  margin-bottom: 0 !important;
  margin-top: 10px !important;
  position: relative;
  font-family: "Nunito", sans-serif;
  font-size: 18px;
  line-height: 180%;
  color: #797979;
  list-style: none; }
  .policy__text ul li:after {
    content: '';
    width: 6px;
    height: 6px;
    display: block;
    position: absolute;
    top: 15px;
    left: 0;
    background: #797979;
    border-radius: 10px; }
  .policy__text ul li:before {
    display: none; }

.policy__text ol {
  counter-reset: myCounter;
  padding-left: 0;
  margin-top: 60px;
  list-style-type: none; }
  .policy__text ol li {
    min-height: 40px;
    margin-bottom: 55px;
    position: relative;
    font-size: 34px;
    line-height: 135%;
    font-family: "Lora", sans-serif;
    color: #191919; }
    .policy__text ol li:before {
      counter-increment: myCounter;
      content: counters(myCounter, ".") ". ";
      font-size: 34px;
      line-height: 135%;
      font-family: "Lora", sans-serif;
      color: #191919; }
    .policy__text ol li ol {
      margin-top: 20px; }
      .policy__text ol li ol li {
        padding-left: 0;
        min-height: 40px;
        margin-bottom: 20px;
        position: relative;
        font-family: "Nunito", sans-serif;
        font-size: 18px;
        line-height: 180%;
        color: #797979; }
        .policy__text ol li ol li:before {
          counter-increment: myCounter;
          content: counters(myCounter, ".") " ";
          font-family: "Nunito", sans-serif;
          font-size: 18px;
          line-height: 180%;
          color: #797979; }
        .policy__text ol li ol li ol {
          margin-top: 15px; }
          .policy__text ol li ol li ol li {
            padding-left: 0;
            min-height: 40px;
            margin-bottom: 10px;
            position: relative;
            font-family: "Nunito", sans-serif;
            font-size: 18px;
            line-height: 180%;
            color: #797979; }
            .policy__text ol li ol li ol li:before {
              counter-increment: myCounter;
              content: counters(myCounter, ".") " ";
              font-family: "Nunito", sans-serif;
              font-size: 18px;
              line-height: 180%;
              color: #797979; }

.modal-dialog {
  max-width: 527px; }
  .w2 .modal-dialog {
    max-width: 662px; }
  .w3 .modal-dialog {
    max-width: 1620px; }

.modal-content {
  background: linear-gradient(294.11deg, #E6E6E6 0%, #F7F7F7 56.04%, #E6E6E6 114.47%);
  border-radius: 4px;
  border: none; }
  .w3 .modal-content {
    background: #ffffff; }

.modal-body {
  padding: 40px 70px 70px;
  position: relative; }
  .w3 .modal-body {
    padding: 0; }
  .modal-body .btn-close {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 41px;
    right: 24px;
    z-index: 2;
    opacity: 1;
    box-shadow: none !important; }
    .modal-body .btn-close svg {
      width: 24px;
      height: 24px; }
      .modal-body .btn-close svg use {
        color: #191919; }
    .modal-body .btn-close:hover svg use {
      color: #D7BB76; }
  .modal-body-form-ord {
    text-align: center; }
    .modal-body-form-ord .modal-body-form-title {
      font-size: 34px;
      line-height: 135%;
      margin-bottom: 30px; }
    .modal-body-form-ord-item {
      margin-bottom: 10px;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: #797979; }
      .modal-body-form-ord-item span {
        color: #191919; }
    .modal-body-form-ord + .button {
      width: 100%;
      margin-top: 20px; }
  .modal-body-form-title {
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 130%;
    color: #191919;
    font-family: "Lora", sans-serif;
    text-align: center; }
  .modal-body-form-tx {
    margin-bottom: 25px;
    font-size: 14px;
    line-height: 120%;
    color: #797979;
    text-align: center; }
    .modal-body-form-tx + .button {
      width: 100%;
      margin-top: 20px; }
  .modal-body-form-tt {
    margin-bottom: 23px;
    font-size: 14px;
    line-height: 120%;
    color: #A2A2A2; }
    .modal-body-form-tt a {
      color: #191919; }
      .modal-body-form-tt a:hover {
        color: #D7BB76; }
  .modal-body-form-inner input {
    margin-bottom: 15px; }
  .modal-body-form-inner .button {
    display: block;
    width: 100%;
    text-align: center; }
  .modal-body-product {
    margin: 30px 0;
    background: #ffffff; }
    .modal-body-product-btns {
      display: flex;
      align-items: center; }
      .modal-body-product-btns .button {
        width: 50%; }
        .modal-body-product-btns .button-link {
          padding: 17px 22px;
          font-size: 14px;
          line-height: 19px;
          text-align: center;
          justify-content: center; }
    .modal-body-product-item {
      padding: 13px;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .modal-body-product-item-price {
        width: 150px;
        text-align: right; }
        .modal-body-product-item-price-now {
          font-weight: 600;
          font-size: 18px;
          line-height: 140%;
          color: #191919; }
          .modal-body-product-item-price-now:after {
            display: inline-block;
            margin-left: 7px;
            font-family: "PT Sans", sans-serif;
            content: "\20cf";
            font-weight: 400; }
        .modal-body-product-item-price-old {
          font-weight: 600;
          font-size: 16px;
          line-height: 140%;
          color: #828282;
          text-decoration: line-through; }
          .modal-body-product-item-price-old:after {
            display: inline-block;
            margin-left: 7px;
            font-family: "PT Sans", sans-serif;
            content: "\20cf";
            font-weight: 400; }
      .modal-body-product-item-img {
        width: 80px;
        height: 80px; }
        .modal-body-product-item-img img {
          max-width: 100%;
          max-height: 80px; }
      .modal-body-product-item-info {
        padding-left: 30px;
        width: calc(100% - 230px); }
      .modal-body-product-item-title {
        display: block;
        margin-bottom: 5px;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        color: #191919; }
        .modal-body-product-item-title:hover {
          color: #D7BB76; }
      .modal-body-product-item-inf {
        font-size: 14px;
        line-height: 120%;
        color: #797979; }

.modal .address__map-address:after {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 85.76%); }

.modal .address__map-address-item-ad {
  padding-right: 165px;
  position: relative; }
  .modal .address__map-address-item-ad-nl {
    width: 165px;
    position: absolute;
    bottom: 40px;
    right: 0;
    font-size: 14px;
    line-height: 120%;
    text-align: right; }
    .modal .address__map-address-item-ad-nl.nn {
      color: #219653; }
    .modal .address__map-address-item-ad-nl.er {
      color: #EB5757; }

.modal .address__tp {
  display: flex;
  align-items: center; }
  .modal .address__tp-title {
    width: 515px;
    font-size: 24px;
    line-height: 130%;
    color: #191919;
    font-family: "Lora", sans-serif; }
  .modal .address__tp-form {
    width: calc(100% - 600px);
    position: relative; }
    .modal .address__tp-form input {
      padding: 3px 20px 3px 50px;
      background: linear-gradient(0deg, #FAF9FA, #FAF9FA), #191919; }
    .modal .address__tp-form .button-search {
      position: absolute;
      top: 14px;
      left: 20px;
      z-index: 2; }
      .modal .address__tp-form .button-search svg {
        width: 20px;
        height: 20px; }
        .modal .address__tp-form .button-search svg use {
          color: #191919; }
      .modal .address__tp-form .button-search:hover svg use {
        color: #D7BB76; }

.slider-dots-box {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 35px;
  right: 1525px;
  left: 0;
  z-index: 10; }

.slider-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px; }

.slider-dots button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: block;
  border: 0;
  background-color: transparent;
  margin: 0 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 0;
  transition: .3s all;
  position: relative;
  z-index: 0; }

.slider-dots-box .slick-active button {
  width: 40px;
  height: 40px;
  margin: 0; }

.slider-dots-box .slider-dots li {
  width: 40px;
  height: 40px;
  position: relative; }

.slider-dots-box .slider-dots li:before {
  content: '';
  width: 40px;
  height: 40px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 150px;
  opacity: 0; }

.slider-dots-box .slider-dots li.slick-active:before {
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 150px;
  opacity: 1; }

.slider-dots-box .slider-dots li:after {
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: -1;
  background: #000000;
  opacity: 0.2;
  border-radius: 50%; }

.slider-dots-box .slider-dots li.slick-active:after {
  opacity: 1; }

.circle-bg {
  fill: rgba(255, 255, 255, 0);
  stroke: rgba(0, 0, 0, 0.05);
  stroke-width: 2;
  stroke-linecap: butt; }

.circle-go {
  fill: rgba(255, 255, 255, 0);
  stroke: #000000;
  stroke-width: 2;
  stroke-linecap: round;
  animation: progress 22s;
  stroke-dasharray: 360px;
  stroke-dashoffset: 360; }

.circle-tx {
  fill: #000;
  stroke-width: 1;
  font: bold 16px 'Arial';
  text-anchor: middle; }

@keyframes progress {
  from {
    stroke-dashoffset: 360; }
  to {
    stroke-dashoffset: 0; } }

.banner__inner {
  position: relative; }

@media screen and (min-width: 991px) {
  .address .nav-tabs {
    display: none; }
  .address .tab-content {
    display: flex;
    align-items: flex-start; }
  .address .tab-pane {
    display: block !important;
    opacity: 1 !important; }
    .address .tab-pane:first-of-type {
      order: 2;
      width: calc(100% - 520px);
      height: 663px; }
    .address .tab-pane:last-of-type {
      width: 520px;
      order: 1; } }

@media screen and (max-width: 1670px) {
  .orders__right {
    margin-right: -12px; }
  .orders__form {
    width: 80%; }
  .slider-dots-box {
    left: 15px;
    right: inherit; }
  .banner__inner .pagingInfo {
    right: 55px;
    left: inherit; }
  .banner__slider .slick-next {
    right: 15px;
    left: inherit; }
  .banner__slider .slick-prev {
    right: 125px;
    left: inherit; } }

@media screen and (max-width: 1600px) {
  .banner-form__inner {
    padding-right: 30px; }
  .banner-form__text {
    width: 40%;
    margin-right: 20px; } }

@media screen and (min-width: 1400px) {
  .col-xl-2-1 {
    width: 20% !important; } }

@media screen and (max-width: 1400px) {
  .header-mid__group-item {
    margin-right: 10px; }
  .header-mid__group-item.sr {
    margin-left: 10px; }
  .banner__slider-img {
    position: relative;
    left: -19%; }
  .banner__slider-holder {
    overflow: hidden; }
  .blog-item__text {
    padding-right: 40px; }
  .cart-product__slider {
    width: 600px; }
    .cart-product__slider-for {
      width: 400px; }
  .cart-product__info {
    width: calc(100% - 600px); }
    .cart-product__info-btns .button {
      margin-right: 10px; }
  .cart-in__left {
    width: calc(100% - 350px); }
  .cart-in__all {
    width: 350px; }
    .cart-in__all-inner {
      padding: 30px; }
    .cart-in__all-promo {
      display: block; }
      .cart-in__all-promo .button {
        width: 100%;
        margin-top: 5px;
        text-align: center;
        border-radius: 4px; }
    .cart-in__all-price-now {
      display: block; }
    .cart-in__all-price-old {
      display: block; }
  .orders__bonus-item {
    flex-wrap: wrap; }
  .orders__bonus-item-cell {
    width: 50%;
    flex: 0 0 50%;
    max-width: 50%; }
    .orders__bonus-item-cell:first-of-type {
      margin-bottom: 30px;
      width: 100%;
      flex: 0 0 100%;
      max-width: 100%; }
  .orders__ist .cart-in__item-amount {
    width: 50px; }
  .orders__ist .cart-in__item-price {
    width: 110px; }
  .orders__ist .cart-in__item-info {
    width: calc(100% - 240px); } }

@media screen and (max-width: 1300px) {
  .banner-form__inner {
    background-position: 30% center; }
  .row-right .blog__text {
    padding-left: 0; }
  .banner-form__holder {
    padding: 30px 30px 30px 50px; }
    .banner-form__holder:before {
      width: 500px;
      height: 500px;
      background-size: contain;
      background-position: center bottom; }
  .banner-form.tw .banner-form__text {
    width: 700px; }
  .orders__form {
    width: 100%; } }

@media screen and (max-width: 1200px) {
  .header-mid__group-search-block-list-item {
    max-width: 50%;
    flex: 0 0 50%; }
  .header__logo {
    width: 230px; }
    .header__logo img {
      max-width: 100%; }
  .header-mid__group {
    width: 37%; }
    .header-mid__group-item span {
      font-size: 12px; }
    .header-mid__group-item svg {
      margin-right: 3px; }
    .header-mid__group-search-block {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
      top: 57px;
      background: #ffffff; }
      .header-mid__group-search-block-inp {
        background: transparent; }
        .header-mid__group-search-block-inp-inner {
          padding: 0;
          margin: 15px 0;
          background: #FAF9FA; }
          .header-mid__group-search-block-inp-inner input {
            height: 56px;
            padding-left: 60px;
            font-size: 14px;
            line-height: 19px; }
          .header-mid__group-search-block-inp-inner .button-search {
            left: 20px;
            top: 14px; }
      .header-mid__group-search-block-list {
        padding: 0 0 15px; }
        .header-mid__group-search-block-list-in {
          display: block; }
        .header-mid__group-search-block-list-item {
          max-width: 100%;
          flex: 0 0 100%;
          margin-bottom: 10px; }
        .header-mid__group-search-block-list-tt {
          display: none; }
        .header-mid__group-search-block-list-left {
          width: 100%;
          padding: 0 0 6px;
          border-bottom: 1px solid #D2D2D2; }
          .header-mid__group-search-block-list-left ul li {
            margin-bottom: 13px; }
        .header-mid__group-search-block-list-inner {
          display: block; }
        .header-mid__group-search-block-list-prod {
          padding-left: 0;
          width: 100%; }
  .header-top__menu ul li a {
    font-size: 12px; }
  .services__item-title {
    font-size: 16px; }
  .banner-form__text {
    width: 40%; }
    .banner-form__text-in {
      display: block; }
      .banner-form__text-in input {
        margin-bottom: 20px; }
      .banner-form__text-in .button {
        width: 100%; }
  .banner-ab__text {
    padding-top: 140px; }
  .footer__text {
    width: 250px; }
  .footer__logo img {
    max-width: 100%; }
  .footer__menu {
    flex-wrap: wrap; }
  .footer__item {
    width: 50%;
    margin-bottom: 20px; }
  .blog-item__text {
    width: calc(100% - 350px);
    padding-right: 40px; }
  .blog-item__form {
    width: 350px; }
  .cart-product__slider {
    width: 500px; }
    .cart-product__slider-for {
      width: 300px; }
  .cart-product__info {
    width: calc(100% - 500px); }
    .cart-product__info-form {
      display: block; }
      .cart-product__info-form input {
        width: 100%;
        margin-bottom: 10px; }
    .cart-product__info-acc-list {
      width: 100%; }
  .banner-form.tw .banner-form__text {
    width: 550px; }
  .enter__inner {
    background-position: 191px center; }
  .cart-in__inner {
    flex-wrap: wrap; }
  .cart-in__left {
    width: 100%;
    padding-right: 0; }
  .cart-in__all {
    width: 600px; }
    .cart-in__all-promo {
      display: flex; }
      .cart-in__all-promo .button {
        margin-top: 0;
        width: 187px; }
    .cart-in__all-price-old {
      display: inline-block;
      vertical-align: middle; }
    .cart-in__all-price-now {
      display: inline-block;
      vertical-align: middle; }
  .orders__ist-bottom-price-old {
    display: block;
    margin-right: 0; } }

@media screen and (max-width: 1100px) {
  .soc-l {
    display: none; } }

@media screen and (max-width: 991px) {
  .slider-dots-box {
    bottom: 20px; }
    .slider-dots-box .slider-dots {
      min-height: inherit; }
      .slider-dots-box .slider-dots li {
        width: 6px;
        height: 6px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 8px; }
        .slider-dots-box .slider-dots li:after {
          background: #ffffff; }
        .slider-dots-box .slider-dots li button {
          display: none; }
        .slider-dots-box .slider-dots li:before {
          display: none; }
  h1 {
    font-size: 28px;
    line-height: 130%; }
    h1 span {
      font-size: 12px;
      line-height: 130%; }
  h2 {
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 130%; }
  .page-top {
    padding: 33px 0 15px; }
  .data {
    font-size: 10px;
    padding: 6px 8px; }
  .button-primary {
    padding: 14px 30px;
    font-size: 14px;
    line-height: 19px;
    text-align: center; }
  .title-main {
    margin-bottom: 10px; }
    .title-main .button-link {
      width: 100%;
      padding: 15px;
      position: absolute;
      bottom: 0;
      font-weight: 700;
      background: #EDEDED;
      border-radius: 4px;
      text-align: center;
      justify-content: center; }
  .header {
    display: none; }
    .header-mob {
      display: block; }
      .header-mob-top {
        position: relative;
        z-index: 100;
        padding: 12px 0;
        background: #ffffff;
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05); }
        .header-mob-top-inner {
          display: flex;
          align-items: center;
          justify-content: space-between; }
        .header-mob-top-logo {
          display: inline-block;
          width: 177px; }
          .header-mob-top-logo img {
            max-width: 100%; }
        .header-mob-top-link {
          width: 20px;
          height: 20px;
          display: inline-block;
          vertical-align: middle;
          margin-left: 15px; }
          .header-mob-top-link svg {
            width: 20px;
            height: 20px; }
            .header-mob-top-link svg use {
              color: #191919; }
        .header-mob-top-toggler {
          width: 20px;
          height: 20px;
          display: inline-block;
          vertical-align: middle;
          margin-left: 12px; }
          .header-mob-top-toggler svg {
            width: 20px;
            height: 20px; }
            .header-mob-top-toggler svg use {
              color: #191919; }
          .header-mob-top-toggler.open {
            background-image: url("../images/svg/close.svg"); }
            .header-mob-top-toggler.open svg {
              opacity: 0; }
      .header-mob-all {
        display: none;
        padding: 90px 0 140px;
        width: 100%;
        height: 100vh;
        min-height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99;
        background: #ffffff;
        overflow: auto; }
        .header-mob-all.open {
          display: block; }
        .header-mob-all-top {
          padding: 0 15px;
          margin-bottom: 25px; }
          .header-mob-all-top .header-mid__group-item {
            display: flex;
            align-items: center;
            margin-right: 0;
            margin-bottom: 19px; }
            .header-mob-all-top .header-mid__group-item span {
              font-weight: 600;
              font-size: 14px;
              line-height: 19px; }
            .header-mob-all-top .header-mid__group-item svg {
              margin-right: 8px; }
        .header-mob-all-menu {
          padding: 20px 15px;
          background: #F7F7F7; }
          .header-mob-all-menu ul li {
            margin-bottom: 10px; }
            .header-mob-all-menu ul li:last-of-type {
              margin-bottom: 0; }
            .header-mob-all-menu ul li a {
              font-weight: 700;
              font-size: 14px;
              line-height: 19px;
              color: #191919;
              letter-spacing: 0.06em;
              text-transform: uppercase; }
              .header-mob-all-menu ul li a:hover {
                color: #D7BB76; }
        .header-mob-all-od {
          padding: 18px 15px 10px;
          border-bottom: 1px solid #D2D2D2; }
          .header-mob-all-od ul li {
            margin-bottom: 10px; }
            .header-mob-all-od ul li a {
              color: #797979;
              font-weight: 600;
              font-size: 14px;
              line-height: 19px;
              letter-spacing: 0.06em;
              text-transform: uppercase; }
        .header-mob-all-info {
          padding: 18px 15px; }
          .header-mob-all-info-tel {
            margin-bottom: 5px;
            display: inline-block;
            font-weight: 700;
            font-size: 18px;
            line-height: 25px;
            letter-spacing: 0.06em;
            text-transform: uppercase;
            color: #191919; }
          .header-mob-all-info-time {
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            color: #A2A2A2;
            letter-spacing: 0.06em;
            text-transform: uppercase; }
  .banner__inner .pagingInfo {
    display: none; }
  .banner__slider-inner .container {
    padding: 0; }
  .banner__slider-holder {
    height: 480px;
    padding: 0 15px;
    align-items: flex-end;
    position: relative; }
    .banner__slider-holder:before {
      content: '';
      width: 100%;
      height: 102px;
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 2;
      background: linear-gradient(77.71deg, #6D513E 26.09%, #AA7D63 55.53%);
      filter: blur(65px); }
  .banner__slider-img {
    width: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    left: inherit; }
  .banner__slider-tx {
    padding: 0 0 55px;
    width: 100%;
    position: relative;
    z-index: 3; }
    .banner__slider-tx p {
      margin-bottom: 0;
      font-weight: 600;
      font-size: 14px;
      line-height: 160%;
      color: #ffffff; }
      .banner__slider-tx p br {
        display: none; }
    .banner__slider-tx-title {
      margin-bottom: 10px;
      font-size: 28px;
      line-height: 130%;
      color: #ffffff; }
    .banner__slider-tx .button {
      display: none; }
  .banner-min {
    margin: 20px 0; }
    .banner-min__item {
      margin-bottom: 10px;
      height: 130px;
      padding: 15px 20px; }
      .banner-min__item-title {
        font-size: 18px;
        line-height: 130%; }
      .banner-min__item-link {
        bottom: 15px;
        font-weight: 600;
        font-size: 12px;
        line-height: 140%;
        left: 20px; }
        .banner-min__item-link svg {
          width: 12px;
          height: 12px; }
  .banner-info {
    margin: 40px 0 20px; }
    .banner-info__inner {
      margin: 0 -12px;
      display: block; }
    .banner-info__img {
      height: 348px;
      width: 100%;
      min-height: inherit;
      background-position: center top; }
      .banner-info__img-m {
        display: none; }
    .banner-info__text {
      padding: 30px 15px 50px;
      width: 100%;
      margin: 0; }
      .banner-info__text-min {
        margin-bottom: 5px;
        font-size: 8px;
        line-height: 11px; }
      .banner-info__text p {
        margin-bottom: 30px;
        font-size: 14px;
        line-height: 160%; }
      .banner-info__text .button {
        width: 100%; }
  .banner-rek {
    margin: 30px 0; }
    .banner-rek + .brands {
      margin-top: -30px; }
    .banner-rek__inner {
      height: 446px;
      margin: 0 -12px;
      padding: 20px 15px;
      display: flex;
      align-items: flex-end; }
    .banner-rek__tx {
      width: 100%;
      margin-bottom: 20px; }
      .banner-rek__tx-min {
        margin-bottom: 5px;
        font-size: 8px;
        line-height: 11px;
        color: #ffffff; }
      .banner-rek__tx h2 {
        margin-bottom: 20px;
        color: #ffffff; }
      .banner-rek__tx .button {
        width: 100%; }
  .banner-nav {
    margin: 0; }
    .banner-nav .container {
      padding: 0; }
    .banner-nav__inner {
      flex-wrap: wrap; }
    .banner-nav__left {
      display: none; }
    .banner-nav__text {
      padding: 30px 15px 45px;
      width: 100%;
      order: 2; }
      .banner-nav__text-min {
        margin-bottom: 5px;
        font-size: 8px;
        line-height: 11px; }
      .banner-nav__text p {
        margin-bottom: 30px;
        font-size: 14px;
        line-height: 160%; }
      .banner-nav__text .button {
        width: 100%; }
    .banner-nav__right {
      height: 285px;
      min-height: inherit;
      width: 100%;
      order: 1; }
  .banner-ct {
    margin: 0; }
    .banner-ct__inner {
      flex-wrap: wrap; }
    .banner-ct__text {
      padding: 30px 0 45px;
      width: 100%;
      order: 2; }
      .banner-ct__text-min {
        margin-bottom: 5px;
        font-size: 8px;
        line-height: 11px; }
      .banner-ct__text p {
        margin-bottom: 30px;
        font-size: 14px;
        line-height: 160%; }
      .banner-ct__text .button {
        width: 100%; }
  .banner-ab {
    margin: 0; }
    .banner-ab .container {
      padding: 0; }
    .banner-ab__inner {
      display: flex;
      align-items: flex-end;
      height: 520px;
      padding: 0 15px;
      position: relative;
      background-position: center right; }
    .banner-ab__img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0; }
    .banner-ab__text {
      padding: 45px 0;
      width: 100%;
      position: relative;
      z-index: 4; }
      .banner-ab__text img {
        display: none; }
      .banner-ab__text-min {
        margin-bottom: 5px;
        font-size: 8px;
        line-height: 11px;
        color: #ffffff; }
      .banner-ab__text-in {
        padding: 0; }
      .banner-ab__text h2 {
        color: #ffffff; }
      .banner-ab__text p {
        margin-bottom: 30px;
        font-size: 14px;
        line-height: 160%;
        color: #ffffff; }
      .banner-ab__text .button {
        width: 100%; }
  .banner-form {
    margin: 0; }
    .banner-form .container {
      padding: 0; }
    .banner-form__inner {
      padding-bottom: 200px;
      padding-right: 0;
      flex-wrap: wrap;
      min-height: inherit;
      background-image: url("../images/banner-form-bg-mob.jpg") !important; }
    .banner-form__img {
      min-height: inherit;
      width: 100%;
      order: 2;
      text-align: center; }
      .banner-form__img img {
        max-width: 500px;
        margin: auto;
        position: relative;
        left: 0; }
    .banner-form__text {
      width: 100%;
      order: 1;
      padding: 30px 15px; }
      .banner-form__text p {
        font-size: 14px;
        line-height: 160%; }
      .banner-form__text-in {
        margin-top: 20px; }
        .banner-form__text-in input {
          width: 100%;
          max-width: 100%;
          margin-right: 0; }
    .banner-form__holder {
      padding: 15px; }
      .banner-form__holder-bottom {
        display: block; }
        .banner-form__holder-bottom .form__tx {
          width: 100% !important; }
      .banner-form__holder:before {
        display: none; }
      .banner-form__holder p {
        font-size: 14px !important; }
    .banner-form.tw {
      margin-top: 20px; }
      .banner-form.tw .banner-form__text {
        width: 100%; }
  .banner-dop__text {
    width: 100%;
    margin-left: 0;
    margin-bottom: 13px; }
    .banner-dop__text h2 {
      margin-bottom: 10px; }
    .banner-dop__text p {
      font-size: 14px;
      line-height: 160%; }
  .banner-dop__inner {
    height: 385px;
    align-items: flex-end; }
  .catalog-min {
    margin: 20px 0; }
    .catalog-min__inner {
      padding-bottom: 65px;
      position: relative; }
    .catalog-min .slick-list, .catalog-min .slick-track {
      width: 100% !important;
      transform: none !important; }
    .catalog-min .slick-track {
      display: flex;
      flex-wrap: wrap; }
    .catalog-min .slick-arrow {
      display: none !important; }
    .catalog-min .slick-slide {
      max-width: 50%;
      flex: 0 0 50%;
      width: 50% !important; }
    .catalog-min .slick-slide {
      display: none; }
      .catalog-min .slick-slide:nth-of-type(1), .catalog-min .slick-slide:nth-of-type(2), .catalog-min .slick-slide:nth-of-type(3), .catalog-min .slick-slide:nth-of-type(4) {
        display: block; }
  .catalog__item {
    margin-bottom: 10px; }
    .catalog__item-img {
      height: 165px; }
      .catalog__item-img img {
        max-height: 160px;
        width: auto;
        margin: auto; }
    .catalog__item-labels-item {
      padding: 1px 4px;
      margin-right: 1px;
      font-size: 8px;
      line-height: 11px; }
    .catalog__item-title {
      font-size: 14px;
      line-height: 140%; }
    .catalog__item-price-now {
      font-size: 16px;
      line-height: 140%; }
    .catalog__item-price-old {
      font-size: 12px;
      line-height: 150%; }
    .catalog__item-fav svg {
      width: 18px;
      height: 18px; }
  .catalog-page__mob {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .catalog-page__mob-sort {
      width: 200px; }
  .catalog-page__inner {
    display: block;
    padding-top: 22px; }
  .catalog-page__left {
    width: 100%; }
  .catalog-page__content {
    width: 100%;
    padding-left: 0; }
    .catalog-page__content h1 {
      margin-top: 0; }
    .catalog-page__content-tx-inner p {
      font-size: 14px;
      line-height: 20px; }
  .catalog-page__tags {
    display: none; }
  .catalog-page__sorting {
    display: none; }
  .catalog-page__list .col-sm-6 {
    width: 50%; }
  .brands {
    margin: 0;
    padding: 30px 0; }
    .brands .container {
      position: relative; }
    .brands .button-link {
      width: 97%; }
    .brands__inner {
      padding-bottom: 25px;
      position: relative; }
    .brands__item {
      margin-bottom: 10px;
      height: 102px;
      flex: 0 0 50%;
      max-width: 50%;
      padding: 0 20px;
      display: none;
      background: transparent !important;
      box-shadow: none !important; }
      .brands__item:nth-of-type(1), .brands__item:nth-of-type(2), .brands__item:nth-of-type(3), .brands__item:nth-of-type(4), .brands__item:nth-of-type(5), .brands__item:nth-of-type(6) {
        display: flex; }
      .brands__item:after {
        display: none; }
      .brands__item-img {
        opacity: 1 !important; }
        .brands__item-img-hov {
          display: none; }
        .brands__item-img img {
          max-height: 100px; }
    .brands-page {
      padding-bottom: 30px; }
      .brands-page .brands__item {
        display: flex !important; }
  .services {
    margin: 30px 0; }
    .services .container {
      padding-bottom: 80px;
      position: relative; }
    .services__item {
      margin-bottom: 10px; }
      .services__item-title {
        margin-top: 10px;
        font-size: 12px;
        line-height: 130%; }
      .services__item-img {
        height: 165px; }
    .services__text h2 {
      margin-bottom: 10px; }
    .services__text-min {
      font-size: 8px;
      line-height: 11px; }
    .services__text p {
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 160%; }
    .services__text .button {
      width: 98%;
      margin: auto;
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0; }
  .blog {
    margin-bottom: 40px; }
    .blog .row {
      margin-bottom: 40px; }
      .blog .row-right .col-md-12:first-of-type {
        order: 2; }
      .blog .row-right .col-md-12:last-of-type {
        order: 1; }
    .blog__text {
      margin-bottom: 30px; }
      .blog__text p {
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 20px; }
    .blog__img {
      height: 250px; }
    .blog-bottom-title {
      font-size: 24px;
      line-height: 30px; }
    .blog-bottom p {
      font-size: 14px;
      line-height: 20px; }
    .blog-slider {
      margin: 40px 0 0;
      padding: 30px 0 50px; }
      .blog-slider .container {
        position: relative;
        padding-bottom: 56px; }
        .blog-slider .container .button-link {
          width: 97%;
          color: #191919; }
          .blog-slider .container .button-link svg use {
            color: #191919; }
    .blog__item {
      margin-bottom: 10px;
      height: 150px;
      padding: 20px 25px; }
      .blog__item-data {
        padding: 5px 8px;
        margin-bottom: 10px;
        font-size: 8px;
        line-height: 11px; }
      .blog__item-title {
        height: 61px;
        font-size: 16px;
        line-height: 130%; }
      .blog__item-link {
        font-size: 12px;
        line-height: 140%;
        left: 25px;
        bottom: 15px; }
        .blog__item-link svg {
          width: 12px;
          height: 12px; }
    .blog-item {
      margin-bottom: 40px; }
      .blog-item__form {
        padding: 20px 15px;
        margin-top: 40px;
        width: 100%; }
      .blog-item__inner {
        margin-top: 0;
        display: block; }
      .blog-item-banner {
        height: 300px; }
      .blog-item__text {
        width: 100%;
        padding-right: 0; }
    .blog-page {
      padding-bottom: 30px; }
  .footer {
    margin-top: 0; }
    .footer__main {
      padding: 25px 0 15px; }
      .footer__main-inner {
        display: block; }
    .footer__text {
      width: 100%;
      margin-bottom: 25px; }
      .footer__text p {
        font-size: 14px;
        line-height: 160%; }
    .footer__logo {
      margin-bottom: 15px;
      width: 250px; }
    .footer__menu {
      width: 100%;
      padding-top: 0; }
    .footer__call {
      position: absolute;
      top: 38px;
      right: 0; }
    .footer__time {
      width: 100%;
      margin-top: 20px; }
    .footer__inst {
      padding-bottom: 15px;
      border-bottom: 1px solid #5E5E5E; }
    .footer__bottom {
      padding-bottom: 30px;
      border: none; }
      .footer__bottom-inner {
        display: block;
        padding-top: 55px;
        position: relative; }
      .footer__bottom-tx {
        margin-right: 10px;
        display: inline-block; }
      .footer__bottom-links {
        position: absolute;
        top: 0;
        left: 0; }
        .footer__bottom-links a {
          display: block;
          margin: 0; }
    .footer__dev {
      display: inline-block; }
    .footer__item {
      width: 100% !important;
      padding: 0;
      position: relative;
      border-bottom: 1px solid #5E5E5E; }
      .footer__item:last-of-type {
        border: none; }
      .footer__item.open ul {
        display: block;
        margin-bottom: 20px; }
      .footer__item-title {
        position: relative; }
        .footer__item-title svg {
          display: block;
          width: 20px;
          height: 20px;
          transform: rotate(90deg);
          position: absolute;
          top: 0;
          right: 0;
          transition: 0.3s; }
          .footer__item-title svg use {
            color: #797979; }
        .open .footer__item-title svg {
          transform: rotate(270deg); }
      .footer__item ul {
        display: none; }
  .filter {
    padding: 66px 20px;
    display: none;
    width: 100%;
    height: 100vh;
    min-height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 98;
    background: #ffffff;
    overflow: auto; }
    .filter.op {
      display: block; }
    .filter__total {
      display: none; }
    .filter__top-tt {
      padding-bottom: 0;
      display: block;
      font-size: 28px;
      line-height: 130%;
      color: #191919;
      font-family: "Lora", sans-serif;
      font-weight: 400;
      text-transform: inherit; }
    .filter__top-close {
      display: block;
      position: absolute;
      right: 20px;
      top: 86px; }
  .pagination .page-item {
    margin: 0; }
    .pagination .page-item.prev, .pagination .page-item.next {
      display: none !important; }
  .pagination .page-link {
    width: 40px !important;
    height: 40px !important;
    font-size: 16px !important; }
  .text blockquote {
    font-size: 14px;
    line-height: 20px; }
  .text p {
    font-size: 14px;
    line-height: 20px; }
  .text h2 {
    margin-top: 25px; }
  .text h3 {
    font-size: 20px; }
  .text ol li {
    padding-left: 20px;
    font-size: 14px;
    line-height: 20px; }
    .text ol li:before {
      font-size: 14px;
      line-height: 20px; }
  .text__slider {
    margin: 20px 0; }
    .text__slider-inner {
      height: 350px; }
    .text__slider .pagingInfo {
      width: 40px;
      bottom: 25px; }
      .text__slider .pagingInfo .numb-prev, .text__slider .pagingInfo .numb-next {
        font-size: 15px !important; }
        .text__slider .pagingInfo .numb-prev:before, .text__slider .pagingInfo .numb-next:before {
          font-size: 15px !important; }
  .text__acc {
    margin: 15px 0; }
    .text__acc .accordion-button {
      font-size: 18px;
      line-height: 20px; }
    .text__acc .accordion-body p {
      font-size: 14px;
      line-height: 20px; }
  .text__table table tr th, .text__table table tr td {
    padding: 10px;
    font-size: 14px;
    line-height: 20px; }
  .actions-item__pages {
    margin-top: 30px; }
  .delivery {
    margin-top: 20px; }
    .delivery-title {
      font-size: 20px; }
    .delivery p {
      font-size: 14px;
      list-style: 20px; }
      .delivery p + .form__title {
        margin-top: 10px; }
  .cart-product__inner {
    display: block; }
  .cart-product__info {
    width: 100%; }
    .cart-product__info-price {
      display: none;
      margin-top: 10px; }
      .cart-product__info-price.mob {
        display: block; }
      .cart-product__info-price-now {
        margin-right: 10px;
        font-size: 26px;
        line-height: 140%; }
      .cart-product__info-price-old {
        font-size: 16px;
        line-height: 140%; }
    .cart-product__info-fav {
      display: none; }
    .cart-product__info-all {
      margin-top: 20px; }
    .cart-product__info-form {
      margin-top: 37px; }
      .cart-product__info-form .button {
        width: 100%;
        text-align: center; }
    .cart-product__info-descr {
      margin-top: 20px;
      padding: 13px 18px; }
      .cart-product__info-descr p {
        font-size: 12px;
        line-height: 140%; }
      .cart-product__info-descr-list-item {
        font-size: 10px;
        line-height: 160%; }
      .cart-product__info-descr-link {
        font-size: 12px;
        line-height: 16px; }
    .cart-product__info-acc .accordion-body p {
      font-size: 14px;
      line-height: 20px; }
    .cart-product__info-acc-list-row:before {
      bottom: 4px; }
    .cart-product__info-acc-list-item {
      font-size: 12px;
      line-height: 150%; }
      .cart-product__info-acc-list-item:last-of-type {
        font-size: 16px;
        line-height: 140%; }
    .cart-product__info-btns .button {
      width: 100%; }
    .cart-product__info-btns-link {
      margin-top: 15px;
      width: 100%;
      display: block;
      text-align: center; }
    .cart-product__info-size {
      position: relative; }
      .cart-product__info-size-tx {
        font-size: 16px;
        line-height: 140%; }
      .cart-product__info-size-title {
        margin-bottom: 10px;
        font-size: 14px;
        line-height: 160%; }
        .cart-product__info-size-title-dow {
          width: 220px;
          bottom: 43px;
          left: 61px; }
      .cart-product__info-size-link {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        font-size: 14px;
        line-height: 160%; }
      .cart-product__info-size-list {
        margin-right: 0; }
        .cart-product__info-size-list-item input[type="radio"] + label {
          font-size: 14px;
          line-height: 160%; }
  .cart-product__slider {
    width: 100%;
    height: 370px;
    position: relative; }
    .cart-product__slider .catalog__item-labels {
      display: block;
      left: 0;
      top: 20px; }
    .cart-product__slider .catalog__item-fav {
      height: 18px;
      display: block;
      bottom: 0;
      top: auto; }
      .cart-product__slider .catalog__item-fav svg {
        width: 24px;
        height: 24px; }
    .cart-product__slider-nav {
      display: none !important; }
    .cart-product__slider-for {
      height: 370px;
      width: 100%;
      margin-left: 0; }
      .cart-product__slider-for-item {
        padding: 10px;
        height: 370px !important; }
  .cart-product__top {
    display: none;
    margin-top: 30px; }
    .cart-product__top.mob {
      display: block; }
    .cart-product__top-inf {
      margin-top: 10px; }
      .cart-product__top-inf-art, .cart-product__top-inf-nl {
        font-size: 14px;
        line-height: 160%; }
  .address {
    margin-bottom: 0; }
    .address .container {
      position: relative; }
    .address .tab-content > .active {
      opacity: 1; }
    .address .tab-pane {
      opacity: 0; }
    .address__info {
      display: block; }
      .address__info-img {
        width: 100%;
        height: 225px;
        min-height: inherit; }
      .address__info-text {
        width: 100%;
        padding: 30px 20px; }
        .address__info-text-list {
          display: block;
          margin-top: 30px; }
          .address__info-text-list-item {
            display: flex;
            align-items: center; }
          .address__info-text-list-col {
            width: 100%; }
          .address__info-text-list-title {
            width: 140px; }
          .address__info-text-list-tx {
            width: calc(100% - 140px); }
          .address__info-text-list-title, .address__info-text-list-tx, .address__info-text-list-link {
            font-weight: 600;
            font-size: 14px;
            line-height: 160%; }
    .address__map {
      display: block; }
      .address__map-mp {
        width: 100%;
        height: 510px; }
      .address__map-address {
        width: 100%;
        padding-top: 63px; }
        .address__map-address-inner {
          padding-bottom: 80px;
          height: 320px; }
        .address__map-address-item {
          border-top: none;
          border-bottom: 1px solid #D2D2D2;
          padding-bottom: 10px; }
          .address__map-address-item-title {
            font-size: 12px;
            line-height: 16px; }
          .address__map-address-item-ad {
            margin-bottom: 5px; }
            .address__map-address-item-ad-title {
              margin-bottom: 5px;
              font-size: 16px;
              line-height: 140%; }
    .address__tb {
      margin-top: 0;
      position: relative; }
    .address .nav-tabs {
      border: none;
      position: absolute;
      top: 20px;
      left: 20px;
      z-index: 10; }
    .address .nav-link {
      font-weight: 600;
      font-size: 14px;
      line-height: 160%;
      color: #191919;
      border: 1px solid #D2D2D2 !important;
      margin-right: 10px;
      border-radius: 3px;
      background: #ffffff;
      overflow: hidden; }
      .address .nav-link.active {
        background: #191919;
        border-color: #191919;
        color: #ffffff; }
  .enter {
    background: linear-gradient(180deg, #F5F6F8 9.77%, #F3F4F7 27.08%, #EFF0F4 49.23%, #EDEDEE 65.05%) !important; }
    .enter__inner {
      height: auto;
      padding: 30px 0;
      background-image: none !important; }
    .enter__top {
      margin-bottom: 30px; }
      .enter__top-tx {
        font-size: 14px; }
    .enter__form {
      display: block;
      width: 100%;
      height: auto; }
      .enter__form-tx {
        font-size: 14px; }
  .cart-in__all {
    margin-top: 20px;
    width: 100%; }
    .cart-in__all-inner {
      padding: 15px;
      margin: 0 -12px;
      border-radius: 0; }
    .cart-in__all-promo .button {
      height: 55px;
      border-radius: 0 4px 4px 0; }
    .cart-in__all-price-now {
      margin-left: 10px;
      font-weight: 600;
      font-size: 22px;
      line-height: 140%; }
    .cart-in__all-price-old {
      font-size: 12px;
      line-height: 150%; }
  .cart-in__item {
    display: block;
    padding: 12px 15px 12px 140px;
    position: relative; }
    .cart-in__item-title {
      margin-bottom: 5px;
      width: 100%;
      font-size: 12px;
      line-height: 130%; }
    .cart-in__item-img {
      width: 80px;
      height: 80px;
      position: absolute;
      top: 10px;
      left: 46px; }
    .cart-in__item-close {
      position: absolute;
      top: 37px;
      left: 22px; }
    .cart-in__item-info {
      width: 100%;
      padding: 0; }
      .cart-in__item-info-tx {
        margin-bottom: 2px;
        font-size: 12px;
        line-height: 150%; }
    .cart-in__item-amount {
      width: 114px;
      margin: 10px 0; }
      .cart-in__item-amount .amount {
        width: 114px; }
        .cart-in__item-amount .amount input {
          height: 46px; }
    .cart-in__item-price {
      width: 100%;
      text-align: left; }
      .cart-in__item-price.mob {
        display: none; }
      .cart-in__item-price-now {
        font-size: 16px;
        line-height: 140%; }
      .cart-in__item-price-old {
        font-size: 12px;
        line-height: 150%; }
  .cart-order__total-inner {
    padding: 20px 20px 0; }
  .cart-order__total-text {
    font-size: 14px;
    line-height: 160%; }
    .all .cart-order__total-text {
      font-size: 8px;
      line-height: 11px; }
  .cart-order__total-price-pr {
    font-size: 14px;
    line-height: 160%; }
    .all .cart-order__total-price-pr {
      font-size: 16px;
      line-height: 140%; }
  .cart-order__total-price-tx {
    font-size: 14px;
    line-height: 160%; }
  .cart-order__total-item {
    margin-bottom: 10px; }
    .cart-order__total-item.all {
      padding: 20px 0; }
  .ord {
    margin-bottom: 50px; }
    .ord__inner {
      flex-wrap: wrap; }
    .ord__left {
      width: 100%;
      max-width: 100%;
      padding-left: 0;
      order: 2; }
    .ord__all {
      margin-bottom: 20px;
      width: 100%; }
      .ord__all-prod-promo {
        padding: 15px 0; }
        .ord__all-prod-promo .button {
          height: 55px; }
      .ord__all-prod-list {
        padding: 5px 18px; }
      .ord__all-prod-item {
        display: block;
        padding: 15px 0 15px 90px;
        position: relative; }
        .ord__all-prod-item-img {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2; }
        .ord__all-prod-item-info {
          width: 100%;
          padding: 0; }
        .ord__all-prod-item-price {
          margin-top: 15px;
          text-align: left; }
    .ord__bottom-check label {
      font-size: 12px !important;
      line-height: 150%; }
    .ord__bottom .button {
      width: 100%;
      text-align: center; }
    .ord__list {
      max-width: 100%;
      padding-left: 0; }
      .ord__list-item-title {
        margin-bottom: 12px;
        font-size: 16px;
        line-height: 140%; }
        .ord__list-item-title-main {
          display: block;
          margin-right: 0;
          margin-bottom: 10px;
          font-size: 16px;
          line-height: 140%; }
      .ord__list-item-group-item {
        margin-bottom: 5px; }
        .ord__list-item-group-item input[type="radio"] + label {
          padding: 16px 15px 15px 51px; }
          .ord__list-item-group-item input[type="radio"] + label .title {
            font-size: 14px;
            line-height: 160%; }
          .ord__list-item-group-item input[type="radio"] + label .tx {
            font-size: 12px;
            line-height: 150%; }
          .ord__list-item-group-item input[type="radio"] + label:before {
            left: 20px; }
          .ord__list-item-group-item input[type="radio"] + label:after {
            left: 25px; }
      .ord__list .nav-link {
        font-weight: 600;
        font-size: 14px;
        line-height: 160%; }
      .ord__list .tab-content {
        margin-top: 30px; }
    .ord-done__inner {
      height: auto;
      padding: 40px 0 150px;
      display: block; }
      .ord-done__inner h2 {
        margin-bottom: 30px; }
  .orders__inner {
    flex-wrap: wrap;
    position: relative; }
  .orders__left {
    width: 100%;
    margin-bottom: 40px;
    padding-right: 0;
    order: 2; }
    .orders__left .breadcrumb-block {
      display: none; }
    .orders__left .page-top {
      padding-bottom: 100px; }
  .orders__right {
    width: 100%;
    margin: 0;
    order: 1;
    position: absolute;
    top: 87px;
    background: transparent; }
  .orders__pages {
    padding: 10px 0;
    display: flex;
    background: transparent;
    overflow: auto; }
    .orders__pages-item {
      padding: 0 30px 0 25px;
      display: flex;
      align-items: center;
      white-space: nowrap;
      border: none !important;
      font-size: 16px;
      line-height: 140%; }
      .orders__pages-item svg {
        left: 0; }
  .orders__form-item {
    display: block; }
  .orders__form-btns .button-bord {
    padding: 17px 27px; }
  .orders__form-inp {
    width: 100%; }
    .orders__form-inp-gr {
      width: 100%; }
  .orders__form-title {
    width: 100%;
    padding: 0;
    font-size: 16px;
    margin-bottom: 10px; }
  .orders__item .orders__top {
    display: block; }
  .orders__item {
    padding: 25px 15px; }
  .orders__itt {
    display: block; }
    .orders__itt .orders__status {
      margin-top: 15px; }
  .orders__bonus-tx {
    display: none; }
  .orders__bonus-item {
    padding: 25px 30px 80px;
    position: relative; }
    .orders__bonus-item-inf {
      margin-bottom: 5px;
      font-size: 16px;
      line-height: 140%; }
    .orders__bonus-item-cell {
      width: 100%;
      flex: 0 0 100%;
      max-width: 100%;
      padding-right: 0 !important; }
      .orders__bonus-item-cell:first-of-type {
        margin-bottom: 13px; }
    .orders__bonus-item-tx-title {
      font-size: 20px;
      line-height: 130%; }
    .orders__bonus-item-tx-tt {
      position: absolute;
      bottom: 35px;
      padding-right: 20px;
      font-weight: 600;
      font-size: 14px;
      line-height: 160%; }
  .orders__ist .cart-in__item {
    padding: 12px 15px 12px 102px; }
    .orders__ist .cart-in__item-info {
      width: 100%; }
    .orders__ist .cart-in__item-amount {
      width: 100%;
      text-align: left; }
      .orders__ist .cart-in__item-amount-nb {
        font-size: 12px; }
    .orders__ist .cart-in__item-price {
      width: 100%;
      text-align: left; }
    .orders__ist .cart-in__item-img {
      left: 5px; }
  .orders__ist-bottom {
    margin-top: 20px;
    flex-wrap: wrap; }
    .orders__ist-bottom .button {
      width: 100%;
      order: 2; }
    .orders__ist-bottom-right {
      width: 100%;
      margin-bottom: 20px;
      order: 1;
      text-align: left; }
    .orders__ist-bottom-price-old {
      font-size: 16px; }
    .orders__ist-bottom-price-now {
      font-size: 24px; }
  .https404 .enter__form-in {
    width: 100%; }
  .policy__text p {
    font-size: 14px; }
  .policy__text ol li {
    font-size: 20px; }
    .policy__text ol li:before {
      font-size: 20px; }
    .policy__text ol li ol li {
      font-size: 14px; }
      .policy__text ol li ol li:before {
        font-size: 14px; }
      .policy__text ol li ol li ol li {
        font-size: 14px; }
        .policy__text ol li ol li ol li:before {
          font-size: 14px; }
  .modal-body {
    padding: 30px 25px 40px; }
    .w3 .modal-body {
      padding: 20px; }
    .modal-body .btn-close {
      top: 20px; }
  .modal .address__tp {
    display: block; }
    .modal .address__tp-title {
      width: 100%; }
    .modal .address__tp-form {
      margin: 20px 0;
      width: 100%; }
      .modal .address__tp-form input {
        width: 100%; }
  .modal .address__map-address-inner {
    padding-bottom: 0; }
  .modal .address__map-address-item-ad-nl {
    width: 100%;
    text-align: left;
    margin-top: 20px;
    position: relative;
    bottom: 0; } }

@media screen and (max-width: 768px) {
  .modal-body-product-btns {
    display: block; }
    .modal-body-product-btns .button {
      width: 100%; }
  .modal-body-product-item {
    display: block;
    padding: 13px 13px 13px 80px;
    position: relative; }
    .modal-body-product-item-img {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2; }
    .modal-body-product-item-info {
      margin-bottom: 15px;
      width: 100%;
      margin-left: 0;
      padding-left: 0; }
    .modal-body-product-item-title {
      width: 100%; }
    .modal-body-product-item-price {
      width: 100%;
      text-align: left; }
  .banner__slider-img {
    right: 20%; }
  .banner-rek__inner {
    background-position: 63% center; }
  .banner-ab__inner {
    background-position: 70% center; }
  .services .col-sm-6 {
    width: 50% !important; }
  .text__table {
    overflow: auto;
    padding-bottom: 10px; }
    .text__table table {
      width: 768px; }
  .actions-item__pages {
    display: block;
    text-align: center; }
    .actions-item__pages-prev, .actions-item__pages-next {
      display: block;
      margin: 10px 0; } }

@media screen and (max-width: 530px) {
  .banner__slider-img {
    right: 70%; }
  .catalog__item-labels-item {
    display: table;
    margin-bottom: 5px; } }
